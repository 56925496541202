export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long', 
        day: '2-digit',  
        month: 'long',   
        year: 'numeric' 
    };
    let updatedDate =new Intl.DateTimeFormat('en-GB', options).format(date).split(' ');
    updatedDate[0]=updatedDate[0]+","
    return updatedDate.join(" ")
  };
  
  export function convertTo24HourFormat(timeString: string): string {
    const [time, modifier] = timeString.split(' '); 
    let [hours, minutes] = time.split(':').map(Number); 
  
    
    if (modifier === 'PM' && hours < 12) hours += 12;  
    if (modifier === 'AM' && hours === 12) hours = 0; 
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  export const addOpacity = (hexColor: string): string => {
    const hex = hexColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, 0.05)`;
  };

  export const getTagColor = (tag: string) => {
    switch (tag.toLowerCase()) {
      case 'founder':
        return "#F47133"
      case 'co founder':
        return '#D62339';
      case 'operation manager':
        return '#BC3081'
      case 'events manager':
        return '#045E81'
      case 'content manager ':
        return '#D62339'
      case 'chief inclusivity officer (cio)':
        return '#BC3081'
      case 'cis-gay':
        return '#4A90E2';
      case 'veteran':
        return '#45B7D1';
      case 'aapi':
        return '#D62339';
      case 'man':
        return '#DBA5FF';
      case 'gay':
        return '#BC3081';
      case 'woman':
        return '#FF9ECD';
      case 'non-binary':
        return '#045E81';
      case 'lesbian/sapphic':
        return '#BC3081';
      case 'lesbian':
        return '#BC3081';
      case 'bi+':
        return '#D62339';
      case 'trans':
        return '#BC3081';
      case 'poc':
        return '#D62339';
      case 'pan':
        return '#D62339';
      case 'black':
        return '#D62339';
      case 'lgbtq':
        return '#045E81';
      default:
        return '#F47133';
    }
  };
import React from "react";

// Customizable Area Start
import {
  Button,
  Box,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import {signupBg,logo,cross,backArrow} from "./assets"
import PasswordField from "../../../components/src/PasswordField";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={webStyle.userProfileWrapper(this.state.isMobile)}>
          <style>
            {`
            .MuiCheckbox-colorPrimary {
              color: #045E81;
            }
            .Mui-checked {
              color: #045E81;
            }
            .navContainer {
              display:none !important;
            }
          `}
          </style>
          <Box sx={webStyle.bgImgContainer(this.state.isMobile)}>
            <Box sx={webStyle.bgTextWrapper(this.state.isMobile)}>
              <img src={logo} style={webStyle.headerImg(this.state.isMobile)} />
              {!this.state.isMobile && <Box data-test-id="handleGoBack" sx={webStyle.backBtnContainer} onClick={this.handleGoBack}>
                <img src={backArrow} />
                <Typography sx={webStyle.backBtn}>
                  Go back
                </Typography>
              </Box>}
            </Box>
          </Box>
          <Box sx={webStyle.formContainer(this.state.isMobile)}>
            <Box sx={webStyle.mainForm}>
                {this.state.isMobile && <IconButton data-test-id="cancelBtn" aria-label="close" onClick={this.handleGoBack} sx={{position: "absolute", right: "15px",top: "-30px"}}>
                  <img src={cross} />
                </IconButton>}    
              <div style={{ textAlign: 'center' }}>
                <img
                  src={logo}
                  style={{
                    ...webStyle.footerImg(this.state.isMobile),
                  }}
                  alt="Footer Logo"
                />
              </div>            
              <Typography sx={webStyle.formHeading(this.state.isMobile)}>Log in</Typography>

              <Box sx={webStyle.textFieldContiner}>
                <Typography sx={webStyle.textFieldLabel}>Email address<span style={{ color: "#F47133" , fontWeight: 400}}>*</span></Typography>
                <TextField
                  value={this.state.user.email_id}
                  InputProps={{
                    style: webStyle.textField(this.state.isMobile)
                  }}
                  variant="outlined"
                  data-test-id="emailTextField"
                  placeholder="Enter your name"
                  onChange={this.handleEmailChange}
                  autoComplete="email"
                />
                <Typography sx={webStyle.errorLabel}>{this.state.error.email_id}</Typography>
                {this.state.handleErrors && 
                  <p style={{
                        color: "red",  
                        marginLeft:'10px',
                        lineHeight: "24px",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400, marginTop: '5px'
                    }}>{this.state.loginEmail}</p>}
              </Box>
              <Box sx={webStyle.textFieldContiner}>
                <Typography sx={webStyle.textFieldLabel}>Password<span style={{ color: "#F47133" , fontWeight: 400}}>*</span></Typography>
                <PasswordField
                data-test-id="passwordTextField"
                  value={this.state.user.password}
                  onChange={this.handlePasswordChange}
                  isMobile={this.state.isMobile}
                  isPasswordVisible={this.state.enablePasswordField}
                  onTogglePasswordVisibility={this.handleClickShowPassword}
                  style={webStyle.passwordTextField(this.state.isMobile)}
                />
                <Typography sx={webStyle.errorLabel}>{this.state.error.password}</Typography>
                {this.state.handleError && 
                  <p style={{
                        color: "red",  
                        marginLeft:'10px',
                        lineHeight: "24px",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400, marginTop: '5px'
                    }}>{this.state.loginError}</p>}
              </Box>
              {this.state.isMobile && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography onClick={()=>{ this.navigationToAnyPage("ForgotPassword") }} sx={webStyle.forgotText}>Forgot password?</Typography>
                </Box>
              )}

              <Button
                variant="contained"
                style={{
                  backgroundColor: "#D62339",
                  height: "38px",
                  borderRadius: "100px",
                  margin: "10px 0",
                  boxShadow: "none",
                  cursor:"pointer",
                  width: this.state.isMobile ? "317px": "480px",
                  marginBottom:'30px'
                }}
                data-test-id="loginBtn"
                onClick={this.handleLoginSubmit}
              >
                <Typography sx={webStyle.loginBtn}>
                  Log In
                </Typography>
              </Button>
              
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!this.state.isMobile && (
                  <Typography onClick={()=>{ this.navigationToAnyPage("ForgotPassword") }} sx={webStyle.forgotText}>Forgot password?</Typography>
                )}
                 <Typography sx={{...webStyle.loginText,marginTop:'10px'}}>Don't have an account? <span style={webStyle.loginTextSpan as React.CSSProperties} data-test-id="loginNavigate" onClick={this.navigateToSignup}>Sign Up</span></Typography>
                
              
              </Box>
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  userProfileWrapper: (isMobile:boolean)=>({
    width: "100%",
    height:"100vh",
    display:"flex",
    ...(isMobile && {
      width: "100%",
      display:"flex",
      flexDirection:"column",
      height: "100%",
    }),
  }),
  formContainer:(isMobile:boolean)=>({
    width: "50%",
    background:"#FAF7F7",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::after":{
      content: '"."',
      width: "50px",
      height: "100%",
      background: "#FAF7F7",
      display: "inline-block",
      position: "absolute",
      top: "0",
      left: "-42px",
      borderRadius: "40px 0 0 40px",
    },
    ...(isMobile && {
      width: "100%",
      height: "auto",
      display:"flex",
      flexDirection:"column",
      borderRadius: "40px",
      paddingBottom:"40px",
      background:"#FAF7F7",
      padding:"80px 0 100px 0",
      "&::before":{
        content: '"."',
        width: "100%",
        height: "40px",
        background: "#FAF7F7",
        display: "inline-block",
        position: "absolute",
        top: "-38px",
        left: "0",
        borderRadius: "40px 40px 0 0 ",
      },
      "&::after":{
        display:"none"
      }
    }),
  }),
  bgImgContainer:(isMobile:boolean)=>({
    backgroundImage:`url(${signupBg})`,
    width: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display:"flex",
    justifyContent:"center",
    ...(isMobile && {
      width: "100%",
      height: "100vh",
    }),
  }),
  bgTextWrapper:(isMobile:boolean)=>({
    padding: "calc(100px)", 
    width:"70%",     
    ...(isMobile && {
      padding: "0", 
      position:"static",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign:"center",
    }),
  }),
  mainForm:{
    maxWidth:"480px",
    margin:"auto",
    paddingLeft:"10px",
    zIndex:10,
  },
  formHeading:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: 700,
    textAlign: "center",
    color:"#3C8D9E",
    marginBottom:"35px",
    ...(isMobile && {
      fontSize: "30px",
      marginBottom:"20px",
    })
  }),  
  btnText:{
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
    color:"#043C61",
    textTransform: "capitalize",
  },
  loginBtn:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    textTransform: "capitalize",
  },
  textField:(isMobile:boolean)=>({
    width: "480px",
    height: "38px",
    borderRadius: "100px",
    border: "0.5px",
    fontFamily:"Inter",
    fontSize:"12px",
    color:"#888888",
    backgroundColor:"#fff",
    ...(isMobile && {
      width: "317px",
    })
  }),
  passwordTextField:(isMobile:boolean)=>({
    width: "480px",
    height: "38px",
    borderRadius: "100px",
    border: "0.5px",
    fontFamily:"Inter",
    fontSize:"12px",
    color:"#888888",
    backgroundColor:"#fff",
    ...(isMobile && {
      width: "317px",
    }),
  }),
  textFieldContiner:{
    marginTop:"10px",
    marginBottom:"10px",
  },
  textFieldLabel:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    color:"#888888",
    paddingLeft:"10px",
  },
  loginText:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "10px",
    textAlign: "left",
    color:"#888888"
  },
  loginTextSpan:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 900,
    lineHeight: "10px",
    textAlign: "left",
    color:"#F47133",
    cursor:"pointer",
  },
  headerImg:(isMobile:boolean)=>({
     display:"none",
    ...(isMobile && {
      display:"block"
    })
  }),
  footerImg:(isMobile:boolean)=>({
    ...(isMobile && {
      display:"none"
    })
  }),
  errorLabel:{
    fontFamily: "Inter",
    fontSize: "12px",
    textAlign: "left",
    marginLeft:'10px',
    lineHeight: "24px", marginTop: '5px',
    color:"red"
  },
  forgotText:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "10px",
    textAlign: "left",
    color:"#888888",
    margin:"10px 0",
    cursor:"pointer"
  },
  backBtn:{
    color:"#fff",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    marginLeft:"20px"
  },
  backBtnContainer:{
    margin: "-20px 0 50px 0",
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center",
    cursor : "pointer"
  }
};

// Customizable Area End

import React from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"
import { getTagColor, addOpacity } from '../../../../components/src/HelperUtils';
import { EventForm } from '../EventScreenController.web';

interface EventDetailsModalProps {
  open: boolean
  onClose: () => void
  eventDetails:EventForm
  createEvent:()=>void
}

const styles = {
  modalContainer: {
    borderRadius: '16px',
    padding: '16px',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  modalTitle: {
    textAlign: 'center' as const,
    padding: 0,
  },
  modalTitleText: {
    fontSize: '24px',
    fontWeight: 600,
    marginBottom: '2px',
  },
  modalSubtitle: {
    fontSize: '18px',
    color: '#343434',
    fontWeight : 400
  },
  modalContent: {
    padding: '0 16px',
  },
  eventDetails: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
  },
  detailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '8px',
  },
  detailLabel: {
    width : "50%",
    fontSize: '18px',
    fontWeight: 500,
    color: '#343434',
    fontFamily : "Inter",
  },
  detailValue: {
    width : "50%",
    fontSize: '14px',
    fontWeight: 400,
    textAlign : "left" as const,
    // textAlign: 'right' as const,
    fontFamily : "Inter"
  },
  identityTags: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'flex-end',
    gap: '8px',
  },
  tag: {
    padding: '4px 12px',
    borderRadius: '9999px',
    fontSize: '12px',
    border : "1px solid red"
  },
  tagLgbtq: {
    backgroundColor: '#e6f3ff',
    color: '#0066cc',
  },
  tagSapphic: {
    backgroundColor: '#ffe6f0',
    color: '#cc0066',
  },
  tagLesbian: {
    backgroundColor: '#f0e6ff',
    color: '#6600cc',
  },
  tagBi: {
    backgroundColor: '#e6e6ff',
    color: '#0000cc',
  },
  divider: {
    border: 'none',
    borderTop: '1px solid #e0e0e0',
    margin: '8px 0',
  },
  buttonGroup: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
  },
  button: {
    flex: 1,
    padding: '8px 16px',
    borderRadius: '9999px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonConfirm: {
    backgroundColor: '#dc2626',
    color: 'white',
    border: 'none',
  },
  buttonCancel: {
    backgroundColor: 'white',
    color: '#666',
    border: '1px solid #d1d5db',
  },
}

export default function EventDetailsModal({ open, onClose, eventDetails, createEvent}: EventDetailsModalProps) {

  const renderEventDetails = (key:string, value:string)=>{
    return(
      <div style={styles.eventDetails}>
        <div>
          <div style={styles.detailItem}>
            <span style={styles.detailLabel}>{key}</span>
            <span style={styles.detailValue}>{value}</span>
          </div>
          <hr style={styles.divider} />
        </div>
      </div>
    )
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: styles.modalContainer
      }}
    >
      <div style={styles.modalHeader}>
        <IconButton onClick={onClose}>
          <ArrowBack />
        </IconButton>
      </div>
      
      <DialogTitle style={styles.modalTitle}>
        <div style={styles.modalTitleText}>Event Details</div>
        <p style={styles.modalSubtitle}>Confirm your Event details</p>
      </DialogTitle>

      <DialogContent style={styles.modalContent}>
        {renderEventDetails("Event Name", eventDetails.title)}
        {renderEventDetails(
          "Event Type", 
          eventDetails.sub_category
            ?.map((category) => category.attributes.name) // Extract category names
            .join(", ") || "No categories selected" // Join with a comma and handle empty case
        )}
        {renderEventDetails("Date", eventDetails.start_date)}
        {renderEventDetails("Start Time", eventDetails.start_time)}
        {renderEventDetails("End Time", eventDetails.end_time)}
        {renderEventDetails("Venu Name/Address", eventDetails.location[0]?.attributes.venue_name)}
        {renderEventDetails("Event Organizer", eventDetails.event_organizer)}
        {renderEventDetails("Description", eventDetails.description)}
        <div style={styles.eventDetails}>
        <div>
          <div style={styles.detailItem}>
            <span style={styles.detailLabel}>Identity Tags</span>
            <div style={{ width : "50%", display : "flex"
              , gap : "8px"
            }}>
            {eventDetails.identity_tags.map((identity)=>(
               <span
               key={identity.id}
               style={{
                ...styles.tag,
                color: getTagColor(identity.attributes.name),
                borderColor: getTagColor(identity.attributes.name),
                backgroundColor : addOpacity(getTagColor(identity.attributes.name))
              }}
             >
               {identity.attributes.name}
             </span>
            ))}
            </div>
          </div>
          <hr style={styles.divider} />
        </div>
      </div>
        {renderEventDetails("Ages", eventDetails.age)}
        {renderEventDetails("Cost", eventDetails.cost.toString())}
        <div style={styles.buttonGroup}>
          <button
            onClick={createEvent}
            style={{...styles.button, ...styles.buttonConfirm}}
          >
            Confirm
          </button>
          <button
            onClick={onClose}
            style={{...styles.button, ...styles.buttonCancel}}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  )
}


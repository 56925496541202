import React from "react";

// Customizable Area Start
import {
  Button,
  Box,
  InputAdornment,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  TextField,
  MenuItem,
  Select,
  styled,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogActions,
  IconButton,
  Divider,
  Checkbox,
  FormControlLabel,
  FormLabel,
  ToggleButtonGroup,
  Radio,
  RadioGroup,
  } from '@mui/material';
import { addIcon, uploadIcon, btnIcon } from "../../landingpage/src/assets";
import EventScreenController from "./EventScreenController.web";
import moment from "moment";
import EventsPopUp from "../../../components/src/EventsPopup";
import { ShareEventModal } from "../../../components/src/ShareEventModal";
import EventCalendar from "../../scheduling/src/EventCalendar.web";
import EventMap from "../../maps/src/EventMap.web";
import AdvancedSearches from "../../advancedsearch/src/AdvancedSearches.web";
import SearchIcon from "@mui/icons-material/Search";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EventDetailsModal from "./components/EventDetailsModal";
import EventSuccessModal from "./components/EventSuccessModal";
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import {
  createTheme
} from "@mui/material/styles";
import Footer from "../../../components/src/Footer";
import CustomSnackBar from "./components/Snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  // height: '38px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px',
    backgroundColor: '#fff',
    border: '0.5px solid #94A3B8',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
  '& .MuiInputLabel-outlined': {
    position: 'relative',
    transform: 'none',
    marginBottom: '4px',
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px',
    '::placeholder': {
      color: '#888888',
      fontWeight: "300",
      fontSize: "14px",
      fontFamily: "Inter",
      fontStyle: 'italic',
      opacity: 1,
    },
  },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '38px',
  borderRadius: '100px',
  backgroundColor: '#fff',
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px 0px 0px 0px',
    backgroundColor: '#fff',
    border: '0.5px solid #94A3B8',
  },
  '& .MuiSelect-select': {
    color: 'black',
    fontWeight: "300",
    fontSize: "14px",
    fontFamily: "Inter",
    // fontStyle: 'italic',
    opacity: 1,
    '&.MuiSelect-placeholder': {
      fontStyle: 'italic',
      color: '#6B7280',
    },
  },
  '& .MuiPaper-root': {
    // Styles for the dropdown dialog
    margin: '10px', // Margin around the dropdown
    padding: '20px', // Padding inside the dropdown
    borderRadius: '8px', // Rounded corners
    border: "1px solid red"
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  position: "relative",
  '& .MuiDialog-paper': {
    width: "540px",
    borderRadius: '20px',
    overflow: 'hidden',
    background: "#F3F3F3",
    [theme.breakpoints.down('sm')]: {
      paddingTop : "50px"
    },
  },
  '& .modalTitle':{
    fontFamily: "Inter",
    fontSize: "25px",
    fontWeight: 700,
    textAlign: "left",
    [theme.breakpoints.down('sm')]: {
      fontSize: "22px",
    },
  },
  '& .responsiveTwoColumns' : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "95%",
    marginBottom: "18px",
    [theme.breakpoints.down('sm')]: {
      flexDirection : "column"
    },
  },
  '& .responsiveBlock1':{
    width : "48%",
    [theme.breakpoints.down('sm')]: {
       width : "100%",
    },
  },
  '& .responsiveBlock3':{
    width : "auto",
    [theme.breakpoints.down('sm')]: {
       width : "100%",
    },
  },
  '& .responsiveBlock4':{
    flex : 1,
    gap : "42px",
    [theme.breakpoints.down('sm')]: {
       width : "100%",
       gap:"10px"
    },
  },
  '& .afterBlock':{
    display: 'flex',
    flexGrow : 1, 
    width : "100%",
    alignItems: 'center',
    gap: 2, 
    justifyContent : "space-between" ,
    [theme.breakpoints.down(418)]: {
      flexDirection : "column"
   },
  },
  '& .mobileLocationContainer':{
    width : "100%",
    display : "none",
    [theme.breakpoints.down('sm')]: {
      display : "block",
      marginTop : "8px"
   },
  },
  '& .desktopLocationContainer':{
    width : "100%",
    display : "block",
    [theme.breakpoints.down('sm')]: {
      display : "none"
   },
  },
  '& .responsiveBlock2':{
    width : '48%',
    [theme.breakpoints.down('sm')]: {
      width : "100%",
      marginTop : "10px"
    },
  },
  '& .afterSection':{
    width: "214px",
    marginLeft : "auto",
    [theme.breakpoints.down(438)]: {
      width : "100%",
      marginTop : "10px"
    },
  },
  '& .repeatEventSection' : {
    display : "flex", 
    justifyContent : "space-between", 
    alignItems : "center",
    marginTop  :"12px",
    [theme.breakpoints.down(438)]: {
      flexDirection  :"column"
    },
  },
  '& .repeatLabel' : {
    width : "auto", 
    alignItems: 'center',
    [theme.breakpoints.down(438)]: {
      width : "100%"
    },
  },
  "&::-webkit-scrollbar": {
    width: "4px",
    borderRadius: "100px"
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#043C61",
    borderRadius: "100px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#02283F",
  },
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: "#043C61 transparent",
}));

const StyledTextArea = styled(TextField)(({ theme }) => ({
  paddingTop: '2px',
  backgroundColor: '#fff',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: '#fff',
    // border: '0.5px solid #94A3B8',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
    '&.Mui-focused': {
      // Prevent background color change when the input is focused
      backgroundColor: '#fff', // Keep background same on focus
      borderColor: '#3f51b5', // Set focus border color, adjust as needed
    },
  },
  '& .MuiInputLabel-outlined': {
    position: 'relative',
    transform: 'none',
    marginBottom: '4px',
  },
  '& .MuiInputBase-input': {
    padding: "0",
    '::placeholder': {
      color: '#888888',
      fontWeight: "300",
      fontSize: "14px",
      fontFamily: "Inter",
      fontStyle: 'italic',
      opacity: 1,
    },
  },

  '& .MuiAutocomplete-popupIndicator': {
    // Style for the dropdown indicator (if any)
    backgroundColor: 'transparent', // Make background transparent if undesired
  },
  '& .MuiMenuItem-root': {
    // Styles for the dropdown menu items (if applicable)
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Custom hover background
    },
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: '20px',
  height: '20px',
  padding: '5.25px 4.5px',
  backgroundColor: 'transparent',
  borderRadius : "6px",
  transition: 'background-color 0.3s ease, border-color 0.3s ease',
  marginRight : "10px",
  boxShadow: 'none',
  border : "1px solid #64748B",
  outline: 'none',  
  marginLeft : "10px",
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '&.Mui-checked': {
    backgroundColor: '#ffffff',
    border : "none",
    color: '#ffffff',
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    display: 'block', 
    color : "#96B999",
    borderRadius : "12px",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  fontFamily: 'Inter',
  fontSize: '25px',
  fontWeight: 500,
  textAlign: 'left',
});
// Customizable Area End

export default class EventsScreen extends EventScreenController {


  // Customizable Area Start

  renderAddButton = () => {
    return (
      <Button fullWidth onClick={this.createEventCheck} className="addEventBtn" style={webStyle.shareEvent}>
        Add Your Event
        <Box
          marginLeft={"10px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={webStyle.shareEventIcon}
          className="addEventBox"
        >
          <img src={addIcon} height={27} alt="" />
        </Box>
      </Button>
    )
  }

  renderResultSection = () => {
    return (
      <Box sx={webStyle.results} data-test-id="result-count" className="resultsMobile">
        {this.state.eventsLength} results
      </Box>
    )
  }

  renderEventsText = () => {
    return (
      <Box marginTop={"10px"}>
        <Box sx={webStyle.eventsFound}>Events found</Box>
      </Box>
    )
  }

  renderAlert = () => {
    return (
      <Dialog open={this.state.isAlertOpen} >
        <DialogContent>
          <DialogTitle style={webStyle.alertTitle}>Login Required</DialogTitle>
          <DialogContent className="dialog-content" style={webStyle.dialogContent}>
            <Typography className="dialog-description" style={webStyle.description}>
              You need to be logged in to use this feature.
            </Typography>
            <div className="info-box" style={webStyle.infoBox}>
              <Typography className="info-text" style={webStyle.infoText}>
                Logging in gives you access to exclusive features and personalized content.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions className="button-container" style={{ padding: '0 20px 20px' }}>
            <Button
              data-test-id="cancelBtn"
              className="cancel-button"
              onClick={this.handleCancelEvent}
              style={{ ...webStyle.button, ...webStyle.secondaryButton }}
            >
              Cancel
            </Button>
            <Button
              className="login-button"
              onClick={this.handleLogin}
              style={{ ...webStyle.button, ...webStyle.primaryButton }}
            >
              Go to Login
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  renderLocationTag = ()=>(
    (this.state.showLocationTags) &&
      <div style={webStyle.tagContainer}>
         <ClearRoundedIcon data-test-id="locationContainerClose" onClick={()=>this.setState({showLocationTags : false})} 
          style={webStyle.containerCloseIcon} />
        <Typography style={webStyle.locationHeading}>Recent and/or most popular</Typography>
        <List style={{ width: "90%", height: "240px", overflowY: "scroll" }}>
          {this.state.locations
            .filter((item) => item.attributes.venue_name.toLowerCase().includes(this.state.locationSearchTag.toLowerCase()))
            .map((filteredItem, index) => (
              <div style={{ cursor: "pointer", borderBottom: "1px solid #E2E8F0", display: "flex", padding: "7px" }} key={index} data-test-id="locationItem" onClick={() => this.handleLocationSelect(filteredItem)}>
                <div style={{ height: "20px", width: "20px", borderRadius: "50%", backgroundColor: "#E2E8F0", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "12px" }}>
                  <FmdGoodOutlinedIcon style={{ fontSize: "14px" }} />
                </div>
                <div>
                  <Typography style={webStyle.venuNameHeading}>Venue Name</Typography>
                  <ListItemText style={{ fontWeight: 400, fontSize: "14px", color: "#343434" }} primary={filteredItem.attributes.venue_name} />
                </div>
                <Divider />
              </div>
            ))}
        </List>
        <Typography style={{ ...webStyle.locationHeading, marginTop: "17px", marginBottom: "8px" }}>Virtual</Typography>
        <StyledTextField
          fullWidth
          placeholder="Insert the link for the meeting"
          variant="outlined"
          name="virtual_meeting_link"
          value={this.state.eventForm.virtual_meeting_link}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              ...webStyle.textFieldInputStyles,
              margin: "0 20px",
              width: "90%",
              boxSizing: "border-box"
            }
          }}
          onChange={(event) => this.handleTextFieldChange(event, "virtual_meeting_link")}
        />
      </div>
  )

  datePickers = () => (
    <Box className="date-picker-wrapper responsiveTwoColumns">
      <Box className="responsiveBlock1">
        <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Start Date</Typography>
        <DesktopDatePicker
          name="start_date"
          value={this.state.eventForm.start_date ? dayjs(this.state.eventForm.start_date).format("MMMM D, YYYY") : null} // Format selected date
          onChange={(value) => this.handleDateChange(value, "start_date")}
          format="DD/MM/YYYY"
          open={this.state.isCalendarOpen}
          onClose={() => this.setState({ isCalendarOpen: false })}
          shouldDisableDate={(date) => {
            const today = dayjs();
            const { end_date } = this.state.eventForm;
            const isBeforeToday = dayjs(date).isBefore(today, "day");
            const isAfterEndDate = end_date ? dayjs(date).isAfter(dayjs(end_date)) : false;
            return isBeforeToday || isAfterEndDate;
          }}
          sx={{
            "& .MuiInputBase-input": {
              color: "black",
              fontStyle: "italic",
              fontSize: "14px",
            }
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              placeholder: "DD / MM / YYYY",
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={this.openCalendar}>
                      <CalendarTodayIcon style={{ fontSize: "14px", color: "#96B999" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: null,
                style: webStyle.textFieldInputStyles, // Add custom styles
              },

            },
          }}
        />
      </Box>
      <Box className="responsiveBlock2">
        <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>End Date</Typography>
        <DesktopDatePicker
          name="end_date"
          value={
            this.state.eventForm.end_date
              ? dayjs(this.state.eventForm.end_date, "YYYY-MM-DD") // Parse string as Dayjs object
              : null
          }
          onChange={(value) => this.handleDateChange(value, "end_date")}
          format="DD/MM/YYYY"
          open={this.state.isCalendarOpen2}
          onClose={() => this.setState({ isCalendarOpen: false })}
          shouldDisableDate={(date) => {
            const today = dayjs();
            const { start_date } = this.state.eventForm;
            const isBeforeToday = dayjs(date).isBefore(today, "day");
            const isAfterEndDate = start_date ? dayjs(date).isBefore(dayjs(start_date)) : false;
            return isBeforeToday || isAfterEndDate;
          }}
          sx={{
            "& .MuiInputBase-input": {
              color: "black",
              fontStyle: "italic",
              fontSize: "14px",
            }
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              placeholder: "DD / MM / YYYY",
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={this.openCalendar2}>
                      <CalendarTodayIcon style={{ fontSize: "14px", color: "#96B999" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: null,
                style: webStyle.textFieldInputStyles,
              },

            },
          }}
        />
      </Box>
    </Box>
  )

  

  renderRepeatEvent = () =>{
    const weekDays = [
      { label: 'S', value: 'Sunday' },
      { label: 'M', value: 'Monday' },
      { label: 'T', value: 'Tuesday' },
      { label: 'W', value: 'Wednesday' },
      { label: 'T', value: 'Thursday' },
      { label: 'F', value: 'Friday' },
      { label: 'S', value: 'Ssaturday' },
    ]
    const { repeatEvent, repeatEvery, repeatType, repeatOn, endsOption, endDate, occurrences } = this.state
    return(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width : "95%", boxSizing : "border-box"}}>
          <StyledFormControlLabel
            data-test-id="repeatCheckbox"
            control={
              <StyledCheckbox
                checked={this.state.repeatEvent}
                onChange={(e) => this.setState({ repeatEvent: e.target.checked })}
              />
            }
            label="Repeat Event"
          />

          {repeatEvent && (
            <Box sx={{ mt: 2 }}>
              <Box className="responsiveTwoColumns" style={{gap  :"14px", alignItems : "center", width : "100%"}}>
                <Box className="responsiveBlock3" >
                <Typography style={webStyle.textFieldLabelStyles} >Repeat Every</Typography>
                </Box>
                <Box className="responsiveBlock4" style={{display : "flex", alignItems : "center", justifyContent : "space-between"}}>
                <StyledTextField
                  type="number"
                  data-test-id="repeatEvery"
                  value={repeatEvery}
                  onChange={(e) => this.setState({ repeatEvery: Math.max(1, Number(e.target.value)) })}
                  InputProps={{
                    style : {
                      ...webStyle.textFieldInputStyles,
                      width : "75px"
                    }
                  }}
                />
                  <StyledSelect
                    value={repeatType}
                    onChange={(e) => this.setState({ repeatType: e.target.value as string })}
                    sx={{flex :1 }}
                    data-test-id="repeatType"
                  >
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="year">Year</MenuItem>
                  </StyledSelect>
                </Box>
              </Box>

              {repeatType === 'week' && (
                <Box sx={{ mb: 3 }}>
                  <Typography style={{
                    ...webStyle.textFieldLabelStyles,
                    marginTop : "20px"
                  }}>Repeat on</Typography>
                  <ToggleButtonGroup
                    value={repeatOn}
                    //onChange={this.handleRepeatOnChange}
                    aria-label="repeat on days"
                    sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}
                  >
                    {weekDays.map((day) => {
                      const isSelected = repeatOn.some((d) => d.value === day.value);
                      return(
                      <Box
                        key={day.value}
                        data-test-id="weekDay"
                        onClick={() => this.toggleDaySelection(day)}
                        sx={{
                          display: 'flex', // Center align content
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%', // Make it a circle
                          width: '40px', // Ensure equal width and height
                          height: '40px',
                          padding: 0, // Remove extra padding
                          backgroundColor: isSelected ? '#96B999' : '#96B99940', 
                          border: '1px solid #96B999',
                          cursor : "pointer"
                        }}
                      >
                        <Typography style={{
                          fontFamily : "Inter",
                          fontWeight : 400,
                          fontSize : "14px",
                          color : isSelected ? "#ffffff" : "#96B999",
                          
                        }}>{day.label}</Typography>
                      </Box>
                      )
                    })}
                  </ToggleButtonGroup>
                </Box>
              )}

              <Box>
                <FormLabel component="legend">Ends</FormLabel>
                <RadioGroup
                  value={endsOption}
                  data-test-id="endOptions"
                  onChange={(e:any) => this.setState({ endsOption: e.target.value })}
                  sx={{ mt: 1 }}
                >
                  <FormControlLabel value="never" control={<Radio />} label="Never" />
                  <Box className="repeatEventSection">
                    <FormControlLabel
                      value="on"
                      control={<Radio />}
                      data-test-id="endDateContainer"
                      className="repeatLabel"
                      label={
                          <Typography>On</Typography>
                      }
                    />
                    <DatePicker
                            data-test-id="eventRepeatEndDate"
                            value={endDate ? dayjs(endDate) : null} // Format selected date
                            onChange={(newValue) => this.handleEndDate(newValue)}
                            disabled={endsOption !== 'on'}
                            format="MMMM D, YYYY" 
                            className="afterSection"
                            shouldDisableDate={(date) => {
                              const today = dayjs();
                              const { start_date } = this.state.eventForm;
                              const isBeforeToday = dayjs(date).isBefore(today, "day");
                              const isAfterStartDate = start_date ? dayjs(date).isBefore(dayjs(start_date)) : false;
                              return isBeforeToday || isAfterStartDate; 
                            }}
                            slotProps={{
                              textField: {
                                size: 'small',
                                sx: { width: 200 },
                              
                              InputProps:{
                                style : webStyle.textFieldInputStyles
                              }
                              }
                            }}
                            
                            
                          />
                  </Box>
                  <Box className="repeatEventSection">
                  <FormControlLabel
                    value="after"
                    control={<Radio />}
                    className="repeatLabel"
                    label={
                        <Typography>After</Typography>
                    }
                  />
                  <TextField
                          type="number"
                          value={occurrences}
                          onChange={(e) => this.setState({ occurrences: Math.max(1, Number(e.target.value)) })}
                          disabled={endsOption !== 'after'}
                          size="small"
                          className="afterSection"
                          InputProps={{
                            endAdornment: <Typography sx={{ ml: 1 }}>occurrences</Typography>,
                            style : {
                              ...webStyle.textFieldInputStyles
                            }
                          }}
                        />
                        </Box>
                </RadioGroup>
              </Box>
            </Box>
          )}
        </Box>
      </LocalizationProvider>
    )
  }

  renderDialogSection = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BootstrapDialog onClick={this.handleCloseCalendar} onClose={() => { }} aria-labelledby="customized-dialog-title" open={this.state.isEventDialogOpen}>
          <CloseIcon style={webStyle.crossIcon} onClick={this.handleCloseEventDialog} />
          <DialogTitle id="customized-dialog-title" style={{paddingBottom : "0px"}}>
            <Typography className="modalTitle" gutterBottom>
              <span style={{ color: "#BC3081" }}>Ev</span><span style={{ color: "#D62339" }}>en</span><span style={{ color: "#F47133" }}>t S</span><span style={{ color: "#FE9612" }}>ub</span><span style={{ color: "#FED576" }}>mi</span><span style={{ color: "#96B999" }}>ss</span><span style={{ color: "#3C8D9E" }}>ion</span> <span style={{ color: "#045E81" }}>Fo</span><span style={{ color: "#043C61" }}>rm</span>
            </Typography>
          </DialogTitle> 
          <Divider style={{margin : "0 30px"}} />
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Event Name</Typography>
              <StyledTextField
                fullWidth
                placeholder="Type your event name"
                variant="outlined"
                name="title"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: webStyle.textFieldInputStyles
                }}
                onChange={(event) => this.handleTextFieldChange(event, "title")}
              />
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>
                Event Type
              </Typography>
              <StyledSelect
                name="event_type"
                fullWidth
                variant="outlined"
                displayEmpty
                multiple
                renderValue={(selected: any) => {
                  if (!selected || selected.length === 0) {
                    return <span style={{ fontStyle: 'italic', color: '#6B7280' }}>Select</span>;
                  } else {
                    const selectedEvents = this.state.eventTypes.filter((event) =>
                      selected.includes(event.id)
                    );
                    return (
                      <span style={{ color: "black" }}>
                        {selectedEvents.map((event) => event.attributes.name).join(", ")}
                      </span>
                    );
                  }
                }}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: '300px',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                inputProps={{
                  style: webStyle.textFieldInputStyles,
                }}
                defaultValue={[]}
                onChange={(event) => this.handleTextFieldChange(event, "sub_category")}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {this.state.eventTypes.map((event_type) => (
                  <MenuItem key={event_type.id} value={event_type.id}>
                    <Checkbox
                      checked={this.state.eventForm.sub_category?.includes(event_type)}
                      color="primary"
                    />
                    {event_type.attributes.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
            {this.datePickers()}
            <Box className="responsiveTwoColumns">
              <Box className="responsiveBlock1">
                <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Start Time</Typography>
                <TimePicker
                name="start_time"
                  value={this.state.eventForm.start_time ? dayjs(this.state.eventForm.start_time, 'HH:mm') : null}
          
                 onChange={(newValue) => this.handleTimeChange(newValue, "start_time")}
                  format="HH:mm"
                  ampm={true}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      placeholder: "HH:MM",
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        style: webStyle.textFieldInputStyles,
                      },
                      sx: {
                        '&::placeholder': {
                          color: 'gray',
                          fontStyle: 'italic',
                          fontSize: '14px',
                          fontWeight: 300
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box className="responsiveBlock2">
                <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>End Time</Typography>
                <TimePicker
                name="end_time"
                  value={this.state.eventForm.end_time ? dayjs(this.state.eventForm.end_time, 'HH:mm') : null}
                  onChange={(newValue) => this.handleTimeChange(newValue, "end_time")}
                  format="HH:mm"
                  ampm={true}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      placeholder: "HH:MM",
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        style: webStyle.textFieldInputStyles,
                      },
                      sx: {
                        '&::placeholder': {
                          color: 'gray',
                          fontStyle: 'italic',
                          fontSize: '14px',
                          fontWeight: 300
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className="responsiveTwoColumns">
              <Box className="responsiveBlock1">
                <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Venue Name/Address</Typography>
                <StyledTextField
                  fullWidth
                  name="location"
                  autoComplete="off"
                  onFocus={this.handleInputFocus}
                  value={this.state.locationSearchTag}
                  placeholder="Search here"
                  onChange={(event) => this.handleLocationChange(event)}
                  onKeyDown={(event) => this.handleBackspace(event)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputAdornment-root": {
                      marginRight: -1, // Adjust the spacing
                      padding: "0", // Optional
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#9E9E9E" }} />
                      </InputAdornment>
                    ),
                    style: webStyle.textFieldInputStyles,
                  }}
                />

              </Box>
              <Box className="mobileLocationContainer">
                {this.renderLocationTag()}
              </Box>
              <Box className="responsiveBlock2">
                <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Event Organizer</Typography>
                <StyledTextField
                  name="event_organizer"
                  autoComplete="off"
                  fullWidth
                  placeholder="Type your Event Organizer"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: webStyle.textFieldInputStyles
                  }}
                  onChange={(event) => this.handleTextFieldChange(event, "event_organizer")}
                />
              </Box>
            </Box>
            <Box className="desktopLocationContainer">
              {this.renderLocationTag()}
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Description</Typography>
              <StyledTextArea
                name="description"
                fullWidth
                multiline
                maxRows={3}
                variant="outlined"
                placeholder="Describe your event"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiInputBase-input":{
                    marginTop : -2
                  }
                }}
                InputProps={{
                  style: {
                    ...webStyle.textAreaFieldInputStyles,
                    paddingTop: "7px"
                  }

                }}
                onChange={(event) => this.handleTextFieldChange(event, "description")}
              />
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Comunities</Typography>
              <Box sx={{ margin: "10px 0" }}>
                {this.state.selectedTag.map((item) => (
                  <Chip
                    key={item.attributes.name}
                    label={item.attributes.name}
                    onDelete={() => this.handleDelete(item)}
                    sx={{
                      marginRight: "10px",
                      marginBottom : "10px",
                      padding: "12px",
                      border: "0.5px solid #BC3081",
                      background: "none",
                      ".MuiChip-label": {
                        marginRight: "35px",
                        marginLeft: "-8px"
                      },
                      ".MuiChip-deleteIcon": {
                        marginLeft: "-35px",
                        marginRight: "-8px",
                        backgroundColor: "#BC3081",
                        borderRadius: "50%"
                      },
                    }}
                    deleteIcon={
                      <div
                        style={{
                          backgroundColor: "#BC3081",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "6px",
                          boxSizing: "border-box"
                        }}
                      >
                        <ClearRoundedIcon style={{fontSize : "14px"}} />
                      </div>
                    }
                  />
                ))}
              </Box>
              <Typography data-test-id="addTagBtn" onClick={() => this.setState({ isTagClicked: true })} sx={{ ...webStyle.addTag, display: "inline-block" }}>
                <span style={webStyle.addTagIcon}>+</span>Add Tag
              </Typography>
              {this.state.isTagClicked &&
                <div style={webStyle.tagContainer}>
                  <ClearRoundedIcon data-test-id="tagCotainerClose" onClick={()=>this.setState({isTagClicked : false})} 
                  style={webStyle.containerCloseIcon} />
                  <input
                    name="searchTag"
                    aria-placeholder="Search tags"
                    value={this.state.searchTag}
                    onChange={(e) => this.setState({ searchTag: e.target.value })}
                    placeholder="Search tags"
                    style={{ width: "90%", height: "38px", borderRadius: "20px", border: "none", background: "#F3F3F3", padding: "14px" }}
                  />
                  <List style={{ width: "90%", height: "200px", overflowY: "scroll" }}>
                    {this.state.communities
                      .filter(
                        (item) =>
                          item.attributes.name.toLowerCase().includes(this.state.searchTag.toLowerCase()) &&
                          !this.state.selectedTag.some((tag) => tag.attributes.name === item.attributes.name)
                      )
                      .map((filteredItem, index) => (
                        <ListItem style={{ cursor: "pointer" }} data-test-id="singleComunity" key={index} onClick={() => this.handleItemSelect(filteredItem)}>
                          <ListItemIcon style={{ minWidth: "24px" }}>
                            <FiberManualRecordIcon style={{ fontSize: "8px" }} />
                          </ListItemIcon>
                          <ListItemText style={{ fontWeight: 400, fontSize: "14px", color: "#94A3B8" }} primary={filteredItem.attributes.name} />
                        </ListItem>
                      ))}
                  </List>
                </div>
              }
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Upload Event Poster</Typography>
              <div
                className="upload-area"
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                style={{
                  width: "100%",
                  height: "67px",
                  borderRadius: "10px",
                  border: "0.5px solid #94A3B8",
                  display: "flex",
                  backgroundColor: '#fff',
                }}
              >
                <input
                  type="file"
                  multiple
                  accept=".jpg,.png,.pdf"
                  onChange={(e) => this.handleFileChange(e)}
                  style={{ display: 'none' }}
                  id="file-upload"
                />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={uploadIcon} />
                  <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#888888', fontFamily: "Inter", fontSize: "14px", fontWeight: 400 }}>
                    {this.state.eventForm.event_posters && this.state.eventForm.event_posters.length > 0 ? (
                      <div style={{ fontWeight: 700 }}>
                        {this.state.eventForm.event_posters.map((file, index) => (
                          <div key={index}>{file.name}</div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <span style={{ fontWeight: 700 }}>Drag & Drop here</span> or Upload from Computer
                      </>
                    )}
                  </label>
                </Box>
              </div>
              <Box display="flex" justifyContent="flex-end">
                <Typography sx={webStyle.acceptFiles}>Accept Files Type: PDF, JPG, PNG</Typography>
              </Box>
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Link to Event</Typography>
              <StyledTextField
                name="eventLink"
                autoComplete="off"
                fullWidth
                placeholder="www.sparkevents/shakers"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: webStyle.textFieldInputStyles
                }}
                onChange={(event) => this.handleTextFieldChange(event, "event_link")}
              />
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Ages</Typography>
              <StyledSelect
                value={this.state.eventForm.age}
                name="age"
                IconComponent={ExpandMoreIcon}
                fullWidth
                variant="outlined"
                displayEmpty
                inputProps={{
                  style: webStyle.textFieldInputStyles,
                }}
                onChange={(event) => this.handleTextFieldChange(event, "age")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: '6px',
                      padding: '18px 22px',
                      boxSizing: "border-box",
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#f0f0f0',
                        borderRadius: "8px"
                      },
                      '& .Mui-selected': {
                        backgroundColor: '#f1f5f9',
                        borderRadius: "8px"
                      }
                    },
                  },
                }}
              >
                <MenuItem value="all_ages" >All Ages</MenuItem>
                <MenuItem value="over_18">18+</MenuItem>
                <MenuItem value="over_21">21+</MenuItem>
              </StyledSelect>
            </Box>
            <Box style={webStyle.textInputBox}>
              <Typography style={webStyle.textFieldLabelStyles} variant="body2" sx={{ mb: 1 }}>Cost</Typography>
              <StyledTextField
                name="cost"
                autoComplete="off"
                fullWidth
                type="text"
                placeholder="Enter the price of your event"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: webStyle.textFieldInputStyles
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  if (/^\d*$/.test(value)) {
                    this.handleTextFieldChange(event, "cost");
                  }

                }}
                onKeyDown={(event) => {
                  if (!/\d/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
            {this.renderRepeatEvent()}
            <Box style={webStyle.textInputBox}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#D62339",
                  width: "100%",
                  height: "38px",
                  borderRadius: "100px",
                  margin: "30px 0",
                  textTransform: "none"
                }}
                data-test-id="signupBtn"
                onClick={() => {
                  this.handleOpenDetailsPopUp();
                }}
                disabled={!this.isFormValid()}
              >
                <Typography sx={webStyle.signUpbtn}>
                  Create Event
                </Typography>
                <img src={btnIcon} style={{ position: "absolute", right: "5px" }} />
              </Button>
            </Box>
          </DialogContent>
        </BootstrapDialog>
      </LocalizationProvider>
    )
  }


  displayEvent = (data: any) => {
    this.setState({ isEventsPopupOpen: true, currentEvent: data })
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });

 return (
      <MainWrapper>
        <Box bgcolor={"#FAF7F7"}>
          <EventsPopUp open={this.state.isEventsPopupOpen} onClose={this.handleEventPopupClose} onClickShare={this.handleShareModalOpen} event={this.state.currentEvent} />
          <ShareEventModal isOpen={this.state.isShareModalOpen} onClose={this.handleShareModalClose}
            event={this.state.currentEvent?.attributes || {}}
          />
          <AdvancedSearches navigation={undefined} id={""} searchQueries={this.searchFilterQuery} />
          <Box className="showEventsText">
            {this.renderEventsText()}
          </Box>
          
       
        <CustomSnackBar
        data-test-id="Snackbar"
        open={this.state.isLoggedIn === false}
        message="Logged Out Successfully"
        alertSeverity="info"
        autoHideDuration={3000}
        onClose={this.handleSnckbrClose}
        snackbarStyles={{
          height:'58px',
        marginBottom:'500px',
        marginLeft:"555px",
        width:'347px',
        marginTop:'122px',
        borderRadius:'8px',
        gap:'8px',
        backgroundColor:'#FFFFFF',
        zIndex:'9999'
        }}
        alertStyles={{
          backgroundColor: "#FFFFFF",
        }}
      />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"8px"}
            sx={{
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              padding: {
                lg: "30px 10px",
                md: "30px 10px",
                sm: "30px 0px",
                xs: "30px 0px",
              }
            }}
          >
            <Box sx={{
              width: {
                lg: "687px",
                md: "687px",
                sm: "100%",
                xs: "100%"
              }
            }}>
              <EventMap
                id="Map"
                navigation={this.props.navigation}
                eventsMapData={this.state.eventsData?.data}
                showEvent={(events) => this.displayEvent(events)}
                data-test-id="showEvent"
              />
            </Box>
            <Box sx={{
              width: {
                lg: "687px",
                md: "687px",
                sm: "100%",
                xs: "100%"
              }
            }} height={"736px"}>
              <Box marginBottom={"19px"} paddingLeft={"24px"}>
                <Box className="rigthEnd" sx={webStyle.sortByDropdownContainer}>
                  <Box className="hideEventsText">
                    {this.renderEventsText()}
                  </Box>
                  <Box>
                    <Box className="hideMobileAddBtn">
                      {this.renderAddButton()}
                    </Box>
                    {this.renderResultSection()}
                  </Box>
                </Box>
              </Box>
              <Box
                height={"650px"}
                width={"100%"}
                bgcolor={"#FFF"}
                borderRadius={"10px"}
              >

                <EventCalendar
                  navigation={this.props.navigation}
                  id={this.props.id}
                  updateResultLength={this.updateEventsResult}
                  updateCalendarType={this.updateCalendarType}
                  filterSearchData={this.state.searchQueryResult}
                  data-test-id="calendarComponent"
                />

              </Box>

              <Box className="showMobileAddBtn">
                {this.renderAddButton()}
              </Box>
            </Box>
          </Box>
          <Footer />
          {this.renderDialogSection()}
          {this.renderAlert()}
          <EventDetailsModal open={this.state.isEventDetailPopUpOpen} onClose={this.handleClose} eventDetails={this.state.eventForm} createEvent={this.createEvent} />
          <EventSuccessModal open={this.state.isEventCreated} onClose={this.closeEventCreateModal} />
        </Box>
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle: { [key: string]: React.CSSProperties } = {
  modalText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "#343434",
    margin: 0,
  },
  modalHeadingText2: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    textAlign: "left",
    color: "#045E81",
    margin: 0
  },
  footerSection2: {
    background: "#043C61",
    display: "flex",
    justifyContent: "center",
    height: "57px",
    alignContent: "center",
    color: "white",
    gap: "25px",
    alignItems: "baseline",
  },
  footerLink: {
    color: "white",
    display: "flex",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: 400,
    cursor: "pointer",
  },
  searchContainer: {
    border: "1px solid #888888",
    borderRadius: "100px",
    maxHeight: "50px",
    padding: "6px 6px 6px 13px",
    display: "flex",
    alignItems: "center",
    width: "667px",
  },
  sortByDropdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  eventsFound: {
    background:
      "linear-gradient(270deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #D62339 83%, #BC3081 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: "600",
    margin: "0",
  },
  calenderStyle: {
    border: "none",
    width: "100%",
  },
  sortByRelevance: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0",
  },
  sortBy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#F47133",
    paddingLeft: "5px",
  },
  shareEvent: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "25px",
    paddingRight: "12px",
    height: "48px",
    borderRadius: "100px",
    margin: "0",
    background: "#D62339",
    color: "white",
    textTransform: "none",
    boxShadow: `-12px -12px 24px 0px #B30117 inset,
        6px 6px 24px 0px #DB001B inset,
        0px 6px 15px 0px #CE041D33`,
  },
  shareEventIcon: {
    height: "34px",
    width: "34px",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  results: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "10px",
    textAlign: "end",
    marginTop: "10px",
    color: "#94A3B8",
    marginRight:"10px",
  },
  calendarView: {
    fontWeight: "bold",
    color: "#64748B",
    padding: "6px 12px",
    lineHeight: "22px",
    fontFamily: "Inter",
    cursor: "pointer",
    fontSize: "14px",
    border: "1px solid transparent",
  },
  mainContainer: { display: "flex", padding: "20px 60px" },
  mapContainer: {
    flex: 2,
    minHeight: "min(100vh, 736px)",
    maxWidth: "687px",
    maxHeight: "736px",
    borderRadius: "20px",
    overflow: "hidden",
    background: "gray",
  },
  relevanceIon: {
    cursor: "pointer",
    color: "#F47133",
  },
  dropdownContainer: {
    height: "50px",
    borderRadius: "100px",
    color: "#888888",
    border: "1px solid #D9D9D9",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    backgroundColor: "#FFF",
  },
  formControl: {
    width: 310,
  },
  dropdownIcon: {
    color: "#888888",
  },
  dropdownPaper: {
    maxHeight: 262,
    borderRadius: "5px",
    overflowY: "auto",
  },
  hiddenButton: {
    display: "none",
  },
  textField: {
    width: "100%",
    height: "38px",
    borderRadius: "100px",
    border: "0.5px",
    fontFamily: "Inter",
    fontSize: "12px",
    ...(window.innerWidth <= 500 && {
      width: "317px",
    })

  },
  textFieldContiner: {
    marginTop: "10px",
    marginBottom: "18px",
    border: "1px solid green"
  },
  textFieldLabel: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "left",
    color: "#343434",
    marginBottom: "14px"
  },
  addTag: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    color: "#888888",
    padding: "10px 0",
    cursor: "pointer"
  },
  addTagIcon: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "left",
    color: "#3C8D9E"
  },
  acceptFiles: {
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "italic",
    fontWeight: "300",
    textAlign: "left",
  },
  textFieldLabelStyles: {
    fontWeight: 500,
    fontSize: "18px",
    color: "#343434",
    lineHeight: "#343434",
    fontFamily: "Inter"
  },
  textFieldInputStyles: {
    borderRadius: "100px",
    backgroundColor: "white",
    height: "38px",
  },
  textAreaFieldInputStyles: {
    borderRadius: "10px",
    backgroundColor: "white",
    // paddingTop : "2px",
    height: "67px",
    overflow: "hidden",
    boxSizing: "border-box",
    border: "0.5px solid #94A3B8"
  },
  textInputBox: {
    width: "95%",
    marginBottom: "18px"
  },
  textInputColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "95%",
    marginBottom: "18px"
  },
  signUpbtn: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Inter",
  },
  tagContainer: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "11px",
    boxSizing: "border-box",
    position : "relative"
  },
  alertTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#1E293B',
    textAlign: 'center',
  },
  dialogContent: {
    padding: '20px',
  },
  description: {
    fontSize: '16px',
    color: '#4b5563',
    textAlign: 'center',
    marginBottom: '20px',
  },
  infoBox: {
    backgroundColor: '#f3e8ff',
    borderRadius: '6px',
    padding: '16px',
    marginBottom: '20px',
  },
  infoText: {
    fontSize: '14px',
    color: '#6b21a8',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '5px',
    textTransform: 'none',
  },
  primaryButton: {
    backgroundColor: '#D62339',
    color: 'white'
  },
  secondaryButton: {
    backgroundColor: '#e5e7eb',
    color: '#374151'
  },
  crossIcon: {
    position: "absolute",
    top: "25px",
    right: "25px",
    cursor: "pointer"
  },
  locationHeading: {
    width: "100%",
    marginLeft: "22px",
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: "15px",
    textAlign: "left",
    color: "#343434",
    marginTop: "20px"
  },
  venuNameHeading: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: "15px",
    textAlign: "left",
    color: "#343434"
  },
  containerCloseIcon: {
    fontSize : "18px", 
    color : "black", 
    position  :"absolute",
    top : "8px", 
    right : "10px", 
    cursor : "pointer"
    
   }
};

const MainWrapper = styled(Box)({
  "& .hideMobileAddBtn": {
    "@media (max-width: 600px)": {
      display: "none"
    },
  },
  "& .showMobileAddBtn": {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
      margin: "0 30px"
    },
  },
  "& .resultsMobile" : {
    // display: "none",
    "@media (max-width: 600px)": {
      display: "block",
      margin: "34px 32px 20px 32px"
      // margin: "0 30px"

    },
  },
  "& .hideEventsText" : {
    "@media (max-width: 600px)": {
      display: "none"
    },
  },
  "& .showEventsText": {
    display: "flex",
    opacity: 0,
    "@media (max-width: 600px)": {
      opacity: 1,
      justifyContent: "center"
    },
  },
  "& .sortByRelevanceDivs": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  },
  "& .rigthEnd": {
    "@media (max-width: 600px)": {
      float: "right"
    },
  },
  "& .addEventBtn": {
    "@media (max-width: 600px)": {
      position: "relative"
    },
  },
  "& .addEventBox": {
    "@media (max-width: 600px)": {
      position: "absolute",
      left: 0
    },
  }
})
// Customizable Area End

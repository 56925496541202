Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getFilterOptionsApiEndPoint = "bx_block_events/events/filter_options";
exports.eventsApi="bx_block_events/events"
exports.getFilteredEventsApiEndPoint = "bx_block_events/events?";
// Customizable Area End

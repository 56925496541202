import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";

interface CustomSnackBarProps {
  open: boolean; // Controls Snackbar visibility
  message?: string; // Message to display inside the alert
  autoHideDuration?: number; // Duration before Snackbar auto-closes
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void; // Close handler
  alertSeverity?: AlertProps["severity"]; // Alert severity (success, error, warning, info)
  snackbarStyles?: React.CSSProperties; // Additional styles for Snackbar
  alertStyles?: React.CSSProperties; // Additional styles for Alert
}

const CustomSnackBar: React.FC<CustomSnackBarProps> = ({
  open,
  message ,
  autoHideDuration ,
  onClose,
  alertSeverity ,
  snackbarStyles,
  alertStyles,
}) => {
  return (
    <Snackbar
      data-test-id="Snackbar"
      sx={{
        height: "58px",
        marginBottom: "500px",
        marginLeft: "555px",
        width: "347px",
        marginTop: "122px",
        borderRadius: "8px",
        gap: "8px",
        backgroundColor: "#FFFFFF",
        zIndex: "9999",
        ...snackbarStyles,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={alertSeverity}
        sx={{
          width: "100%",
          padding: "8px 16px 8px 8px",
          gap: "8px",
          backgroundColor: "#FFFFFF",
          zIndex: "9999",
          ...alertStyles,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;

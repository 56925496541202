import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDate } from './HelperUtils';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { format } from 'date-fns';
import {
  createTheme
} from "@mui/material/styles";

import { useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});


interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  onClickShare : ()=>void;
  event : any
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px 10px 40px 10px',
    overflow: 'hidden',
    background: "#F3F3F3",
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px',
    },
  },
  '& .modal-parent': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: 'border-box',
    padding: "32px",
    backgroundColor: "#F3F3F3",
    overflowY: "scroll",
    overflowX : "hidden",
    [theme.breakpoints.down('sm')]: {
      padding : "17px"
    },
  },
  '& .modal-content': {
    maxWidth: "560px",
    boxSizing: 'border-box',
  },
  '& .eventDetailHeading': {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "12px",
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      flexDirection : "column",
      alignItems : "center",
      gap  :"16px"
    },
  },
  '& .eventDetailText': {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '10px',
    textAlign: 'left',
    marginBottom: "13px",
    marginTop: "13px",
  },
  '& .ageRestriction': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  "& .eventTimeCard": {
    maxWidth: '549px',
    height: 'auto',
    borderRadius: '20px',
    boxShadow: '0px 4px 4px 0px #0000001A',
    margin: "18px 0",
    background: "white",
    // display: "flex"
  },
  "& .eventOrganizerHeading": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '15px',
    marginBottom: "6px"
  },
  "& .eventOrganizerSubHeading": {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '10px',
    color: "#818384"
  },
  "& .eventDetails": {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: "justify",
    whiteSpace: 'pre-wrap',
    color: "#343434"
  }
}));

const StyledHr = styled('hr')({
  border: '1px solid #E2E8F0',
  marginTop: '19px',
  marginBottom: '16px',
});


const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '221px',
  overflow: 'hidden',
  borderRadius: '10px',
  boxSizing: 'border-box',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'top',
});

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  color: theme.palette.common.white,
  boxSizing: 'border-box',
  textAlign: 'center',
  background : "rgba(0,0,0,0.3)"
}));

const EventHeading = styled('div')(({ theme }) => ({
  width: "100%",
  display: 'flex',
  flexDirection: 'column',
  alignItems: "flex-start",
  paddingLeft: "24px",
  paddingBottom: "20px",
  boxSizing : "border-box",
  "& .eventName": {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '10px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  "& .categories": {
    display: "flex",
    gap: "15px",
  }
}));

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '14px',
  right: '19px',
  color: '#fff',
  height : "41px",
  width : "41px",
  borderRadius : "50%",
  border : "3px solid #E2E8F0"
});

const StyledCategory = styled("div")<{ categoryLength: number }>(({ theme, categoryLength }) => {
  const dynamicWidth = Math.max(85, categoryLength*10+20); // Adjust the multiplier and base value as needed
  const dynamicBorderRadius = Math.min(dynamicWidth / 2, 100); // Ensure a circular shape for smaller categories

  return {
    height: "28px",
    width: `${dynamicWidth}px`,
    border: "1px solid #F3F3F3",
    borderRadius: `${dynamicBorderRadius}px`,
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "8px",
    background: "#3434344D",
    boxSizing: "border-box",
    "& .categoryBtnTxt": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter",
      lineHeight: "10px",
    },
  };
});

const GradientButton = styled(Button)({
  width: '117px',
  height: '34px',
  borderRadius: '100px',
  background: 'linear-gradient(94.85deg, #BC3081 5.19%, #D62339 23.82%, #FE9612 36.03%, #FED576 54.68%, #96B999 70.72%, #3C8D9E 82.89%, #045E81 96.01%)',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '10px',
  color: "black",
  [theme.breakpoints.down('sm')]: {
    width : "100%"
  },
});

const ActionButtons = styled('div')(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "45px",
  gap: "12px",
  marginTop: "60px",
  "& .addToCalendarBtn": {
    width: "90%",
    backgroundColor: "#F47133",
    borderRadius: '100px 50px 50px 100px',
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    cursor : "pointer"
  },
  "& .shareBtn": {
    minWidth: "45px",
    height  :"45px",
    backgroundColor: "#D62339",
    borderRadius: "10px",
    display: "flex",
    cursor : "pointer"
  },
  "& .shareIcon": {
    color: "white",
    fontSize: "24px",
    margin: "auto"
  },
  "& .calendarIconContainer": {
    minWidth: "38px",
    height: "38px",
    AspectRatio:1,
    borderRadius: "50%",
    background: "#F3F3F3",
    display: "flex"
  },
  "& .calendarIcon": {
    color: "#F47133",
    fontSize: "20px",
    margin: "auto"
  },
  "& .addToCalTextContainer": {
    display: "flex",
    width: "100%",
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '10px',
    justifyContent: "center",
    color: "white"
  }
}));

const StyledEventTimeItem = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: "12px",
  position: 'relative',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    borderBottom : "1px solid #E2E8F0",
    padding  :"0px 24px 12px 24px",
    
  },
  '& .eventTimeHeading': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '10px',
    color: "#343434",
    marginBottom: "6px"
  },
  '& .eventTimeContent': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    color: "#818384",
    marginRight : "5px"
  }
});


const category = (category: any) => {
  return (
    <StyledCategory id={`category-${category.id}`} categoryLength={category.length}>
      <img src={require("./music.png")} />
      <Typography className='categoryBtnTxt' >{category}</Typography>
    </StyledCategory>
  )
}

const eventAge = (age: string): string => {
  switch (age) {
    case 'all_ages':
      return 'All Ages';
    case 'over_18':
      return 'Over 18';
    case 'over_21':
      return 'Over 21';
    default:
      return 'All Ages';
  }
};


interface EventTimeItemProps {
  icon: "location" | "date" | "time"
  title: string
  content: string
  showDivider?: boolean
}

const EventTimeItem: React.FC<EventTimeItemProps> = ({ icon, title, content, showDivider = true }) => {
  const icons = {
    location: PlaceOutlinedIcon,
    date: CalendarMonthIcon,
    time: AccessTimeIcon
  }

  const Icon = icons[icon]

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledEventTimeItem>
      <Icon sx={{ color: '#045E81', fontSize: 20 }} />
      <Box>
        <Typography className='eventTimeHeading'>
          {title}
        </Typography>
        <Typography className='eventTimeContent'>
          {content}
        </Typography>
      </Box>
      {showDivider && !isMobile && (
        <Divider orientation="vertical" flexItem sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', height: '48px', marginRight : "7px" }} />
      )}
    </StyledEventTimeItem>
  )
}

const convertTo24Hour = (time:any) => {
  const [hour, minute] = time.split(':');
  const ampm = time.slice(-2).toUpperCase();
  let hours = parseInt(hour, 10);
  const minutes = parseInt(minute, 10);

  if (ampm === 'PM' && hours !== 12) hours += 12;
  if (ampm === 'AM' && hours === 12) hours = 0;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatDateTime = (date: any, time: any) => {
  const formattedTime = convertTo24Hour(time);
  const combinedDateTime = `${date}T${formattedTime}`;
  return format(new Date(combinedDateTime), "yyyyMMdd'T'HHmmss");
};

const handleAddToCalendar = (eventData:any) => {

  const {
    title: eventName,
    start_date: startDate,
    end_date: endDate,
    start_time: startTime,
    end_time: endTime,
    virtual_meeting_link: eventLink,
    location,
    description,
  } = eventData.attributes;
  try {
    const formattedStartTime = formatDateTime(startDate, startTime);
    const formattedEndTime = formatDateTime(endDate, endTime);
    const locationName = location?.data ? location.data.attributes.venue_name : 'Online';

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      eventName
    )}&dates=${formattedStartTime}/${formattedEndTime}&location=${encodeURIComponent(
      locationName
    )}&details=${encodeURIComponent(description || 'No description provided')}`;

    window.open(googleCalendarUrl, '_blank');
  } catch (error) {
    alert('Unable to create the event link.');
  }
};


const EventPopup: React.FC<CustomDialogProps> = ({ open, onClose, onClickShare, event }) => {
  const attributes = event?.attributes || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const subCategories:any = attributes.sub_categories?.data ||[];

  // Duplicate categories for the looping effect
  const duplicatedCategories = [...subCategories, ...subCategories];

  const updatePadding = () => {
    const container = scrollRef.current;
    if (!container) return; // Ensure container exists
  
    const children = Array.from(container.children) as HTMLElement[]; // Cast children to HTMLElement[]
    if (children.length > 0) {
      const containerRect = container.getBoundingClientRect();
  
      // Check if the first child is cropped
      const firstChild = children[0];
      const firstChildRect = firstChild.getBoundingClientRect();
      const isFirstChildCropped = firstChildRect.left < containerRect.left;
  
      // Check if the last child is cropped
      const lastChild = children[children.length - 1];
      const lastChildRect = lastChild.getBoundingClientRect();
      const isLastChildCropped = lastChildRect.right > containerRect.right;
  
      // Add dynamic margins instead of padding to avoid increasing width
      firstChild.style.marginLeft = isFirstChildCropped
        ? `${containerRect.left - firstChildRect.left}px`
        : "0px";
      lastChild.style.marginRight = isLastChildCropped
        ? `${lastChildRect.right - containerRect.right}px`
        : "0px";
    }
  };
  
  
  useEffect(() => {
    const updatePadding = () => {
      const container = scrollRef.current;
      if (!container) return; // Ensure container exists
  
      const children = Array.from(container.children) as HTMLElement[]; // Cast children to HTMLElement[]
      if (children.length > 0) {
        // Get the container's visible width
        const containerRect = container.getBoundingClientRect();
  
        let visibleStart = 0;
        let visibleEnd = 0;
  
        children.forEach((child) => {
          const childRect = child.getBoundingClientRect();
  
          // Check if the child is fully visible within the container
          if (childRect.left >= containerRect.left && childRect.right <= containerRect.right) {
            if (!visibleStart) visibleStart = childRect.left - containerRect.left;
            visibleEnd = childRect.right - containerRect.left;
          }
        });
  
        // Adjust padding to ensure correct alignment after resize
        container.style.paddingLeft = `${-visibleStart}px`;
        container.style.paddingRight = `${containerRect.width - visibleEnd}px`;
      }
    };
  
    if (scrollRef.current) {
      updatePadding(); // Initial adjustment
      window.addEventListener("resize", updatePadding); // Update on resize

      return () => {
        window.removeEventListener("resize", updatePadding); // Cleanup listener on unmount
      };
    }
  }, [subCategories]);
  
  const handleScroll = () => {
    
    if (scrollRef.current) {
      const container = scrollRef.current;
      const currentScrollLeft = container.scrollLeft;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      // Dynamically calculate the scroll distance based on the width of the first visible child
      const children = Array.from(container.children) as HTMLElement[];
      const firstVisibleChild = children.find((child) => {
        const childRect = child.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        return childRect.left >= containerRect.left && childRect.right <= containerRect.right;
      });
  
      const scrollDistance = firstVisibleChild
        ? firstVisibleChild.getBoundingClientRect().width
        : 100; // Default scroll distance if no visible child is found
  
      if (currentScrollLeft + container.clientWidth >= maxScrollLeft) {
        // Reset scroll to the start if it reaches the end
        container.scrollLeft = 0;
      } else {
        // Scroll forward by the calculated distance
        container.scrollBy({
          left: scrollDistance,
          behavior: "smooth",
        });
      }
    }
  };
  
  
  
  
  
  return (
    <BootstrapDialog onClose={onClose} open={open}>
      {attributes.title &&
      <Box className='modal-parent'>
        <Box className='modal-content'>
          <ImageContainer>
            <StyledImage src={attributes.event_posters[0].url} alt="event_image" />
            <Overlay>
              <EventHeading>
                <Box sx={{display : "flex", width  :"100%", marginBottom  :"10px" }}>
                  <Box
                    ref={scrollRef}
                    className="categories"
                    sx={{
                      display: "flex",
                      overflowX: "hidden",
                      scrollBehavior: "smooth",
                      gap: "15px",
                      width  :"80%",
                    }}
                  >
                    {duplicatedCategories.map((item:any, index:any)=>{
                    return category(item.attributes.name)
                    })}
                  </Box>
                  <Box sx={{width : "20%", display : "flex"}}>
                  <Box
                    onClick={handleScroll}
                    sx={{
                      color: "#fff",
                      margin : "auto",
                      width  :"32px",
                      height : "28px",
                      borderRadius : "26px",
                      border : "1px solid #ffffff", 
                      backgroundColor: "#3434344D",
                      display : "flex",
                      cursor :"pointer"
                    }}
                  >
                    <ArrowForwardIosIcon style={{width : "10px", height : "10px", margin : "auto"}} />
                  </Box>
                  </Box>
                </Box>
                <Typography className='eventName' variant="h6">{attributes.title}</Typography>
              </EventHeading>
              <CloseButton onClick={onClose}>
                <CloseRoundedIcon />
              </CloseButton>
            </Overlay>
          </ImageContainer>
          <Box style={{paddingLeft : isMobile? "0px" : "10px"}}>
          <Box className="eventDetailHeading">
            <Box >
              <Typography className="eventDetailText">About the Event </Typography>
              <Typography className='ageRestriction'>{eventAge(attributes.age)}
                <span style={{color : attributes.categoryClr}}> | Category</span>
              </Typography>
            </Box>
            <GradientButton>
              ${attributes.cost}
            </GradientButton>
          </Box>
          <Box className="eventTimeCard">
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              p: 2,
              boxSizing: 'border-box',
              flexWrap: 'wrap',
              height:"auto",
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column', 
                alignItems: 'stretch', 
                padding : "15px 30px",
                gap : "12px"
              },
             }}>
              <Box sx={{ flex: '0 0 45%' }}>
                <EventTimeItem
                  icon="location"
                  title="Location"
                  content={attributes.location?.data?.attributes?.venue_name + 
                    attributes.location?.data?.attributes?.address
                  }
                />
              </Box>
              <Box sx={{ flex: '0 0 30%' }}>
                <EventTimeItem
                  icon="date"
                  title="Date"
                  content={attributes.start_date && formatDate(attributes.start_date)}
                />
              </Box>
              <Box sx={{ flex: '0 0 25%' }}>
                <EventTimeItem
                  icon="time"
                  title="Time"
                  content={
                    attributes.start_time && attributes.end_time
                      ? `${event.attributes.start_time} -  ${event.attributes.end_time}`
                      : "Time not available"
                  }    
                  showDivider={false}
                />
              </Box>
            </Box>
          </Box>
          <Typography className='eventOrganizerHeading'>
            {attributes.event_organizer}
          </Typography>
          <Typography className='eventOrganizerSubHeading'>
            Event Organizer
          </Typography>
          <StyledHr />
          <Typography className='eventDetails'>
           {attributes.description}
          </Typography>
          <ActionButtons>
            <Box className='addToCalendarBtn'>
              <Box className='calendarIconContainer'>
                <CalendarTodayIcon className='calendarIcon' />
              </Box>
              <Box className="addToCalTextContainer" onClick={()=>handleAddToCalendar(event)}>
                <Typography>
                  Add To Calendar
                </Typography>
              </Box>
            </Box>
            <Box className='shareBtn' onClick={onClickShare}>
              <ShareIcon className='shareIcon' />
            </Box>
          </ActionButtons>
          </Box>
        </Box>
      </Box>
      }            
    </BootstrapDialog>
  );
};

export default EventPopup;

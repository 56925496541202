// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import {  IEvent, EventInfo, MapEventsData, UserLocation } from "../../../components/src/typeInterfaces";
import { locationMarkerDarkBlue, locationMarkerLightGreen, locationMarkerOrange, locationMarkerPink, locationPinDarkBlue, locationPinLightGreen, locationPinOrange, locationPinRed, locationPinPink } from "./assets";
import { locationMarkerRed } from "../../landingpage/src/assets";
import { truthyValue, fetchGeolocationCoordinates, getCurrentLocation } from "../../../components/src/utils";
import moment from "moment";

export const configJson = require("../../landingpage/src/config");
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  eventsMapData : IEvent[]
  showEvent : (events : IEvent) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  screenWidth: any;
  mapEventsData : MapEventsData[],
  isGoogleScriptLoaded: boolean
  mapPopUpId : string,
  eventInfoArr : EventInfo[],
  selectedColorCode : string,
  userCurrentLocation : UserLocation
  isLocationLoaded : boolean,
  locationPermissionPopUp : boolean
  locationDeniedState : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EventMapController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        screenWidth: window.innerWidth,
        mapEventsData : [],
        isGoogleScriptLoaded : false,
        mapPopUpId : "",
        eventInfoArr : [],
        selectedColorCode : "",
        userCurrentLocation : {
          lat : 0,
          lng : 0
        },
        isLocationLoaded : false,
        locationPermissionPopUp : false,
        locationDeniedState : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // some code here
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
      if(prevProps.eventsMapData !== this.props.eventsMapData) {
         this.setState({ mapEventsData : this.groupByLocation(this.props.eventsMapData)})
      }
     window.addEventListener("resize", this.handleResize)

  }

  async componentDidMount() {
     this.loadScript()
     this.checkUserGeoLocation()
     window.addEventListener("resize", this.handleResize)
  }

  async componentWillUnmount() {
    window.addEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

    loadScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${configJson.mapApiKey}&libraries=&v=weekly`;
        script.async = true;
        script.onload = () => this.setState({ isGoogleScriptLoaded: true });
        script.onerror = () => console.error("Google Maps API script failed to load");
        document.head.appendChild(script);
    }

    checkUserGeoLocation = () => {
      fetchGeolocationCoordinates((cordinates) => {
        this.setState({
          userCurrentLocation: {
            lat: cordinates.latitude,
            lng: cordinates.longitude,
          },
          isLocationLoaded: true,
          locationPermissionPopUp: false
        })
      }, (error) => {
        this.setState({ locationDeniedState: true, locationPermissionPopUp: true })
        console.error(error);
      }, this.openLocationPermissionModal
      )
    }


   


  handletLatCheck = (events : IEvent) => {
        let lat = 0
        if(events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes && events.attributes.location.data.attributes.latitude) {
            lat = events.attributes.location.data.attributes.latitude
        }
        return lat
  }

  handletLongCheck = (events: IEvent) => {
    let long = 0
    if (events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes && events.attributes.location.data.attributes.longitude) {
      long = events.attributes.location.data.attributes.longitude
    }
    return long
  }

  markerArray = [
    {icon : locationMarkerRed, colorCode : "#D62339", pinIcon : locationPinRed},
    {icon : locationMarkerDarkBlue, colorCode : "#043C61", pinIcon : locationPinDarkBlue},
    {icon : locationMarkerOrange, colorCode : "#F47133", pinIcon : locationPinOrange},
    {icon : locationMarkerPink, colorCode : "#BC3081", pinIcon : locationPinPink},
    {icon : locationMarkerLightGreen, colorCode : "#3C8D9E", pinIcon : locationPinLightGreen},  
  ];

  handleDynamicMarker = (index : number) => {
    const selectedIndex = this.markerArray[index % this.markerArray.length]
    if(selectedIndex) {
       return selectedIndex.icon
    }
  }

  handleDynamicBgColor = (index : number) => {
    const selectedIndex = this.markerArray[index % this.markerArray.length]
    if(selectedIndex) {
       return selectedIndex.colorCode
    }
  }

  handleDynamicMarkerPinColor = (index : number) => {
    const selectedIndex = this.markerArray[index % this.markerArray.length]
    if(selectedIndex) {
       return selectedIndex.pinIcon
    }
  }

  groupByLocation = (events : IEvent[]) => {
    const locationArr = events.map(event => event.attributes.location && event.attributes.location.data ? truthyValue(event.attributes.location.data.id) : null)
    const reapetedLocation = locationArr.filter(item => item !== null)
    const uniqueLocationIds = Array.from(new Set(reapetedLocation));

    const modifiedArray : MapEventsData[] = uniqueLocationIds.map(locationId => {
      const filteredEvents = events.filter(
        event => event.attributes.location?.data?.id === locationId
      );

      return {
        locationId: locationId.toString(), 
        events: filteredEvents,
        totalEvents : filteredEvents.length
      };
    })
    return modifiedArray
  }
 
  openMapPopUp = (event : IEvent[], locationId : string) => {

    const filteredEventsData = event.map(item => {
      return {
        eventId: item.id,
        eventTitle: truthyValue(item.attributes.title),
        eventImage: item.attributes.event_posters && item.attributes.event_posters.length ? truthyValue(item.attributes.event_posters[0].url) : "",
        eventAddress: this.handleLocationObject(item),
        eventDates: {
          eventDate: this.getFormattedDate(item.attributes.start_date, item.attributes.start_time, item.attributes.end_time).date,
          eventTimeRange: this.getFormattedDate(item.attributes.start_date, item.attributes.start_time, item.attributes.end_time).time
        },
        currentEvent: item
      }
    })

     this.setState({ eventInfoArr : filteredEventsData, mapPopUpId : locationId})
    
  }

  closeMapPopUp = () => {
    this.setState({
       mapPopUpId : "",
       eventInfoArr : []
    })
  }

  showLocationMessage = {
    locationRequiredMsg : "Spark wants to know your location",
    locationDeniedMsg : "Location access was denied. Please enable location permissions from your browser settings."
  }

  openLocationPermissionModal = () => {
    this.setState({ locationPermissionPopUp: true })
  }

  closeLocationPermissionModal = () => {
    this.setState({ locationPermissionPopUp: false })
  }

  allowLocationAccess = () => {
     getCurrentLocation((cordinates) => {
      this.setState({
        userCurrentLocation: {
          lat: cordinates.latitude,
          lng: cordinates.longitude,
        },
        isLocationLoaded: true,
        locationPermissionPopUp : false
      })
    }, (error) => {
      this.setState({ locationDeniedState : true, locationPermissionPopUp : true})
      console.error(error);
    })
  }

  handleLocationObject = (events : IEvent) => {
    let locationTxt = ""
    if (events && events.attributes && events.attributes.location && events.attributes.location.data && events.attributes.location.data.attributes) {
      locationTxt = events.attributes.location.data.attributes.address
    }
     return locationTxt
  }

  getFormattedDate = (startDate : string, startTime : string, endTime : string) => {
    const startDateTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD hh:mm A");
    const endDateTime = moment(`${startDate} ${endTime}`, "YYYY-MM-DD hh:mm A");

    const formattedDate = startDateTime.format("ddd, DD MMM, YYYY");
    const formattedTimeRange = `${startDateTime.format("HH:mm")} - ${endDateTime.format("HH:mm")}`;

    return { date :formattedDate, time : formattedTimeRange};
  }

  // Customizable Area End
}

// Customizable Area End
export const handleStorageChange = (
    setState: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const loggedOutFlag = localStorage.getItem('showLogoutSnackbar');
  
    setState({ isLoggedIn });
  
    if (loggedOutFlag === 'true') {
        setState({ isLoggedIn: null });
      showLogoutSnackbar(setState)
      localStorage.removeItem('showLogoutSnackbar');
    }
  };
  
  export const showLogoutSnackbar = (
    setState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setState({ isLoggedIn: false });
    setTimeout(() => {
        handleSnackbarClose(setState)
    }, 3000);
  };
  
  export const handleSnackbarClose = (
    setState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    localStorage.removeItem('isLoggedIn');
    setState({ isLoggedIn: null });
  };
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { SelectChangeEvent } from "@mui/material/Select";
import { SearchQueryData } from "../../../components/src/typeInterfaces"
import React from "react";
import dayjs from "dayjs";
import { handleSnackbarClose, handleStorageChange, showLogoutSnackbar } from "./components/utils";

export type CalendarViewType = "month" | "week" | "work_week" | "day" | "agenda";

interface IEventData {
  data: IEvent[];
  meta: {
    events_day_counts: Record<string, number>;
    events_total_count: number;
  };
}

interface IEvent {
  id: string;
  attributes: IEventAttributes;
}

interface IEventAttributes {
  id: number;
  title: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  description: string;
  location: ILocation | null;
  identity_tags: IdentityTag | null;
}

interface ILocation {
  data: {
    id: string;
    attributes: ILocationAttributes;
  };
}

interface ILocationAttributes {
  id: number;
  venue_name: string;
  address: string;
  latitude: number | null;
  longitude: number | null;
  image: string | null;
}

interface Community {
    id: string;
    attributes: {
      id: number;
      name: string;
    };
}

interface IdentityTag {
  data: Community[];
}

interface ICalendarEvents {
  title: string;
  start: Date;
  end: Date;
}

interface Category {
  id: number;
  name: string;
}

interface SubCategoryAttributes {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  category: Category;
}

interface SubCategory {
  id: string;
  type: string;
  attributes: SubCategoryAttributes;
}

interface Location {
  id: string;
  type: string;
  attributes: {
    id: number;
    venue_name: string;
    address: string;
    latitude: number; 
    longitude: number;
    image: string | null;
  };
}

export interface EventForm{
    event_link: string;
    title: string;
    event_type:string;
    start_date: string;
    end_date: string;
    location_id: number;
    location : Location[];
    virtual_meeting_link: string;
    virtual: boolean;
    event_organizer_id: number;
    event_organizer:string;
    description: string;
    cost: number;
    repeat_event: boolean;
    custom_repeat_in_number: number;
    custom_repeat_every: string;
    sub_category: SubCategory[];
    identity_tag_ids: number[];
    identity_tags:Community[];
    start_time: string;
    end_time: string;
    age: string;
    created_by: string;
    status: string;
    talent_ids: number[];
    event_posters:File[]
}

interface RepeatDay {
  label : string,
  value : string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: string;
  selectedEvents: string[];
  selectedCommunities: string[];
  searchQuery: string;
  calendarView: CalendarViewType;
  eventsData: IEventData;
  calendarEventsData: ICalendarEvents[];
  dayEventsData: Record<string, IEvent[]>;
  isEventsPopupOpen : boolean;
  isShareModalOpen : boolean;
  currentEvent : any;
  eventsLength : number,
  searchQueryResult : SearchQueryData
  eventForm:EventForm;
  eventTypes: SubCategory[];
  searchTag : string;
  locationSearchTag : string;
  communities: Community[];
  selectedTag:Community[];
  anchorEl: any,
  anchorEl2 : any,
  highlightedDates:any[],
  isEventDialogOpen : boolean,
  isEventDetailPopUpOpen : boolean,
  isTagClicked:boolean,
  locations:Location[],
  showLocationTags : boolean,
  isAlertOpen:boolean,
  isEventCreated:boolean,
  isCalendarOpen: boolean,
  isCalendarOpen2: boolean
  repeatEvent: boolean
  repeatEvery: number
  repeatType: string
  repeatOn: RepeatDay[]
  endsOption: string
  endDate: string
  occurrences: number
 
  isLoggedIn: boolean | null

  // Customizable Area End
}

interface SS {
  id: any;
}


export default class EventScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleEventPopupClose = this.handleEventPopupClose.bind(this);
    this.handleShareModalOpen = this.handleShareModalOpen.bind(this);
    this.handleShareModalClose = this.handleShareModalClose.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedDate: new Date().toLocaleDateString(),
      selectedEvents: [],
      selectedCommunities: [],
      searchQuery: "",
      calendarView: "month",
      eventsData: {
        data: [],
        meta: {
          events_day_counts: {},
          events_total_count: 0
        }
      },
      calendarEventsData: [],
      dayEventsData: {},
      isEventsPopupOpen : false,
      isShareModalOpen : false,
      currentEvent : {},
      eventsLength : 0,
      searchQueryResult : this.blankQueriesData,
      eventForm: {
        event_link: "",
        title: "",
        event_type : "",
        start_date: "",
        end_date: "",
        location_id: 0,
        location:[],
        virtual_meeting_link: "",
        virtual: false,
        event_organizer_id: 0,
        event_organizer : "",
        description: "",
        cost: 0,
        repeat_event: false,
        custom_repeat_in_number: 0,
        custom_repeat_every: "",
        sub_category: [],
        identity_tag_ids: [],
        identity_tags:[],
        start_time: "",
        end_time: "",
        age: "all_ages",
        created_by: "",
        status: "",
        talent_ids: [],
        event_posters:[]
      },
      eventTypes : [],
      searchTag : "",
      locationSearchTag : "",
      communities:[],
      selectedTag : [],
      anchorEl: null,
      anchorEl2:null,
      highlightedDates:[],
      isEventDialogOpen:false,
      isEventDetailPopUpOpen:false,
      isTagClicked:false,
      locations : [],
      showLocationTags:false,
      isAlertOpen:false,
      isEventCreated:false,
      isCalendarOpen:false,
      isCalendarOpen2:false,
      repeatEvent: false,
      repeatEvery: 1,
      repeatType: "week",
      repeatOn: [],
      endsOption: "never",
      endDate: "",
      occurrences: 1,
      
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' ? true : null ,


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getFilteredEventsApiId && responseJson) {
        this.handleGetFilteredEvents(responseJson)
      }
      if(apiRequestCallId === this.getEventTypesApiId){
        this.setState({eventTypes : responseJson.data})
      }
      if(apiRequestCallId === this.getIdentityTagsApiId){
        this.setState({communities : responseJson.data})
      }
      if(apiRequestCallId === this.getEventLocationApiId){
        this.setState({locations : responseJson.data})
      }
      if(apiRequestCallId === this.createEventAPiId){
        this.setState({isEventCreated  :true})
      }
      if(apiRequestCallId === this.getEventByIdApiId){
        if(responseJson.data){
          this.setState({
            isEventsPopupOpen : true,
            currentEvent : responseJson.data
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getFilteredEventsApiId: string = "";
  getFilteredOptionsApiId: string = "";
  getEventTypesApiId: string = "";
  getIdentityTagsApiId: string = "";
  createEventAPiId: string = "";
  getEventLocationApiId:string = "";
  getEventByIdApiId:string = "";
  
  openCalendar = (e:any) => {
    e.stopPropagation();
    this.setState({ isCalendarOpen: true , isCalendarOpen2 : false}); 
  };

  openCalendar2 = (e:any) => {
    e.stopPropagation();
    this.setState({ isCalendarOpen2: true, isCalendarOpen : false }); 
  };

  handleCloseCalendar = ()=>{
    this.setState({ isCalendarOpen: false , isCalendarOpen2 : false});
  }

  handleTimeChange = (newValue : any, fieldName : string)=>{
    const formattedTime = dayjs(newValue).format("HH:mm:ss");
    this.setState({
      eventForm : {
        ...this.state.eventForm,
        [fieldName] : formattedTime
      }
    })
  }

  handleDateChange = (newDate:any, dateField:string) => {
    if (newDate) {
      const formattedDate = newDate.format('YYYY-MM-DD'); 
      this.setState({
        isCalendarOpen2: false,
        eventForm: {
          ...this.state.eventForm,
          [dateField]: formattedDate,
        },
      });
    } else {
      this.setState({
        isCalendarOpen2: false,
        eventForm: {
          ...this.state.eventForm,
          [dateField]: "",
        },
      });
    }
  };
  
  

  

  handleGetFilteredEvents = (responseJson : any)=>{
    const eventData = this.state.calendarView === "month" ? Object.entries(responseJson.meta.events_day_counts).map(([date, count]) => ({
      start: new Date(date),
      end: new Date(date),
      title: count as string,
    })) : [];
    const sortedEvents = responseJson.data.sort((aEvent: IEvent, bEvent: IEvent) => {
      const timeA = moment(aEvent.attributes.start_time, "hh:mm A");
      const timeB = moment(bEvent.attributes.start_time, "hh:mm A");
  
      return timeA.isBefore(timeB) ? -1 : 1;
    });
    const dayEventsData = this.groupEventsByHour(sortedEvents);
    this.setState({ eventsData: {...responseJson, data: sortedEvents }, calendarEventsData: eventData, dayEventsData: dayEventsData });
  }
  TermsAndConditionsApiCallId: string = "";
  PrivacyPolicyApiCallId: string = "";

  componentDidMount = async () => {
    this.getFilteredOptionsData();
    this.getFilteredEventsType();
    this.getIdentityTags();
    this.getEventLocations();
    const id = this.props.navigation.state.params.path.id;
    if (id) {
      this.getEventById(parseInt(id));
    }

    window.addEventListener('localStorageChange', () => {
      handleStorageChange(this.setState.bind(this));
  });

  };


  async componentWillUnmount() {
    // window.removeEventListener('localStorageChange', this.handleStorageChange);
    
    window.removeEventListener('localStorageChange', () => {
      handleStorageChange(this.setState.bind(this));
  });


  }



  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
    window.addEventListener('localStorageChange', ()=>{
      handleStorageChange(this.setState.bind(this))
    });
    
    }


handleSnckbrClose=()=>{
  handleSnackbarClose(this.setState.bind(this))


}

  groupEventsByHour = (eventData: IEvent[]) => {
    const groupedEvents: Record<string, IEvent[]> = {};
  
    eventData.forEach((event) => {
      const eventStartTime = moment(event.attributes.start_time, "hh:mm A");
      const hourSlot = eventStartTime.format("H a");
  
      if (!groupedEvents[hourSlot]) {
        groupedEvents[hourSlot] = [];
      }
      groupedEvents[hourSlot].push(event);
    });
  
    return groupedEvents;
  };

  handleEventChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    this.setState({
      selectedEvents: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleCommunityChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    this.setState({
      selectedCommunities: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };


  getFilteredEventsData = () => {
    const newDate = new Date(this.state.selectedDate);
    let endPointString =
      configJSON.getFilteredEventsApiEndPoint +
      `${
        this.state.calendarView === "day"
          ? `date=${moment(newDate).format("YYYY-MM-DD")}`
          : `month=${moment(newDate).format(
              "MM"
            )}&year=${moment(newDate).format("YYYY")}`
      }&search=${this.state.searchQuery}`;
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredEventsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointString
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilteredOptionsData = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilteredOptionsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterOptionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  blankQueriesData : SearchQueryData = {
     searchQuery : "",
     communityTypes : [],
     eventTypes : []
  }

  searchFilterQuery = (eventType : string[], communityTypes : string[], searchQuery : string) => {
      this.setState({
         searchQueryResult : {
           searchQuery,
           communityTypes,
           eventTypes : eventType
         }
      })
  }

  handleEventPopupClose(){
    this.setState({isEventsPopupOpen : false})
  }

  handleShareModalOpen(){
    this.setState({
      isEventsPopupOpen : false,
      isShareModalOpen : true
    })
  }

  handleShareModalClose(){
    this.setState({
      isShareModalOpen : false,
      isEventsPopupOpen : true,
    })
  } 

  updateEventsResult = (newValue: number, mapData : IEventData) => {
    this.setState({ eventsLength: newValue, eventsData : mapData });
  };

  updateCalendarType = (calendarType : CalendarViewType) => {
    this.setState({ calendarView : calendarType})
  }

  handleTextFieldChange = (event: any, field: string) => {
    const { value } = event.target;
  
    this.setState((prevState) => {
      const updatedForm:any = {
        ...prevState.eventForm,
      };
  
      if (field === "sub_category") {
        // Find the object from eventTypes
        const selectedItems = value.map((id:any) => 
          this.state.eventTypes.find((item) => item.id === id)
        ).filter(Boolean);
        
        
        console.log("selected item", selectedItems)
        // Ensure sub_category is always an array
        updatedForm[field] = selectedItems
      } else {
        updatedForm[field] = value;
      }

      this.setState({eventForm : updatedForm})
    });
  };
  
  
  

  handleDragOver(event: React.DragEvent) {
    event.preventDefault();
  }

  handleDrop(event: React.DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file) {
        this.setState((prevState) => ({
          eventForm: {
            ...prevState.eventForm,
            eventPoster: file, 
          },
        }));
      }
    }
  }

  handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.setState((prevState) => ({
        eventForm: {
          ...prevState.eventForm,
          event_posters: Array.from(files),
        },
      }));
    }
  }
  

  handleSwitchToggle = (item: { title: string }) => {
    this.setState((prevState: any) => ({
      eventForm: {
        ...prevState.eventForm,
        ages: {
          ...prevState.eventForm.ages,
          [item.title]: !prevState.eventForm.ages[item.title],
        },
      },
    }));
  };

  getFilteredEventsType = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventTypesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEventTypesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getIdentityTags = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentityTagsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentityTagsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEventLocations = () => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventLocationApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEventLocationApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleItemSelect = (item: Community) => {
    if (!this.state.selectedTag.includes(item)) {
      this.setState((prevState: any) => ({
        selectedTag: [...prevState.selectedTag, item],
        searchTag : "",
        eventForm: {
          ...prevState.eventForm,
          identity_tags: [
            ...(prevState.eventForm.identity_tags || []),
            item
          ],
        },
      }));
    }
  };

  handleLocationSelect = (item:Location) => {
    this.setState({
      locationSearchTag: item.attributes.venue_name,
      showLocationTags:false,
      eventForm: {
        ...this.state.eventForm,
        location: [item],
      },
    });
  };
  
  handleLocationChange = (event:any) => {
    const locationSearchTag = event.target.value;
    this.setState({
      locationSearchTag,
      showLocationTags:true
    });
  };

  handleInputFocus = () => {
    this.setState({
      showLocationTags: true,
    });
  };
  
  handleBackspace = (event:any) => {
    if (event.key === "Backspace" && this.state.locationSearchTag === "") {
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          location: [],
        },
      });
    }
  };


  

  handleDelete = (itemToDelete: Community) => {
    this.setState((prevState) => {
      // Filter the selected tags and identity tags
      const updatedSelectedTags = prevState.selectedTag.filter((item) => item !== itemToDelete);
      const updatedIdentityTags = prevState.eventForm.identity_tags.filter((tag) => tag.id !== itemToDelete.id);
  
      return {
        selectedTag: updatedSelectedTags,
        eventForm: {
          ...prevState.eventForm,
          identity_tags: updatedIdentityTags,
        },
      };
    });
  };
  

  handleClose = () =>{
    this.setState({anchorEl : null, anchorEl2 : null, isEventDetailPopUpOpen : false})
  }

  handleCloseEventDialog = ()=>{
    this.setState({isEventDialogOpen : false})
  }

  createEvent = async () => {
    const meta = localStorage.getItem('meta') || "";
    let metaObject = null;

    try {
      metaObject = meta ? JSON.parse(meta) : {};
    } catch (error) {
      metaObject = {};
    }
    const formData = new FormData();
    const event  = this.state.eventForm;
    const startTime = this.combineDateAndTime(event.start_date, event.start_time);
    const endTime = this.combineDateAndTime(event.end_date, event.end_time);
    formData.append("event[event_link]", event.event_link);
    formData.append('event[title]', event.title);
    formData.append('event[start_date]', event.start_date);
    formData.append('event[end_date]', event.end_date);
    if(event.virtual_meeting_link){
      formData.append('event[virtual_meeting_link]', event.virtual_meeting_link);
      formData.append('event[virtual]', 'true');
    }
    formData.append('event[location_id]', event.location[0].attributes.id.toString() );
    formData.append('event[description]', event.description);
    formData.append('event[cost]', event.cost.toString());
    formData.append('event[repeat_event]', this.state.repeatEvent.toString());
    if(this.state.repeatEvent){
      const { repeatEvery, repeatType, repeatOn, endsOption, endDate, occurrences } = this.state;
      formData.append('event[custom_repeat_in_number]', repeatEvery.toString());
      formData.append('event[custom_repeat_every]', repeatType);
      if(repeatType==='week'){
        formData.append('event[repeat_on]', repeatOn[0].value);
      }
      formData.append('event[ends]', endsOption);
      if(endsOption === "on"){
        formData.append('event[particular_date]', endDate);
      }
      if(endsOption === "after"){
        formData.append('event[occurrences]', occurrences.toString());
      }
    }
    
    
    
    event.sub_category.forEach((category)=>{
      formData.append('event[sub_category_ids][]', category.id);
    })
    formData.append('event[start_time]', startTime);
    formData.append('event[end_time]', endTime);
    formData.append('event[age]', event.age);
    formData.append('event[created_by]', 'user');
    formData.append('event[status]', 'pending');
    event.identity_tags.forEach((identity)=>{
      formData.append('event[identity_tag_ids][]', identity.id);
    })
    
    event.event_posters.forEach((item) => {
      formData.append("event[event_posters][]", item)
    })

    formData.append("event[event_organizer]", event.event_organizer)

    const header = {
       token: metaObject.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEventAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEventApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createEventCheck = () =>{
    if(localStorage.getItem("meta")){
      this.setState({isEventDialogOpen : true})
    }else{
      this.setState({isAlertOpen : true})
    }
  }

  handleLogin = ()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleCancelEvent = ()=>{
    this.setState({isAlertOpen : false})
  }

  combineDateAndTime = (date:string, time:string) => {
    const dateString = date.trim();
    const timeString = time.trim();
    const combinedDateTime = `${dateString}T${timeString}:00`;
    return combinedDateTime;
  }

  handleOpenDetailsPopUp = () =>{
    const event  = this.state.eventForm;
    const startTime = this.combineDateAndTime(event.start_date, event.start_time);
    const endTime = this.combineDateAndTime(event.end_date, event.end_time);
    const start = dayjs(startTime);
    const end = dayjs(endTime);
    const currentTime = dayjs(); 
    
    if (start.isBefore(currentTime)) {
      alert("Event start time must be greater than the current time");
      return false;
    }
    
    if(startTime && endTime && end.isBefore(start)){
      alert("Event start time and end time are not valid")
      return false;
    }else{
      this.setState({ isEventDetailPopUpOpen: true })
    }
  }

  isFormValid = () => {
    const event  = this.state.eventForm;
    if(
      !event.title ||
      !event.sub_category || event.sub_category.length === 0 ||
      !event.start_date ||
      !event.end_date || 
      !event.start_time || 
      !event.end_time || 
      !event.location || event.location.length === 0 ||
      !event.event_organizer || 
      !event.description || 
      !event.identity_tags || event.identity_tags.length === 0 || 
      !event.event_posters || event.event_posters.length === 0 || 
      !event.event_link ||
      !event.cost
    ){
      return false
    }
    if (this.state.repeatEvent) {
      const { repeatType, repeatOn, endsOption, endDate } = this.state; 
      if (
        (repeatType === "week" && repeatOn.length === 0) || 
        (endsOption === "on" && !endDate)
      ) {
        return false;
      }
    }
    
    return true
  };

  closeEventCreateModal  =()=>{
    this.setState({isEventDetailPopUpOpen : false, isEventDialogOpen : false, isEventCreated:false})
    this.props.navigation.navigate('LandingPage');
  }
  
  getEventById = (eventId:number) => {
    const header = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventByIdApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsApi+`/${eventId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleDaySelection = (day: any) => {
    this.setState((prevState) => {
      const isSelected = prevState.repeatOn.some((d) => d.value === day.value);
      if (isSelected) {
        return {
          repeatOn: prevState.repeatOn.filter((d) => d.value !== day.value),
        };
      } else {
        return {
          repeatOn: [...prevState.repeatOn, day],
        };
      }
    });
  };
  handleEndDate = (newDate: any) => {
    if(newDate){
    const formattedDate = newDate?.format('YYYY-MM-DD');
  
    this.setState({
      endDate: formattedDate,
    });
    }
  };
  
  
  
  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleSnackbarClose, handleStorageChange } from "./components/utils";

// Customizable Area Start
interface AboutUs {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  title: string;
  description: string;
  our_mission: string;
  team_description: string;
  created_at: string;
  updated_at: string;
  teams: TeamsData;
}

interface TeamsData {
  data: Team[];
}

interface Team {
  id: string;
  type: string;
  attributes: TeamAttributes;
}

interface TeamAttributes {
  name: string;
  pronouns: string;
  position: string;
  identity_tags:{"data":IdentityTag[]}
  member_image: MemberImage[];
}

interface MemberImage {
  id: number;
  url: string;
}

interface IdentityTag {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  events: any
  isMobile:boolean;
  isEventsPopupOpen : boolean;
  isShareModalOpen : boolean;
  currentEvent : any;
  aboutUsData:AboutUs;
  
  isLoggedIn: boolean | null
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleEventPopupClose = this.handleEventPopupClose.bind(this);
    this.handleShareModalOpen = this.handleShareModalOpen.bind(this);
    this.handleShareModalClose = this.handleShareModalClose.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      events:  [],
      isMobile:false,
      isEventsPopupOpen : false,
      isShareModalOpen : false,
      currentEvent : {},
      aboutUsData: {
        id: "",
        type: "",
        attributes: {
          title: "",
          description: "",
          our_mission: "",
          team_description: "",
          created_at: "",
          updated_at: "",
          teams: {
            data: []
          },
        }
      },
    
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' ? true : null ,

    
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );       

      if (apiRequestCallId && responseJson) {
        const colors = ["#D62339", "#F47133", "#BC3081", "#045E81"];
        if (apiRequestCallId === this.getEvnetsDataApiId) {
          this.setState(
            {
              events: responseJson.data.slice(0, 4).map((item: any, index: number) => {
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    categoryClr: colors[index],
                  },
                };
              }),
            },
            () => this.addStyleToElement()
          );
        }
        if(apiRequestCallId === this.getAboutUsAPiId){
          this.setState({aboutUsData : responseJson.data[0]})
        }
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  getEvnetsDataApiId:string = ""
  getAboutUsAPiId:string = ""

  async componentDidMount() {
    this.getEvnetsData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getAboutUsData();

      window.addEventListener('localStorageChange', () => {
        handleStorageChange(this.setState.bind(this));
    });
   }

  async componentWillUnmount() {
    
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener('localStorageChange', () => {
      handleStorageChange(this.setState.bind(this));
  });
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
    
   window.addEventListener("resize", this.handleResize);
  window.addEventListener('localStorageChange', () => {
    handleStorageChange(this.setState.bind(this));
});

}

handleSnckbrClose=()=>{
  handleSnackbarClose(this.setState.bind(this))
  
}

  addStyleToElement=()=>{
    const element = document.getElementsByClassName('react-multi-carousel-list');
    if (element && element.length > 0) {
      element[0].removeAttribute("dir");
      const parDiv = document.getElementsByClassName('event-card-section') as any;
      if (parDiv && parDiv.length > 0) {
        parDiv[0].style.display = "flex";
        parDiv[0].style.justifyContent = "center";
        parDiv[0].style.gap = "20px";
      }
    }
  }
  handleResize=()=> {
      if (window.innerWidth < 900) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false });
      }
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getEvnetsData = () => {
    const header = { "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEvnetsDataApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTodayEventsAPi);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);  
    return true;
  }
  getLocation = (location: any) => {
    let loc = "";
    if(location && location.data){
      loc =  location.data.attributes.venue_name + "," + location.data.attributes.address 
    }
    return loc;
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long', 
        day: '2-digit',  
        month: 'long',   
        year: 'numeric' 
    };
    let updatedDate =new Intl.DateTimeFormat('en-GB', options).format(date).split(' ');
    updatedDate[0]=updatedDate[0]+","
    return updatedDate.join(" ")
  }

  convertTo24HourFormat(timeString: string): string {
    const [time, modifier] = timeString.split(' '); 
    let [hours, minutes] = time.split(':').map(Number); 

    
    if (modifier === 'PM' && hours < 12) hours += 12;  
    if (modifier === 'AM' && hours === 12) hours = 0; 

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  handleEventPopupClose(){
    this.setState({isEventsPopupOpen : false})
  }

  handleShareModalOpen(){
    this.setState({
      isEventsPopupOpen : false,
      isShareModalOpen : true
    })
  }

  handleShareModalClose(){
    this.setState({
      isShareModalOpen : false,
      isEventsPopupOpen : true,
    })
  }

  getAboutUsData = () => {
    const header = { 
      "Content-Type": configJSON.validationApiContentType
     };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutUsAPiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAboutUsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}

import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface PasswordFieldProps {
  value: string; // The current value of the password field
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Change handler for the password field
  isMobile: boolean; // Indicates if the UI is in mobile view
  isPasswordVisible: boolean; // Controls the visibility of the password
  onTogglePasswordVisibility: () => void; // Toggles the password visibility
  style?: React.CSSProperties; // Optional custom styles
  placeholder?: string; // Placeholder text for the input field
  testId?: string; // Test ID for testing purposes
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  value,
  onChange,
  isMobile,
  isPasswordVisible,
  onTogglePasswordVisibility,
  style,
  placeholder = "Enter your password",
  testId = "passwordTextField",
}) => {
  return (
    <TextField
      value={value}
      InputProps={{
        style: style,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onTogglePasswordVisibility}
              edge="end"
            >
              {isPasswordVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      type={isPasswordVisible ? "text" : "password"}
      data-test-id={testId}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default PasswordField;

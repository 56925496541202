import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import React, { Component } from 'react'
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import {cross} from "./assets";
import {
    createTheme
} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

type MyProps = {
}

interface FooterState {
    FooterLinks: {
        facebook: string,
        google: string,
        linkedin: string,
        twitter: string,
        instagram: string
    },
    IsTermsAndConditionsModal: boolean,
    IsPrivacyConditionModal: boolean,
    TermsAndConditionsData: Array<{
        id: string;
        description: Array<string>;
        created_at: string;
    }>;
    PrivacyPolicyData: Array<{
        id: string;
        type: string;
        description: Array<string>;
    }>;
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}



export default class Footer extends Component<MyProps, FooterState> {
    subScribedMessages: string[];
    constructor(props: MyProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.PostDetailDataMessage),
        ];

        this.state = {
            FooterLinks: {
                facebook: "",
                google: "",
                linkedin: "",
                twitter: "",
                instagram: ""
            },
            IsTermsAndConditionsModal: false,
            IsPrivacyConditionModal: false,
            PrivacyPolicyData: [],
            TermsAndConditionsData: [],
        }

        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }

    TermsAndConditionsApiCallId: string = "";
    PrivacyPolicyApiCallId: string = "";

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.PrivacyPolicyAPiResponse(apiRequestCallId, responseJson);
        this.TermsAndConditionsAPiResponse(apiRequestCallId, responseJson);
    }

    TermsAndConditionsAPiResponse = (ApiCallId: string, responseData: {
        data: Array<{
            id: string,
            created_at: string,
            description: string
        }>
    }) => {
        if (ApiCallId === this.TermsAndConditionsApiCallId) {
            if (Array.isArray(responseData.data) && responseData.data.length > 0) {
                const ApiresponseData = responseData.data.map((item) => {
                    return {
                        id: item.id,
                        description: item.description.split(/\r\n/),
                        created_at: item.created_at
                    }
                })
                this.setState({
                    TermsAndConditionsData: ApiresponseData
                })
            }
        }
    }

    PrivacyPolicyAPiResponse = (ApiCallId: string, responseData: {
        data: Array<{
            id: string;
            type: string;
            attributes: {
                id: string;
                description: string;
            }
        }>
    }) => {
        if (ApiCallId === this.PrivacyPolicyApiCallId) {
            if (Array.isArray(responseData.data) && responseData.data.length > 0) {
                const ReponseData = responseData.data.map((item) => {
                    return {
                        id: item.id,
                        type: item.type,
                        description: item.attributes.description.split(/\r\n/)
                    }
                })
                this.setState({
                    PrivacyPolicyData: ReponseData
                })
            }
        }
    }

    async componentDidMount() {
        this.TermsAndConditionsFuncation()
        this.PrivacyPolicyApiCallFuncation()
    }

    TermsAndConditionsFuncation = () => {

        const headers = {
            "Content-Type": "application/json"
        };

        const policyData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.TermsAndConditionsApiCallId = policyData.messageId;

        policyData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_terms_and_conditions/terms_and_conditions"
        );

        policyData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        policyData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(policyData.id, policyData);

    }

    PrivacyPolicyApiCallFuncation = () => {

        const headers = {
            "Content-Type": "application/json"
        };

        const policyData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.PrivacyPolicyApiCallId = policyData.messageId;

        policyData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_terms_and_conditions/privacy_policies"
        );

        policyData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        policyData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(policyData.id, policyData);

    }

    OpenTermsandConditions = () => {
        this.setState({
            IsTermsAndConditionsModal: true
        })
    }

    CloseTermsandConditions = () => {
        this.setState({
            IsTermsAndConditionsModal: false
        })
    }

    OpenPrivacyPolicy = () => {
        this.setState({
            IsPrivacyConditionModal: true
        })
    }

    ClosePrivacyPolicy = () => {
        this.setState({
            IsPrivacyConditionModal: false
        })
    }

    TermsandConditions = () => {
        console.log("this.state.TermsAndConditionsData", this.state.TermsAndConditionsData)
        return (
            <DilogStyle disableRestoreFocus data-test-id="TermsandConditionsDilog" onClose={this.CloseTermsandConditions} aria-labelledby="customized-dialog-title" open={this.state.IsTermsAndConditionsModal} maxWidth="sm">
                <DialogTitle id="customized-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box display="flex" alignItems="center">
                        <Typography className="modalHeadingText" gutterBottom style={{ color: '#D62339' }}>
                            Terms and Conditions
                        </Typography>
                    </Box>
                    <IconButton aria-label="close" onClick={this.CloseTermsandConditions}>
                        <img src={cross} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        {
                            this.state.TermsAndConditionsData?.map((item) => (
                                <div key={item.id} className="modalText">
                                    {item.description.map((line, index) => 
                                        line && <span style={{display:'block'}} key={index}>{line}</span> 
                                    )}
                                </div>
                            ))
                            
                        }
                    </Box>
                </DialogContent>
            </DilogStyle>
        )
    }

    PrivacyPolicy = () => {
        return (
            <DilogStyle  disableRestoreFocus data-test-id="PrivacyPolicyDilog" onClose={this.ClosePrivacyPolicy} aria-labelledby="customized-dialog-title" open={this.state.IsPrivacyConditionModal} maxWidth="sm">
                <DialogTitle id="customized-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box display="flex" alignItems="center">
                        <Typography gutterBottom className={'modalHeadingText'}>
                            Privacy Policy
                        </Typography>
                    </Box>
                    <IconButton aria-label="close" onClick={this.ClosePrivacyPolicy}>
                        <img src={cross} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        {
                           this.state.PrivacyPolicyData?.map((item, index) => {
                            return (
                                <div key={index} className={'modalText'}>
                                    {item.description.map((line: string, lineIndex: number) => (
                                        <p key={lineIndex}>{line}</p>
                                    ))}
                                </div>
                            );
                        })
                        
                        }
                    </Box>
                </DialogContent>
            </DilogStyle>
        )
    }

    render() {
        return (
            <FooterMainBox style={{ marginTop: 'auto' }}>
                {this.PrivacyPolicy()}
                {this.TermsandConditions()}
                <Grid className={'footerSection2'} container>
                    <Box data-test-id="TermsAndConditionsButton" className={'footerLink'} onClick={this.OpenTermsandConditions}>Terms and Conditions</Box>
                    <Box fontSize={"24px"}>|</Box>
                    <Box data-test-id="linkPrivacy" className={'footerLink'} onClick={this.OpenPrivacyPolicy}>Privacy Policy</Box>
                </Grid>
            </FooterMainBox>
        )
    }
}

const DilogStyle = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '10px 10px 40px 10px',
        [theme.breakpoints.down('sm')]: {
            borderRadius : "10px"
        }
      },
    '& .modalHeadingText': {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "32px",
        textAlign: "left",
        color: "#045E81",
        margin: 0
    },
    "& .modalText": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#343434",
        margin: 0,
    },
})

const FooterMainBox = styled(Box)({
    '& .footerSection2': {
        background: "#043C61",
        display: "flex",
        justifyContent: "center",
        height: "57px",
        alignContent: "center",
        color: "white",
        gap: "25px",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            gap  :"16px"
        }
    },
    "& .footerLink": {
        color: 'white', display: 'flex', fontFamily: 'Inter', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', fontSize: '18px', fontWeight: 400,
        cursor : "pointer",
        [theme.breakpoints.down('sm')]: {
        fontSize : "14px"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize : "10px"
            }
    },
    '& .modalHeadingText': {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "32px",
        textAlign: "left",
        color: "#045E81",
        margin: 0
    },
    "& .modalText": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#343434",
        margin: 0,
        textWrap : "wrap"
    },
});

export const locationMarkerDarkBlue = require("../assets/location-marker-dark-blue.png");
export const locationMarkerOrange = require("../assets/location-marker-orange.png");
export const locationMarkerLightGreen = require("../assets/location-marker-light-green.png");
export const locationMarkerPink = require("../assets/location-marker-pink.png");
export const locationPin = require("../assets/locationPin.png");
export const instaIcon = require("../assets/instaIcon.png");
export const locationPinDarkBlue= require("../assets/location-pin-dark-blue.png");
export const locationPinOrange = require("../assets/location-pin-orange.png");
export const locationPinLightGreen = require("../assets/location-pin-light-green.png");
export const locationPinRed = require("../assets/location-pin-red.png");
export const locationPinPink = require("../assets/location-pin-pink.png");
export const timeIcon = require("../assets/timeIcon.png");
export const currentLocationIcon = require("../assets/current-location.png");







import React from 'react';
import { Card, CardContent, CardMedia, Chip, Typography, Box } from '@mui/material';
import { getTagColor } from '../../../../components/src/HelperUtils';

interface Team {
  id: string;
  type: string;
  attributes: TeamAttributes;
}

interface TeamAttributes {
  name: string;
  pronouns: string;
  position: string;
  identity_tags:{"data":IdentityTag[]}
  member_image: MemberImage[];
}

interface IdentityTag {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
  };
}

interface MemberImage {
  id: number;
  url: string;
}



export function TeamMemberCard(props: 
  Team
) {

  return (
    <Card sx={{ maxWidth: "100%", height: '100%', borderRadius: "10px" }}>
      <CardMedia
        component="img"
        height="300px"
        image={props.attributes?.member_image[0]?.url || ''}
        alt={props.attributes.name}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent style={{position :"relative"}}>
        <Box sx={{ display: 'flex', gap : 1, alignItems: 'center', mb: 1}}>
          <Typography variant="h6" component="div">
            {props.attributes.name}
          </Typography>
          <Typography color="text.secondary">
          {props.attributes.pronouns}
        </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
          {props.attributes.identity_tags.data.map((tag) => (
            <Chip
              key={tag.attributes.name}
              label={tag.attributes.name}
              size="small"
              variant="outlined"
              sx={{
                color: getTagColor(tag.attributes.name),
                borderColor: getTagColor(tag.attributes.name),
                '&:hover': {
                  bgcolor: getTagColor(tag.attributes.name),
                  color: 'white',
                },
              }}
            />
          ))}
        </Box>

        <Chip
            label={props.attributes.position}
            variant="outlined"
            sx={{
              position : "absolute",
              top : 0,
              right : "14px",
              transform: "translateY(-50%)",
              bgcolor: getTagColor(props.attributes.position),
              color : "white",
              fontWeight: 'bold',
              border : "1px slid white"
            }}
          />
      </CardContent>
    </Card>
  );
}


import React from "react";
// Customizable Area Start
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Select,
  FormControl,
  ListItemText,
  Checkbox,
  MenuItem,
  TextField,
  InputAdornment,
  Backdrop,
  Card,
  Typography,
} from "@mui/material";
import {
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End
import AdvancedSearchController, { Props } from "./AdvancedSearchController.web";
import { checkbox, checkboxChecked,filterMobile ,arrow,imageTune,search} from "../../advancedsearch/src/assets";

export default class AdvancedSearches extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderEventTypeDropdownIcon = () => {
    return (
      this.state.expandless ? (
        <ExpandLessIcon data-test-id="expandless" onClick={() => this.lessShow()} style={{ color: '#888888', fontSize: '24px' }} />
      ) : (
        <ExpandMoreIcon data-test-id="expandmore" onClick={() => this.moreShow()} style={{ color: '#888888', fontSize: '24px' }} />
      )
    )
  }

  renderCommunitiesTypeDeopdownIcon = () => {
    return (
      this.state.expandlessMore ? (
        <ExpandLessIcon onClick={() => this.lessShowHandle()} style={{ color: '#888888', fontSize: '24px' }} />
      ) : (
        <ExpandMoreIcon onClick={() => this.moreShowHandle()} style={{ color: '#888888', fontSize: '24px' }} />
      )
    )
  }

  renderDropdownStyle = () => {
    const styleObj = {
      top: this.state.anchorEl &&
        typeof this.state.anchorEl.getBoundingClientRect === "function"
        ? this.state.anchorEl.getBoundingClientRect().bottom + 10
        : "0px",

      zIndex: 1000,
      width: "650px",
      maxHeight: "300px",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 8px 16px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",

      padding: "12px 10px",
    }

    return styleObj
  }

  renderResponsiveCSS = () => {
    const style = {
        padding: this.state.hideForMobile?"20px 30px":"20px 0px",
        justifyContent:'center',
        background : this.state.hideForMobile ? "#043C61" : "none",
        borderBottomRightRadius : this.state.hideForMobile ? "25px" : "0px",
        borderBottomLeftRadius : this.state.hideForMobile ? "25px" : "0px",
        marginTop : "0px"
    }

    return style
  }

  render() {
    // Customizable Area Start
    const { selectedCommunities,   selectedEvents } = this.state;
    const dropdownStyle = this.renderDropdownStyle()
    const eventsData = this.state.eventsData.data || [];

    const communityOptionsSearch = eventsData?.map((event: any) => {
      const subCategories = event.attributes.sub_categories.data?.map((subCategory: any) => subCategory.attributes.name.trim());
      const identityTags = event.attributes.identity_tags.data?.map((identityTag: any) => identityTag.attributes.name.trim());

      return subCategories?.map((subCategory: any, index: any) => {
        const identityTag = identityTags[index] || ""; // Handle cases where identityTags may have fewer entries
        return `${subCategory} , ${identityTag}`;
      });
    })?.flat();

    const filteredCommunityOptions = communityOptionsSearch.filter((option: string) =>
      option.toLowerCase().includes(this.state.selectedValue.toLowerCase())
    ) || [];

    const placeHolderStyle = {fontFamily: 'Inter', fontSize: '18px', fontWeight: '400' }
   
    return (
      <StyledEngineProvider injectFirst>
           <Box display="flex" alignItems="center" gap={2} p={2} style={this.renderResponsiveCSS()} onBlur={this.handleBlur} >
           
          <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.eventOpen} >
            {
              this.state.eventOpen && (
                <Card
                  style={{
                    width: '85%', height: '83%', display: 'flex', flexDirection: 'column',
                    borderRadius: '8px', padding: '22px 0px 24px 0px',
                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                  }}>
                  <IconButton
                    size="small"
                    style={{
                      alignSelf: 'flex-end',
                      marginRight: '16px',
                    }}
                    data-test-id="iconbutton"
                    onClick={() => {
                      this.setState({ eventOpen: false })
                    }}
                  >
                    <CloseIcon style={{ height: "24px", width: "24px", color: '#334155' }} />
                  </IconButton>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <img
                    style={{marginTop:'3px'}}
                      src={imageTune.default}
                      height={23.25}
                      width={24}
                      alt="checkbox"
                      />
                    <Box style={webStyle.eventsFound}>Filters.</Box>
                  </div>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      color: '#343434',
                      width: '89%',
                      borderBottom: '1px solid #DADADA',
                      paddingBottom: '15px',
                    }}
                  >
                    <span>Event Type</span>
                    
                    {this.renderEventTypeDropdownIcon()}

                  </Box>
                  {this.state.expandless &&
                    <div
                      style={{
                        backgroundColor: '#FAF7F7',
                        height: '200px',
                        width: '89%',
                        marginTop: '-15px',
                        overflowY: 'auto',
                        overflowX:'hidden'
                      }}
                    >
                      {this.state.eventTypesOption.map(optionVal => (
                        <div key={optionVal.id} style={{ display: 'flex', flexDirection: 'column' }}>
                          <MenuItem
                            value={optionVal.name}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                checkedIcon={
                                  <img
                                    src={checkboxChecked}
                                    height={20}
                                    width={20}
                                    alt="checkbox-checked"
                                  />
                                }
                                icon={
                                  <img
                                    src={checkbox}
                                    height={20}
                                    width={20}
                                    alt="checkbox"
                                  />
                                }
                                data-test-id="handleevent"
                                onClick={(event) => this.handleEventSelect(event, optionVal)}
                                checked={selectedEvents?.includes(optionVal?.id)}

                              />
                              <ListItemText primary={<Typography style={{ fontFamily: 'Inter', textTransform : "capitalize" }}>{optionVal.name}</Typography>} />
                            </div>
                          </MenuItem>
                        </div>
                      ))}
                    </div>
                  }
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      color: '#343434',
                      width: '89%',
                      borderBottom: '1px solid #DADADA',
                      paddingBottom: '15px',
                    }}
                  >
                    <span>Communitites</span>
                    {this.renderCommunitiesTypeDeopdownIcon()}           
                  </Box>
                  {this.state.expandlessMore &&
                    <div style={{backgroundColor:'#FAF7F7',height:'200px',width:'89%',marginTop:'-15px',overflowY:'auto', overflowX:'hidden'}}>
                      {this.state.filterOptions?.communities?.map((option: any) => (
                        <MenuItem
                          key={option?.id}
                          style={{ fontFamily: 'Inter' }}
                          value={option?.name}
                          data-test-id="menuitem"   
                          onClick={() => this.handleCommunitySelect(option)}
                        >
                          <Checkbox
                            checkedIcon={
                              <img
                        src={checkboxChecked}
                        height={20}
                        width={20}
                        alt="checkbox-checked"
                      />
                    }
                    icon={
                      <img 
                      src={checkbox}
                       height={20} width={20} alt="checkbox" />
                    }
              
              checked={selectedCommunities?.includes(option?.name)}
            />
            <ListItemText
              
              primary={
                <Typography style={{ fontFamily: 'Inter' }}>{option?.name}</Typography>
              }
            />
          </MenuItem>
        ))}
                    </div>}
                  <Box style={{backgroundColor:'#D62339',width:'286px',
                  display:'flex',justifyContent:'center',alignItems:'center',marginTop: 'auto',
                    height:'50px',borderRadius:'100px',color:'white',fontWeight:400,fontSize:'18px',fontFamily:'Inter'}}
                    onClick={this.handleSearchQueries} >Apply</Box>
                </Card>
              )
            }
          </Backdrop>
          {!this.state.hideForMobile &&
          <>
          <FormControl variant="outlined" sx={webStyle.formControl}>
            <Select
              multiple
              value={this.state.selectedEventOption}
              displayEmpty
              onChange={this.handleEventChange}
              data-test-id="eventTypeOnchange"
              MenuProps={{
                PaperProps: {
                  style: webStyle.dropdownPaper,
                  sx: {
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#043C61",
                      borderRadius: "100px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#043C61",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#E7E6E6",
                    },
                  },
                },
              }}
              style={webStyle.dropdownContainer}
              renderValue={(selected) => {
                if (this.state.selectedEvents.length > 0) {
                  const optionLabel = this.state.eventTypesOption.filter(item => selected.includes(item.id)).map(item => item.name.trim());
                  return optionLabel.join(", ");
                }
                return <span style={placeHolderStyle}>Event Type</span>;
              }}
            >
              {this.state.eventTypesOption.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checkedIcon={
                      <img
                       src={checkboxChecked}
                        height={20}
                        width={20}
                        alt="checkbox-checked"
                      />
                    }
                    icon={
                      <img 
                      src={checkbox}
                       height={20} width={20} alt="checkbox" />
                    }
                    checked={this.state.selectedEvents.includes(option.id)}
                  />
                  <ListItemText  primary={<Typography style={{ fontFamily: 'Inter', textTransform : "capitalize" }}>{option.name}</Typography>}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={webStyle.formControl}>
            <Select
              multiple
              value={selectedCommunities}
              displayEmpty
              onChange={this.handleCommunityChange}
              data-test-id="communityTypeOnchange"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#043C61",
                      borderRadius: "100px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#E7E6E6",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#043C61",
                    },
                  },
                  style: webStyle.dropdownPaper,
                },
              }}
              style={webStyle.dropdownContainer}
              renderValue={(selected) => {
                if (selectedCommunities.length > 0) {
                  return selected.join(", ");
                }
                return <span style={placeHolderStyle}>Communities</span>;
              }}
            >
              
              {this.state.filterOptions?.communities?.map((option: any) => (
                        <MenuItem
                          key={option?.id}   
                          data-test-id="menuitem2"   
                          onClick={() => this.handleCommunitySelect(option)}
                          style={{ fontFamily: 'Inter' }}
                          value={option?.name}
                        >
                          <Checkbox
                            checkedIcon={
                              <img
                      
                        alt="checkbox"
                        src={checkboxChecked}
                        height={20}
                        width={20}
                      />
                    }
                    icon={
                      <img 
                      src={checkbox}
                        alt="checkboxes" 
                        height={20} width={20}/>
                    }   
              checked={selectedCommunities?.includes(option?.name)}
            />
            <ListItemText primary={
                <Typography style={{ fontFamily: 'Inter' }}>{option?.name}</Typography>
              }
            />
          </MenuItem>
        ))}
            </Select>
          </FormControl>
          </>
          }
          <div style={{...webStyle.searchContainer,zIndex: this.state.eventOpen ?  -1:9999 ,backgroundColor:'white'}}>
            <TextField
             sx={{
              border: "none",
              "& .MuiInputBase-input": {
                color: "#888888", 
                fontFamily: "Inter", 
              },
              "& .MuiInputBase-input::placeholder": {
                fontFamily: "Inter", 
                fontStyle: "italic", 
              },
             
            }}
            data-test-id="openDiv"
            onClick={(event) => this.handleOpen(event)}
              style={{maxHeight: "50px", borderRadius:"100px", border:"none",fontSize:'18px',position:'absolute',zIndex:99999}}
              variant="standard"
              placeholder="Search"
              value={this.state.selectedValue}
              onChange={this.handleSearchChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment style={{height: "38px",  boxShadow: "0px 4px 6px #0000001A", width: "38px", borderRadius:"50%", background: "#F47133",color:"white",display:"flex", justifyContent:"center"}} position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {this.state.openSearchBox && this.state.selectedValue.length > 0  &&  filteredCommunityOptions.length > 0 &&
            <div style={{...dropdownStyle,position:'absolute',zIndex:100}}>
              <div>
                {filteredCommunityOptions.map((option: string) => (
                  <MenuItem
                    key={option}
                    style={{
                      display: "flex",
                      fontFamily:'Inter',
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: this.state.selectedValue === option ? "rgba(25, 118, 210, 0.08)" : "",
                      padding: "8px 12px",
                    }}
                  >
                    <div  
                    data-test-id="searchBox"
                    onClick={() => this.handleSelect(option)} style={{ display: "flex", alignItems: "center", gap: "30px", flex: "1" }}>
                      <img 
                        src={search.default}
                        height={20}
                        width={20}
                        alt="searchBox"
                        style={{ cursor: "pointer" }}
                      />
                      <span
                        style={{ cursor: "pointer", flex: "1" }}
                      >
                        {option}
                      </span>
                    </div>
                    <IconButton
                      size="small"
                      data-test-id="iconbutton"
                      onClick={() => {
                       this.state.selectedValue===option && this.setState({ selectedValue: "" });
                        this.handleClose();
                      }}
                    >
                      <CloseIcon style={{ height: "24px", width: "24px" }} />
                    </IconButton>
                  </MenuItem>
                ))}
              </div>
            </div>}
            {!this.state.hideForMobile ?
            <Button
              style={{
                width: "206px",
                height: "38px",
                borderRadius: "100px",
                background: "#F47133",
                color: "white",
                marginLeft: "auto",
                fontWeight:400,
                fontSize:'18px',
                textTransform: "none",
              }}
              onClick={this.handleSearchQueries}
              data-test-id="searchBtn"
            >
              Search
            </Button> : 
            <>
             <img 
             src={arrow.default}
             height={23.25}
             width={24}
             alt="arrow"
             style={{ 
               position:'relative',
               left:'-15px',          
               marginLeft: "auto"}}/>
             <img 
             onClick={this.handleOpenEvents}
                        src={filterMobile.default}
                        height={23.25}
                        width={24}
                        alt="search"
                        style={{ cursor: "pointer" ,  
                          zIndex:999999,
                          position:'relative',
                          left:'-15px',          
                          }}
                      />
                      </>
                      
  }
            </div>
        

            </Box>  
           
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: { [key: string]: React.CSSProperties } = {
  eventsFound: {
    background:
      "linear-gradient(270deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #D62339 83%, #BC3081 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "600",
    margin: "0",
  },
  searchContainer: {
    height: "38px",
    border: "1px solid #888888",
    borderRadius: "100px",
    maxHeight: "50px",
    padding: "6px 6px 6px 13px",
    display: "flex",
    alignItems: "center",
    width: "667px",
  },
  sortBy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#F47133",
    paddingLeft: "5px",
  },
  shareEvent: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "25px",
    paddingRight: "12px",
    height: "48px",
    borderRadius: "100px",
    margin: "0",
    background: "#D62339",
    color: "white",
    textTransform: "none",
  },

 
  eventLine: {
    margin: "5px 7px 5px 0px",
    borderRadius: "100px",
  },
  eventTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "14px",
    fontStyle: "italic",
    color: "#343434",
  },

  dropdownContainer: {
    height: "50px",
    borderRadius: "100px",
    color: "#888888",
    border: "1px solid #D9D9D9",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    backgroundColor: "#FFF",
    textTransform : "capitalize",
  },
  formControl: {
    width: 310,
  },
  dropdownPaper: {
    marginTop:20,
    zIndex:1000,
    maxHeight: 500,
    borderRadius: "5px",
    overflowY: "auto",
  },
};
// Customizable Area End

import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import {Box,Drawer, Typography,Divider} from '@mui/material';
import {logo, insta,profile,dropdown,close,flash,profile_white,profile_blue,calender,aboutUs,topArrow,hamburger} from "../../blocks/landingpage/src/assets"

const TopNav = () => {
  const [isOpen,setIsOpen]=useState(false);
  const [isSignupToggle,setIsSignupToggle]=useState(false);
  const [isMobile,setIsMobile]=useState(false);
  const [userName,setUserName]=useState("");

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth < 900) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('data');
    let answeer=JSON.parse(data) 
    if (answeer) {
      setUserName(answeer?.attributes?.full_name);
    }else{
      setUserName("");
    }
  });


  
  const navigate = useNavigate();
  const location = useLocation();
  const NaviGatePage = (pathName) =>{
    setIsOpen(false);
    navigate(pathName)
  }
  const currentLocation = location.pathname;
  const currentPath = window.location.pathname;
  const navStyle = {fontFamily: "Inter",textTransform: "unset", fontSize: "18px", fontWeight:currentLocation == "/"?"400": "700", lineHeight: "10px", textAlign: "left", padding: "0", textWrap: "nowrap"}
  const navStyleAboutUs = {fontFamily: "Inter",textTransform: "unset", fontSize: "18px", fontWeight: "400", lineHeight: "10px", textAlign: "left", padding: "0", textWrap: "nowrap",marginLeft:"62px"}
  const navBackground =  currentLocation == "/"? "" : "#043C61";
  const loginStyle = {fontFamily: "Inter", textTransform: "unset", fontSize: "18px", fontWeight: currentLocation == "/" ? "400" : "700", lineHeight: "10px", textAlign: "left", padding: "0", textWrap: "nowrap", width: "117px",height: "33px",borderRadius: "100px", backgroundColor: "#FAE4AA", color: "#043C61"}
  const drawerSignupBtnStyle = {fontFamily: "Inter", textTransform: "unset", fontSize: "18px", fontWeight: currentLocation == "/" ? "400" : "700", textAlign: "left", padding: "0", textWrap: "nowrap", width: "128px", height: "46px", borderRadius: "100px",color: "#fff" }
  const drawerLoginBtnStyle = {fontFamily: "Inter", textTransform: "unset", fontSize: "18px", fontWeight: currentLocation == "/" ? "400" : "700", textAlign: "left", padding: "0", textWrap: "nowrap", width: "128px", height: "46px",borderRadius: "100px",backgroundColor: "#fff", color: "#043C61"  }
  return (
    <>
      {isMobile ?
        <Box sx={{
          bgcolor: "#043C61",
          overflow: "hidden",
          minHeight: "100px",
          boxSizing: "border-box",
        }} className="navContainer" bgcolor={"#043C61"}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" >
            <Button sx={{zIndex:1}} onClick={()=>setIsOpen(true)}><img src={hamburger} /></Button>
              <Button sx={{zIndex:1, padding:0, margin : 0}} 
              onClick={
                () => {
                  NaviGatePage("/")
                }
              }
              disableElevation disableFocusRipple disableRipple disableTouchRipple>
                <img src={logo} alt="logo" />
              </Button>
          </Box>
          <Drawer open={isOpen} onClose={()=>setIsOpen(false)} sx={{zIndex:999}}>
            <Box sx={{ backgroundColor: "#043C61", width: "336px", height: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "25px" }}>
                <Button onClick={
                  () => {
                    NaviGatePage("/")
                  }
                } 
                disableElevation disableFocusRipple disableRipple disableTouchRipple>
                  <img src={logo} alt="logo" />
                </Button>
                <Box display="flex" onClick={()=>setIsOpen(false)}>
                  <Typography sx={{ fontFamily: "Inter", fontSize: "18px", fontWeight: 400, color: "#fff", marginRight: "10px" }}>Close</Typography>
                  <img src={close} alt="close" style={{ width: "32px", height: "32px" }} />
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
                <img src={flash} style={{ position: "absolute", height: "751px", width: "420px", left: "-128px" }} />
                <Box sx={{ display: "flex", alignItems: "left", flexDirection: "column", gap: "15px", marginTop: "60px" }}>

                  <Box sx={{ gap: "10px", padding: "0 20px", margin: "10px 0" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }} >
                      <img src={calender} />
                      <Button variant="text" sx={{ color: "#fff", fontSize: "18px", fontWeight: 400, textDecoration: "none" ,textTransform:"capitalize"}} 
                      onClick={
                        ()=>{
                          NaviGatePage("/events")
                        }
                      }
                      >Events</Button>
                    </Box>
                    <Divider sx={{ border: "0.5px solid #3C8D9E", marginTop: "8px" }} />
                  </Box>

                  <Box sx={{ gap: "10px", padding: "0 20px", margin: "10px 0" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }} 
                    onClick={
                      () => {
                        NaviGatePage("/aboutUs")
                      }
                    }>
                      <img src={aboutUs} />
                      <Button variant="text" sx={{ color: "#fff", fontSize: "18px", fontWeight: 400, textDecoration: "none" ,textTransform:"capitalize"}}>About Us</Button>
                    </Box>
                    <Divider sx={{ border: "0.5px solid #3C8D9E", marginTop: "8px" }} />
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", padding: "0 20px", margin: "10px 0" }}>
                      <Typography sx={{ color: "#fff", fontSize: "18px", fontWeight: 400 }}>Follow us</Typography>
                     <span style={{ width: "24px", backgroundColor: "#fff", borderRadius: "100%", display: "block", textAlign: "center", position: "relative", left: "-4px" }}><img src={insta} width="100%" /></span>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", marginTop: "40px" }}>
                    <Button style={drawerLoginBtnStyle} className="navLink" color="inherit" variant="contained"
                      onClick={
                        () => {
                          if (userName) {
                            NaviGatePage("/")
                            setIsOpen(false)
                            setIsSignupToggle(false)
                            localStorage.removeItem('data')
                            localStorage.removeItem('meta')
                          }else{
                            NaviGatePage("/EmailAccountLoginBlock")
                          }
                        }
                      }
                    startIcon={<span style={{ width: "30px", backgroundColor: "#043C61", borderRadius: "100%", display: "block", textAlign: "center", position: "relative" }}><img src={profile_white} width="100%" /></span>}> {userName ?  "Logout" : "Login"}</Button>
                    <Button style={drawerSignupBtnStyle} className="navLink" color="inherit" variant="outlined"
                      onClick={
                        () => {
                          NaviGatePage("/EmailAccountRegistration")
                        }
                      }
                    startIcon={<span style={{ width: "30px", backgroundColor: "#fff", borderRadius: "100%", display: "block", textAlign: "center", position: "relative" }}><img src={profile_blue} width="100%" /></span>}>Sign Up</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
        :
        <Box style={{ color: "white", display: 'flex', justifyContent: "flex-end", zIndex: '1', height: '105px',  background: currentPath === "/LandingPage" ? "none" : navBackground, }} className="navContainer">
          <Button style={{ display: 'flex', flexDirection: 'row', width:"55%",justifyContent:"flex-end", paddingLeft:"50px"}} className="section1" 
            onClick={
              () => {
                NaviGatePage("/")
              }
            }
          disableElevation disableFocusRipple disableRipple disableTouchRipple>
            <img src={logo} alt="logo" />
          </Button>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", width:"50%" ,justifyContent:"flex-end", paddingRight:"100px"}} className="section2">
            <Button style={navStyle} className="navLink" color="inherit" onClick={
              ()=>{
                NaviGatePage("/events")
              }
            }>Events</Button>
            <Button style={navStyleAboutUs} className="" color="inherit" 
            onClick={
              ()=>{
                NaviGatePage("/aboutUs")
              }
            }>About Us</Button>
            <div style={{position:"relative" ,marginLeft:"40px" }}>
              
              <Button style={loginStyle} className="navLink" color="inherit" variant="contained"
                onClick={
                  ()=>{
                    !userName ? NaviGatePage("/EmailAccountLoginBlock") : null
                  }
                }
              >
                <span style={{ width: "26px", height:"26px" ,backgroundColor: "#043C61", borderRadius: "100%", display: "block", textAlign: "center", position: "relative", right: "16px" ,display: "flex",alignItems: "center",justifyContent: "center"}}>
                  <img src={profile} width="16px" height="16px" />
                </span>
                <Typography style={{fontFamily:"Inter", fontSize:"16px", fontWeight:500, position: "relative", width: "50px" ,textOverflow: "ellipsis", left: "-10px" ,overflow: "hidden" ,whiteSpace: "nowrap"}}>{!userName ? "Log In" : userName}</Typography>
              </Button>
                <img src={dropdown} style={{
                  zIndex: 999, position: "absolute", right: "4px",
                  top: "9px",
                  padding: "7px",
                  cursor: "pointer"
                }}
                  onClick={(e) => {
                    e.persist()
                    setIsSignupToggle(!isSignupToggle)
                  }}
                />
                
                {isSignupToggle &&
                <Button onClick={()=>{
                  if (isSignupToggle && userName) {
                    setIsOpen(false)
                    setIsSignupToggle(false)
                    localStorage.removeItem('data')
                    localStorage.removeItem('meta')
                    localStorage.setItem('isLoggedIn', 'false');
                    localStorage.setItem('showLogoutSnackbar', 'true');


                    // Dispatch custom event
                    window.dispatchEvent(new Event('localStorageChange'));
                    
                  }
                  
                  if(!userName){
                    setIsOpen(false)
                    setIsSignupToggle(false)
                    navigate("/EmailAccountLoginBlock")
                  }
                }}  style={{
                  width: "125px", borderRadius: "5px", height: "45px", background: " #fff",
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around", margin: "10px", position: "absolute",
                  top: "40px",
                  left:"-20px",
                  zIndex:999,
                }}>
                  <img src={topArrow.default} alt="icon" style={{position:"absolute",top:"-8px",right:"11px"}}/>
                  <div>
                    <span style={{ width: "20px",height:"20px", backgroundColor: "#fff", borderRadius: "100%", display: "block", textAlign: "center", position: "relative", backgroundColor:"#E2E8F0",display: "flex",alignItems: "center",justifyContent: "center" }}>
                      <img src={profile_blue} width="14px" height="14px"/>
                    </span>
                  </div>
                  <div>
                    <Typography style={{fontFamily:"Inter", fontSize:"16px", fontWeight:500, color:"#043C61", fontSize:"16px" , textTransform:"capitalize" ,left: "-5px", position: "relative"}}>{!userName ? "Sign Up" : "Logout"}</Typography>
                  </div>
                </Button>}
              </div>
            <div>
              <a
                href="https://www.instagram.com/sparklgbtq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "31px",
                    width: "31px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    marginLeft: "20px"
                  }}
                  className="instaIcon"
                  src={insta}
                  alt="Instagram icon"
                />
              </a>
            </div>
          </div>
        </Box>
      }    
    </>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default TopNav;

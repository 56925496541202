import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material'
import { eventCreation } from "../assets";

interface EventSuccessModalProps {
  open: boolean
  onClose: () => void
}

export default function EventSuccessModal({ open, onClose }: EventSuccessModalProps) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxWidth: '540px',
          p: 2
        }
      }}
    >
      <DialogContent sx={{ textAlign: 'center', px: 3, py: 4, width : "auto" }}>
      <img src={eventCreation} height="auto" width="130px" />
        <h2 style={{ 
          fontSize: '25px',
          fontFamily : "Inter",
          fontWeight: 700,
          marginBottom: '12px',
          color: '#343434'
        }}>
          Your Event is made!
        </h2>
        
        <p style={{
          fontSize: '18px',
          fontWeight : 400,
          fontFamily  :"Inter",
          color: '#343434',
          marginBottom: '24px',
          lineHeight: 1.5
        }}>
          Congratulations! Your event has been successfully created. We will send you a confirmation email with the details to your email address after our review.
        </p>
        <Button
  fullWidth
  variant="contained"
  onClick={onClose}
  sx={{
    width :"448px",
    backgroundColor: '#E41E31',
    color: 'white',
    textTransform: 'none',
    py: 1.5,
    borderRadius: '100px',
    '&:hover': {
      backgroundColor: '#D62339',
    },
    boxShadow: `
      -12px -12px 24px 0px #B30117 inset, 
      6px 6px 24px 0px #DB001B inset, 
      0px 6px 15px 0px #CE041D33
    `,
  }}
>
  Back to Home
</Button>

      </DialogContent>
    </Dialog>
  )
}


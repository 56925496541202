"use client"

import * as React from "react"
import { 
  Dialog, 
  DialogContent, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
  Grid, 
  TextField,
  styled,
  createTheme
} from "@mui/material"
import { 
  Close as CloseIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Message as MessageIcon,
  Mail as MailIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from "@mui/icons-material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {formatDate } from "./HelperUtils";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { mailIcon } from "./assets";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CircleIcon from '@mui/icons-material/Circle';
// import mailIcon from './mail.svg';
// import otherIcon from './others.svg'

import { useMediaQuery } from '@mui/material';

interface ShareEventModalProps {
  isOpen: boolean
  onClose: () => void
  event: any
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  opacity: 0.2,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    opacity: 0.3,
  },
}))

const ShareButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  minWidth: 'auto',
}))

function getBaseUrl() {
  const protocol = location.protocol;
  const host = location.host;
  return `${protocol}//${host}`;
}

export const ShareEventModal = React.memo(({ isOpen, onClose, event }: ShareEventModalProps) => {
  const baseUrl = getBaseUrl();
  const eventUrl = `${baseUrl}/events/${event.id}`.replace(/^"|"$/g, '')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const shareButtons = [
    {
      name: "Facebook",
      icon: FacebookIcon,
      image : require("./facebook.png"),
      color: "#1877F2",
      bgColor : "#E2E8F0",
      onClick: () =>
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(eventUrl)}`,
          "_blank",
          "noopener"
        ),
    },
    {
      name: "Instagram",
      icon: InstagramIcon,
      image : require("./instagram.png"),
      color: "#E4405F",
      bgColor : "#F6E1EA",
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(eventUrl);
          alert(
            "Event link copied! Open Instagram and paste the link in your post or story."
          );
          window.open("https://www.instagram.com", "_blank", "noopener");
        } catch (error) {
          console.error("Clipboard copy failed:", error);
          alert("Failed to copy the link to clipboard.");
        }
      },
    },
    {
      name: "iMessage",
      icon: MessageIcon,
      image : require("./message.png"),
      color: "#34C759",
      bgColor : "#A4E3A2",
      onClick: () =>
        window.open(
          `sms:&body=Check out this event: ${encodeURIComponent(eventUrl)}`,
          "_blank",
          "noopener"
        ),
    },
    {
      name: "Email",
      icon: MailIcon,
      image :mailIcon,
      color: "#FF9500",
      bgColor : "#FCE8CE",
      onClick: () => {
        const subject = "Check out this event";
        const body = `Check out this event: ${encodeURIComponent(eventUrl)}`;
        window.open(
          `mailto:?subject=${encodeURIComponent(subject)}&body=${body}`,
          "_blank",
          "noopener"
        );
      },
    },
    {
      name:"Linkdin",
      icon: LinkedInIcon,
      image : require("./LinkedIn.png"),
      color: "#0077B5",
      bgColor : "#0273AF",
      onClick:
        () => window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            eventUrl
          )}`,
          "_blank",
          "noopener"
        ),
    },
    {
      name:"Twitter",
      icon: TwitterIcon,
      image : require("./Twitter.png"),
      color: "#25D366",
      bgColor : "#1DA1F2",
      onClick:
        () => window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            eventUrl
          )}&text=${encodeURIComponent("Check out this amazing event!")}`,
          "_blank",
          "noopener"
        ),
    },
    {
      name:"Dropbox",
      icon: "https://static-00.iconduck.com/assets.00/dropbox-icon-256x256-uull3744.png",
      image : require("./Dropbox.png"),
      color: "#007EE5",
      bgColor : "#E2E8F0",
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(eventUrl);
          alert(
            "Event link copied! Open Dropbox and upload a file or note with the link."
          );
          window.open("https://www.dropbox.com/", "_blank", "noopener");
        } catch (error) {
          console.error("Clipboard copy failed:", error);
          alert("Failed to copy the link to clipboard.");
        }
      },
    },
    {
      name: "Slack",
      icon: "https://media.lordicon.com/icons/wired/flat/2648-logo-circle-slack.gif",
      image : require("./slack.png"),
      color: "#2EB67D",
      bgColor : "#FFDCE7",
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(eventUrl);
          alert("Event link copied! Open Slack and share the link.");
          window.open("https://slack.com", "_blank", "noopener");
        } catch (error) {
          console.error("Clipboard copy failed:", error);
          alert("Failed to copy the link to clipboard.");
        }
      }
    }
  ]

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '20px',
        overflow: 'hidden',
        background: "#FFFFFF",
        "& .eventName":{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            marginBottom  :"16px",
            marginTop : "16px",
            [theme.breakpoints.down('sm')]: {
              textAlign : "center"
            },
        },
        "& .eventDetails":{
            fontFamily: 'Inter',
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: '15px',
            display  :"flex",
            alignItems :"center",
            gap : "5px",
            [theme.breakpoints.down('sm')]: {
              flexDirection : "column",
              justifyContent : "center"
            },
        },
        "& .eventDetailsItem" : {
            display : "flex",
            justifyContent : "center",
            alignItems : "center",
            wordBreak: "break-word",
            overflowWrap: "break-word",
            [theme.breakpoints.down('sm')]: {
              maxWidth : "320px",
            },

        },
        "& .eventIcons" : {
            fontSize : "15px", 
            marginRight : "5px"
        },
        "& .shareEventTxt" : {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            marginBottom : "24px"
        },
        "& .copyBtn" : {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '10px',
            textTransform : "none",
            backgroundColor: '#3C8D9E',
            color : "white", 
            minWidth : "163px", 
            height : "56px", 
            borderRadius  :"100px",
            alignItems: "center",
            gap : "10px",
            boxShadow: '-12px -12px 24px 0px #055372 inset, 6px 6px 24px 0px #045E8180 inset, 6px 6px 12px 0px #045E8133',
            [theme.breakpoints.down('sm')]: {
              marginTop : "20px"
            },
        }
    },
    '& .dialogContent': {
      borderRadius : "20px", 
      maxWidth : "516px"
    },
    '& .headerBox': {
      display: 'flex', 
      alignItems: 'center', 
      gap: 2,
      [theme.breakpoints.down('sm')]: {
        flexDirection : "column"
      },
    }
  }))

  const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '14px',
    right: '19px',
    color: '#fff',
    height : "28px",
    width : "28px",
    borderRadius : "50%",
    border : "3px solid #E2E8F0",
    cursor : "pointer"
  });

  return (
    <BootstrapDialog open={isOpen} onClose={onClose} >
      <DialogContent className="dialogContent" sx={{ padding: "10px"}}>
        <Box sx={{ bgcolor: '#D62339', p: "10px", position: 'relative', borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}>
            <CloseButton onClick={onClose}>
              <CloseRoundedIcon style={{fontSize : "16px"}} />
            </CloseButton>
          <Box className="headerBox">
            <Box
              component="img"
              src={event?.event_posters && event?.event_posters[0]?.url}
              alt="Event"
              sx={{ width: 123, height: 123, marginRight : "12px", borderRadius: '50%', objectFit: 'cover', border : "5px solid white" }}
            />
            <Box sx={{ color: 'common.white' }}>
              <Typography className="eventName">
                {event.title}
              </Typography>
              <Typography className="eventDetails">
                <div className="eventDetailsItem">
                <CalendarMonthIcon className="eventIcons" /> 
                {event.start_date && formatDate(event.start_date)} 
                {!isMobile &&
                <span>
                  <CircleIcon style={{fontSize : "6px", marginLeft : "5px"}} />
                  </span> 
                }
                </div> 
                <div className="eventDetailsItem">
                <AccessTimeIcon className="eventIcons" />
                {event.start_time &&event.end_time
                      ? `${event.start_time} -  ${event.end_time}`
                      : "Time not available"}
                </div> 
              </Typography>
              <Typography className="eventDetails" style={{marginTop : "9px"}}>
                
                <div className="eventDetailsItem">
                <InsertLinkIcon className="eventIcons" />
                {eventUrl}
                </div>
              </Typography> 
            </Box>
          </Box>
        </Box>
                
        <Box sx={{paddingTop : "18px", position : "relative", paddingLeft : isMobile ? "0px" : "18px", paddingRight : isMobile ? "0px" : "18px" }}>
        <svg
            width={26}
            height={26}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{position : "absolute", top : "-5px", right : "36px"}}
            >
            <path
                d="M11.7321 17C10.9622 18.3333 9.03775 18.3333 8.26795 17L0.47372 3.5C-0.29608 2.16667 0.666172 0.5 2.20577 0.5L17.7942 0.500001C19.3338 0.500002 20.2961 2.16667 19.5263 3.5L11.7321 17Z"
                fill="#D62339"
            />
            </svg>
          <Typography className="shareEventTxt">
            Share Event
          </Typography>
          <Grid container
            spacing={1}
            sx={{ 
              mb: 3, 
              flexWrap: 'wrap',
              [theme.breakpoints.down('sm')]: {
                padding : "auto"
              }
            }}>
            {shareButtons.map((button) => (
              <Grid item 
              // sm={2.4}
             // md={2.4}
              sx={{
                // '@media (max-width: 600px)': {
                //   flex: '1 0 calc(25%)',
                // },
                // '@media (max-width: 480px)': {
                //   flex: '1 0 calc(33.33%)',
                // },
                // display: 'flex', // Ensure the content is flexed properly
                // justifyContent: 'center', // Centers the content within the grid item
                // alignItems: 'center', // Vertically centers the content
              }}
              key={button.name}>
                <ShareButton onClick={button.onClick}>
                  <Box sx={{ bgcolor: button.bgColor, borderRadius: '50%', height : "68px", width : "68px", display : "flex",  margin: '0 auto'}}>
                    <img src={button.image} style={{height  :"36px", width : "36px", margin : "auto"}}></img>
                  </Box>
                  <Typography  align="center" variant="caption" style={{textTransform : "none", }} color="text.secondary">
                    {button.name}
                  </Typography>
                </ShareButton>
              </Grid>
            ))}
          </Grid>
          {ShareEventUrl(eventUrl)}
        
        </Box>
      </DialogContent>
    </BootstrapDialog>
  )
}
)

export const ShareEventUrl = (eventUrl:string)=>{
  const [copied, setCopied] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(()=>{
    setCopied(false);
  },[eventUrl])
  const handleCopyLink = async (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(eventUrl)
      setCopied(true)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }
  }
  return(
    <Box sx={{ display: 'flex', bgcolor: '#FAF7F7', borderRadius: isMobile ?"10%" :"100px", minHeight : "75px", alignItems : "center", padding : "12px", boxSizing : "border-box",
   flexDirection : isMobile ? "column" : "row" }}>
           <Typography
             sx={{
               padding: '0 28px',
               color: '#888888',
               wordBreak: 'break-word',
               overflowWrap: 'break-word',
               boxSizing: 'border-box',
               fontSize: '14px', 
               fontFamily: 'Inter',
             }}
           >{eventUrl}</Typography>
           <Button
             onClick={handleCopyLink}
             className="copyBtn"
           >
               <ContentCopyOutlinedIcon style={{fontSize : "20px"}} />
               <Typography>
               {copied ? "Copied!" : "Copy Link"}
               </Typography>
            
           </Button>
         </Box>
  )
}


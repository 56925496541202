Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getTodayEventsAPi = "bx_block_events/events/todays_events";
exports.getFilteredEventsApiEndPoint = "bx_block_events/events?";
exports.colorsArray = ["#F47133", "#043C61", "#BC3081", "#96B999", "#FE9612", "#3C8D9E", "#D62339"];
exports.noEventsLabel = "No events for the day";
exports.mapApiKey = "AIzaSyBwY6jkWhDvh133eBtU5AAwDdzhdG9I5co";
exports.getFilterOptionsApiEndPoint = "bx_block_events/events/filter_options";
exports.privacyEnd="bx_block_terms_and_conditions/privacy_policies"
exports.termsConditions="bx_block_terms_and_conditions/terms_and_conditions"
exports.httpGetMethod="GET"
exports.httpPostMethod="POST"
exports.httpPutMethod="PUT"
exports.httpPatchMethod="PATCH"
exports.httpDeleteMethod="DELETE"
exports.getAboutUsApiEndPoint = "bx_block_landingpage2/about_us";
exports.getEventTypesApiEndPoint = "bx_block_categories/sub_categories";
exports.getIdentityTagsApiEndPoint = "bx_block_events/identity_tags";
exports.createEventApi = "bx_block_events/events"
exports.getEventLocationApi = "bx_block_location/locations"
exports.eventsApi="bx_block_events/events"

// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMobile: boolean;
  email: string;
  otp: string;
  ReSentOtpButtonActive: boolean;
  Repeatnewpassword: string;
  newpassword: string;
  ShowRepeatnewpassword: boolean;
  Shownewpassword: boolean;
  ForgotPasswordToken: string;
  SnackbarState: boolean;
  severity: string;
  SnakbarMessage: string;
  FormRender: string;
  ResetPasswordError: string,
  EmailError: string,
  OtpFormReinitialize: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  ForgotPasswordApiCallId: string = "";
  OtpApiCallId: string = "";
  ResetPasswordApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isMobile: false,
      email: "",
      otp: "",
      ReSentOtpButtonActive: false,
      Repeatnewpassword: "",
      newpassword: "",
      ShowRepeatnewpassword: false,
      Shownewpassword: false,
      ForgotPasswordToken: "",
      SnackbarState: false,
      severity: "",
      SnakbarMessage: "",
      FormRender: "ForgotPasswordForm",
      ResetPasswordError: "",
      EmailError: "",
      OtpFormReinitialize: false
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.ForgotApiResponse(apiRequestCallId, responseJson)
      this.SetOtpApiResponse(apiRequestCallId, responseJson)
      this.ResetPasswordApiResponse(apiRequestCallId, responseJson)
    }


    // Customizable Area End
  }

  // Customizable Area Start

  CloseSnakabar = () => {
    this.setState({
      SnackbarState: false,
      SnakbarMessage: ""
    })
  }

  ViewProfileApiCall = async (apiData: {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
  }) => {
    const { contentType, method, body, type, endPoint, token } = apiData;

    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };

  ForgotPasswordEmailSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email(configJSON.pleaseEnterAValidEmail).required(configJSON.emailIsRequired),
    });
  };

  otpSchema = () => {
    return Yup.object().shape({
      otp: Yup.number().min(5).required("Otp code is required"),
    })
  };

  TextFieldOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
  ) => {
    SetFieldValue(e.target.name, e.target.value)
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      EmailError: "",
    }));
  }

  OtpSetFieldValue = (e: string, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    if (/^\d*$/.test(e)) {
      SetFieldValue("otp", e)
      this.setState((prevState) => ({
        ...prevState,
        otp: e
      }));
    }
  }

  ForgotPasswordAPiCallFucantion = async () => {
    this.ForgotPasswordApiCallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "/account_block/accounts/forgot_password",
      body: {
        email: this.state.email,
      }
    });
  }

  SnakBarDataSet = (type: 'success' | 'error' | 'info' | 'warning', message: string) => {
    this.setState({
      SnackbarState: true,
      severity: type,
      SnakbarMessage: message
    })
  }

  ForgotApiResponse = (apiRequestCallId: string, responseJson: { message: string, token: string, errors: string }) => {
    if (this.ForgotPasswordApiCallId === apiRequestCallId) {
      if (responseJson.token) {
        this.SnakBarDataSet('success', responseJson.message)
        this.setState({
          ForgotPasswordToken: responseJson.token,
          FormRender: "OtpForm",
          EmailError: ""
        })
        if (this.state.FormRender === "OtpForm") {
          this.setState({
            otp: "",
            OtpFormReinitialize: true
          }, () => {
            this.setState({
              OtpFormReinitialize: false
            })
          })
        }
      } else {
        this.SnakBarDataSet('error', responseJson.errors)
        this.setState({
          EmailError: responseJson.errors
        })
      }
    }
  }

  OtpAPiCallFucantion = async (values: { otp: string }) => {
    this.OtpApiCallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "/account_block/accounts/validate_otp",
      body: {
        otp: values.otp,
        token: this.state.ForgotPasswordToken
      }
    });
  }

  SetOtpApiResponse = (ApiCallId: string, responseJson: { errors: string, message: string }) => {
    if (this.OtpApiCallId === ApiCallId) {
      if (responseJson.message) {
        this.SnakBarDataSet('success', responseJson.message)
        this.setState({
          FormRender: "SetNewPasswordForm",
        })
      } else {
        this.SnakBarDataSet('error', responseJson.errors)
        this.setState({
          ReSentOtpButtonActive: true
        })
      }
    }

  }

  ResetPasswordAPiCallFucantion = async (values: { newpassword: string, Repeatnewpassword: string }) => {
    this.ResetPasswordApiCallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: "/account_block/accounts/change_password",
      body: {
        token: this.state.ForgotPasswordToken,
        new_password: values.newpassword,
        repeat_new_password: values.Repeatnewpassword
      }
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ResetPasswordApiResponse = (apiRequestCallId: string, responseJson: { message: string, token: string, errors: string }) => {
    if (this.ResetPasswordApiCallId === apiRequestCallId) {
      if (responseJson.message) {
        this.SnakBarDataSet('success', responseJson.message)
        this.setState({
          ResetPasswordError: ""
        })
        setTimeout(() => {
          this.navigationToAnyPage("EmailAccountLoginBlock")
        }, 2000);
      } else {
        this.SnakBarDataSet('error', responseJson.errors)
        this.setState({
          ResetPasswordError: responseJson.errors
        })
      }
    }
  }

  SetPasswordFormScheme = () => {
    return Yup.object().shape({
      newpassword: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      Repeatnewpassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("newpassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newpassword")],
            "Repeat new password must match"
          ),
        }),
    })
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  ShowNewPaswword = () => {
    this.setState({
      Shownewpassword: !this.state.Shownewpassword,
    })
  }

  ShowRepeatnewpassword = () => {
    this.setState({
      ShowRepeatnewpassword: !this.state.ShowRepeatnewpassword
    })
  }

  // Customizable Area End
}

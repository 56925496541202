import React from "react";
import LandingPageController, {
  Props
} from "./LandingPageController";
import { Box, Container, Typography, Grid, Divider, styled, Alert, Snackbar } from '@mui/material';
import { TeamMemberCard } from "./components/TeamMemberCard";
import Footer from "../../../components/src/Footer";
import CustomSnackBar from "./components/Snackbar";

const StyledDivider = styled(Divider)({
  minWidth : "670px",
  borderWidth: '2px', 
  borderColor: 'white', 
  marginBottom : "17px",
   "@media (max-width: 700px)" : {
        minWidth : "90%"
    },
})

export default class AboutUsPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    

    return (
        <Box bgcolor={"#FAF7F7"}>
    
        <CustomSnackBar
          data-test-id="Snackbar"
          open={this.state.isLoggedIn === false}
          message="Logged Out Successfully"
          alertSeverity="info"
          autoHideDuration={3000}
          onClose={this.handleSnckbrClose}
          snackbarStyles={{
            height: '58px',
            marginBottom: '500px',
            marginLeft: "555px",
            width: '347px',
            marginTop: '122px',
            borderRadius: '8px',
            gap: '8px',
            backgroundColor: '#FFFFFF',
            zIndex: '9999'
          }}
          alertStyles={{
            backgroundColor: "#FFFFFF",
          }}
        />
        {/* Hero Section */}
        <Box
          sx={{
            bgcolor: '#043C61',
            color: 'white',
            height : "500px",
            textAlign: 'center',
            position: 'relative',
            overflow: 'hidden',
            display : "flex",
            alignItems : "center"
          }}
        >
          <Container maxWidth="md" style={{position : "relative", width : "100%", display : "flex", flexDirection : "column", alignItems  :"center"}}>
            <Typography  style={{fontWeight : 600, fontSize : "45px", fontFamily : "Inter"}}>
              {this.state.aboutUsData.attributes.title}
            </Typography>
            <StyledDivider />
            <Typography  style={{maxWidth:"670px",fontFamily : "Inter", fontWeight : 400, fontSize : "25px"}}>
            {this.state.aboutUsData.attributes.description}
            </Typography>
            <div style={{
              height : "19px",
              width : "19px",
              backgroundColor : "#FED576",
              borderRadius : "50%",
              position :"absolute",
              top : "-30%",
              left : "60%"
            }}>
            </div>
            <div style={{
              height : "14px",
              width : "14px",
              border : "2px solid #FE9612",
              borderRadius : "50%",
              position :"absolute",
              top : "-12%",
              left : "65%"
            }}></div>
             <div style={{
              height : "20px",
              width : "20px",
              border : "2px solid #D62339",
              borderRadius : "50%",
              position :"absolute",
              top : "-40%",
              left : "65%"
            }}></div>
          </Container>
        </Box>
  
        <Container maxWidth="lg" sx={{ pt : "110px" }}>
          <Typography sx={{ textAlign: 'center', color :"#D62339", fontSize : "45px", marginBottom : "54px" }}>
            <span style={{fontWeight : 800}}>Our Mission: </span>
             Connect the LGBTQ+ Community
          </Typography>
           <div
           style={webStyle.textStyles}
          dangerouslySetInnerHTML={{ __html: this.state?.aboutUsData?.attributes?.our_mission }}
        />
        </Container>
        <Container sx={{ pt : "80px", pb : "80px"}} maxWidth="lg">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              textAlign: 'center',
              mb: 6,
            }}
          >
            <span style={{ color: '#BC3081' }}>M</span>
            <span style={{ color: '#D62339' }}>e</span>
            <span style={{ color: '#D62339' }}>e</span>
            <span style={{ color: '#F47133' }}>t</span>
            <span> </span>
            <span style={{ color: '#FE9612' }}>t</span>
            <span style={{ color: '#FE9612' }}>h</span>
            <span style={{ color: '#FED576' }}>e</span>
            <span> </span>
            <span style={{ color: '#96B999' }}>T</span>
            <span style={{ color: '#3C8D9E' }}>e</span>
            <span style={{ color: '#045E81' }}>a</span>
            <span style={{ color: '#045E81' }}>m</span>
          </Typography>

          <Typography style={webStyle.textStyles}>
            {this.state.aboutUsData.attributes.team_description}
          </Typography>

            <Grid container spacing={6} style={{marginTop : "30px"}}>
              {this.state.aboutUsData.attributes.teams.data.map((member:any) => (
                <Grid style={{}} item key={member.attributes.name} xs={12} sm={6} md={4}>
                  <TeamMemberCard {...member} />
                </Grid>
              ))}
            </Grid>
          </Container>
  
        {/* Footer */}
        <Footer />
      </Box>
    );
  }
}

const webStyle: { [key: string]: React.CSSProperties } = {
  footerSection2: {
    background: "#043C61",
    display: "flex",
    justifyContent: "center",
    height: "57px",
    alignContent: "center",
    color: "white",
    gap: "25px",
    alignItems: "baseline",
  },
  footerLink: {
    color: "white",
    display: "flex",
    fontFamily: "Inter",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: 400,
  },
  searchContainer: {
    border: "1px solid #888888",
    borderRadius: "100px",
    maxHeight: "50px",
    padding: "6px 6px 6px 13px",
    display: "flex",
    alignItems: "center",
    width: "667px",
  },
  sortByDropdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  eventsFound: {
    background:
      "linear-gradient(270deg, #045E81 0%, #3C8D9E 13%, #96B999 27%, #D5E07A 44%, #E9A64D 55%, #F47133 63%, #D62339 83%, #BC3081 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Inter",
    fontSize: "22px",
    fontWeight: "600",
    margin: "0",
  },
  calenderStyle: {
    border: "none",
    width: "100%",
  },
  sortByRelevance: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "0",
  },
  sortBy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#F47133",
    paddingLeft: "5px",
  },
  shareEvent: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "25px",
    paddingRight: "12px",
    height: "48px",
    borderRadius: "100px",
    margin: "0",
    background: "#D62339",
    color: "white",
    textTransform: "none",
  },
  shareEventIcon: {
    height: "34px",
    width: "34px",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  results: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "10px",
    textAlign: "end",
    marginTop: "10px",
    color: "#94A3B8",
  },
  selectedDateOrMonthLabel: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "28px",
    color: "#000",
  },
  calendarView: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#64748B",
    cursor: "pointer",
    padding: "6px 12px",
    border: "1px solid transparent",
  },
  calendarActiveView: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#96B999",
    backgroundColor: "#EEF6EE",
    cursor: "pointer",
    borderRadius: "50px",
    border: "1px solid #96B999",
    padding: "6px 12px",
  },
  monthEventTile: {
    borderRadius: "5px",
    backgroundColor: "#FFF",
    display: "flex",
    padding: "2px",
  },
  eventLine: {
    margin: "5px 7px 5px 0px",
    borderRadius: "100px",
  },
  eventCount: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "14px",
    color: "#343434",
  },
  eventTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "14px",
    fontStyle: "italic",
    color: "#343434",
  },
  eventPropGetter: {
    backgroundColor: "transparent",
    border: "none",
    marginTop: "32px",
  },
  calendarComponent: {
    height: 500,
    backgroundColor: "#FAF7F7",
  },
  dayHeader: {
    fontSize: "12px",
    color: "#94A3B8",
    padding: "10px",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "400",
    backgroundColor: "#FFF",
  },
  moveIcon: {
    color: "#94A3B8",
    cursor: "pointer",
  },
  mainContainer: { display: "flex", padding: "20px 60px" },
  mapContainer: {
    flex: 2,
    minHeight: "min(100vh, 736px)",
    maxWidth: "687px",
    maxHeight: "736px",
    borderRadius: "20px",
    overflow: "hidden",
    background: "gray",
  },
  calendarEventContainer: {
    flex: 1,
    minHeight: "min(100vh, 736px)",
    maxWidth: "623px",
    maxHeight: "736px",
    borderRadius: "10px",
    padding: "20px",
  },
  timeSlot: {
    fontSize: "12px",
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "18px",
  },
  dayEventTile: {
    display: "flex",
    boxShadow: "0px 2px 2px 0px #0000001A",
    borderRadius: "5px",
  },
  eventLineForDayEvent: {
    margin: "5px 7px 5px 0px",
    borderRadius: "100px",
  },
  dayEventTitle: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "700",
    lineHeight: "10px",
  },
  locationText: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "10px",
  },
  identityTag: {
    fontSize: "10px",
    color: "#343434",
    fontFamily: "Inter",
    fontWeight: "700",
    lineHeight: "10px",
  },
  relevanceIon: {
    cursor: "pointer",
    color: "#F47133",
  },
  dropdownContainer: {
    height: "50px",
    borderRadius: "100px",
    color: "#888888",
    border: "1px solid #D9D9D9",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    backgroundColor: "#FFF",
  },
  formControl: {
    width: 310,
  },
  dropdownIcon: {
    color: "#888888",
  },
  dropdownPaper: {
    maxHeight: 262,
    borderRadius: "5px",
    overflowY: "auto",
  },
  hiddenButton: {
    display: "none",
  },
  textStyles : {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: 400,
      fontFamily : "Inter"
  }
};

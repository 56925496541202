import React from "react";

// Customizable Area Start
import { Bounce, ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";

import {signupBg,logo,checkbox,cross,backArrow} from "./assets"
import PasswordField from "../../../components/src/PasswordField";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showSuccessMessage = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }
  renderTermsAndCondition = () => {
    return (
      <Dialog onClose={this.handletncClose} aria-labelledby="customized-dialog-title" open={this.state.termsnConditionModal && !this.state.isMobile} maxWidth="sm" PaperProps={{ sx: { borderRadius: "10px 10px 40px 10px" } }}>
      <DialogTitle id="customized-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box display="flex" alignItems="center">
          <Typography gutterBottom sx={webStyle.modalHeadingText}>
            Terms and Conditions and Privacy Policy 
          </Typography>
        </Box>
        <IconButton aria-label="close" onClick={this.handletncClose}>
          <img src={cross} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box padding="20px">
          {
            this.state.termAndConditionResponse?.map((tnc: string, index: number) => {
              return (
                <p  style={webStyle.modalText as React.CSSProperties} key={index}>
                  {tnc}
                </p>) })
          }
           {
            this.state.privacyResponse?.map((tnc: string, index: number) => {
              return ( <p key={index} style={webStyle.modalText as React.CSSProperties}>
                  {tnc}</p>
              );
            })
          }
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              width:'100%' ,
              marginTop:'10px',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              icon={<img src={checkbox} />}
              data-test-id="checkbox"
              checked={this.state.termsnCondition}
              onChange={this.handleTnCChange}
              style={{ cursor: "pointer" ,right:'10px'}}
            />
            <Typography
            style={{...webStyle.modalText as React.CSSProperties,marginTop:'20px'}}
              >
             I have read and agree to these Terms and Conditions and Privacy Policy 
            </Typography>
          
          </Box>
          { this.state.allowPermission && !this.state.termsnCondition && 
              <p style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px", marginTop: '5px'
              }}>This field is required!</p>}
        </Box>
      </DialogContent>
      <DialogActions sx={webStyle.dialogBtnContainer}>
        <Button
          variant="contained"
          style={{
            textTransform:'none',
            background: "linear-gradient(90deg, #F47133 0%, #D62339 100%)",
            width: "269px",
            height: "59px",
            borderRadius: "100px",
            margin: "10px",
            cursor: "pointer",
            boxShadow: "none",
          }}
          data-test-id="agreeBtn"
          onClick={this.handletncAgree} color="primary">
          Agree
        </Button>
        <Button
          variant="outlined"
          style={{
            width: "269px",
            height: "59px",
            borderRadius: "100px",
            margin: "10px",
            color: "#D62339",
            border: "1px solid #D62339",
            cursor: "pointer",
            boxShadow: "none",
            textTransform:'none'
          }}
          data-test-id="cancelBtn"
          onClick={this.handletncClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
  renderTermsAndConditionMobile = () =>{
    return(
      <Box sx={webStyle.formContainer(this.state.isMobile)}>
                <Box sx={webStyle.mainForm(this.state.isMobile)}>
                  <IconButton aria-label="close" data-test-id="cancelBtn" onClick={this.handletncClose} sx={{position: "absolute", right: "15px",top: "-30px",height:'22px'}}>
                      <img src={cross} />
                  </IconButton>
                  <Box sx={{display:"flex" , justifyContent:"center"}}>
                    <Typography gutterBottom sx={{...webStyle.modalHeadingText, fontSize:'16px',marginBottom: "20px"}}>
                    Terms and Conditions and Privay Policy 
                    </Typography>
                  </Box>
                  <Box padding="20px">
                  {
                    this.state.termAndConditionResponse?.map((tnc: string, index: number) => {
                      return (
                        <p key={index} style={webStyle.modalText as React.CSSProperties}>
                          {tnc}
                        </p>
                      );
                    })
                  }
                   {this.state.privacyResponse?.map((tnc: string, index: number) => {
                    return (
                      <p style={webStyle.modalText as React.CSSProperties} key={index} >
                        {tnc}
                      </p>
                    );
                  })
                }
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width:  '100%' ,
                        marginTop:'10px',
                      }}
                    >
                       <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    icon={<img src={checkbox} />}
                    data-test-id="checkbox"
                    checked={this.state.termsnCondition}
                    onChange={this.handleTnCChange}
                    style={{ cursor: "pointer" }}
                  />
                      <Typography style={{...webStyle.modalText as React.CSSProperties,marginTop:'20px'}}>I have read and agree to these Terms and Conditions and Privay Policy </Typography>
                    </Box>
                    { this.state.allowPermission && !this.state.termsnCondition && 
                    <p style={{
                       
                        fontWeight: 500,
                        marginLeft:'10px',
                        color: "#EB3E3E",
                        fontFamily: "Chromatica-Regular",
                        fontSize: "16px",
                        lineHeight: "24px", marginTop: '5px'
                    }}>This field is required!</p>}
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row",justifyContent:"center"}}>
                    <Button
                      variant="contained"
                      style={{
                        textTransform:'none',
                        background: "linear-gradient(90deg, #F47133 0%, #D62339 100%)",
                        width: "269px",
                        height: "59px",
                        borderRadius: "100px",
                        margin: "10px",
                        cursor: "pointer",
                        boxShadow: "none",
                        fontSize:'22px'
                      }}
                      data-test-id="agreeBtn"
                      onClick={this.handletncAgree} color="primary">
                      Agree
                    </Button>
                  </Box>
                </Box>
            </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>

        <Box sx={webStyle.userProfileWrapper(this.state.isMobile)}>
        {this.showSuccessMessage()}
          <style>
            {`
            .MuiCheckbox-colorPrimary {
              color: #045E81;
            }
            .Mui-checked {
              color: #045E81;
            }
            .navContainer {
              display:none !important;
            }
          `}
          </style>
          <Box sx={webStyle.bgImgContainer(this.state.isMobile)}>
            <Box sx={webStyle.bgTextWrapper(this.state.isMobile)}>
              {!this.state.isMobile && <Box data-test-id="handleGoBack" sx={webStyle.backBtnContainer} onClick={this.handleGoBack}>
                <img src={backArrow} />
                <Typography sx={webStyle.backBtn}>
                  Go back
                </Typography>
              </Box>}
              <Typography sx={webStyle.imageHeading(this.state.isMobile)}>Welcome to Washington, D.C.</Typography>
              <Typography sx={webStyle.imageSubHeading(this.state.isMobile)}>Create an account to access all features</Typography>
              <img src={logo} style={webStyle.headerImg(this.state.isMobile)} />
            </Box>
          </Box>
          {((!this.state.termsnConditionModal && this.state.isMobile ) || !this.state.isMobile) && (
            <Box sx={webStyle.formContainer(this.state.isMobile)}>
              <Box sx={webStyle.mainForm(this.state.isMobile)}>
                {this.state.isMobile && <IconButton aria-label="close" onClick={this.handleGoBack} sx={{position: "absolute", right: "15px",top: "-30px"}}>
                  <img src={cross} />
                </IconButton>}
                <Box style={{textAlign: 'center'}}>
                      <img src={logo} style={webStyle.footerImg(this.state.isMobile)} />
                    </Box>
                <Typography sx={webStyle.formHeading(this.state.isMobile)}>Sign Up</Typography>
                <Typography sx={webStyle.formSubHeading(this.state.isMobile)}>Create your account</Typography>

                <Box sx={webStyle.textFieldContiner}>
                  <Typography sx={webStyle.textFieldLabel}>Name<span style={{ color: "#F47133" }}>*</span></Typography>
                  <TextField
                    value={this.state.user.full_name}
                    InputProps={{
                      style: webStyle.textField(this.state.isMobile)
                    }}
                    variant="outlined"
                    placeholder="Enter your name"
                    data-test-id="nameTextField"
                    onChange={this.handleNameChange}
                  />
                  {
                    this.state.user.full_name=='' &&
                  <Typography sx={webStyle.errorLabel}>{this.state.error.full_name}</Typography>
                   }
                </Box>

                <Box sx={webStyle.textFieldContiner}>
                  <Typography sx={webStyle.textFieldLabel}>Email address <span style={{ color: "#F47133" }}>*</span></Typography>
                  <TextField
                    value={this.state.user.email_id}
                    InputProps={{
                      style: webStyle.textField(this.state.isMobile)
                    }}
                    variant="outlined"
                    data-test-id="emailTextField"
                    placeholder="Enter your email"
                    onChange={this.handleEmailChange}
                  />
                  <Typography sx={webStyle.errorLabel}>{this.state.error.email_id}</Typography>
                  {this.state.emailCheck && 
                  <p style={{
                        color: "red",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        marginLeft:'10px',
                        lineHeight: "24px", marginTop: '5px'
                    }}>{this.state.emailError}</p>}
                                    </Box>
                <Box sx={webStyle.textFieldContiner}>
                  <Typography sx={webStyle.textFieldLabel}>Password<span style={{ color: "#F47133" }}>*</span></Typography>
                  <PasswordField
                    value={this.state.user.password}
                    onChange={this.handlePasswordChange}
                    isMobile={this.state.isMobile}
                    isPasswordVisible={this.state.enablePasswordField}
                    onTogglePasswordVisibility={this.handleClickShowPassword}
                    style={webStyle.textField(this.state.isMobile)}
                  />
                  <Typography sx={webStyle.errorLabel}>{this.state.error.password}</Typography>
                  {this.state.passwordCheck && 
                  <p style={{
                        color: "red",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        marginLeft:'10px',
                        lineHeight: "24px", marginTop: '5px'
                    }}>{this.state.passwordError}</p>}      
                              </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '78%',
                    ...(this.state.isMobile && {
                      width: "100%",
                    })
                  }}
                >
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    icon={<img src={checkbox} />}
                    data-test-id="checkbox"
                    checked={this.state.termsnCondition}
                    onChange={this.handleTnCChange}
                    style={{ cursor: "pointer" }}
                  />
                   <Typography sx={webStyle.agreeTermsText(this.state.isMobile)}>I agree to the <span style={webStyle.termsnCondText as React.CSSProperties} data-test-id="tncModalText" onClick={this.openTermnConditionModel}>Terms and Conditions and Privacy Policy</span></Typography>
                </Box>
                {this.state.handleError &&  !this.state.termsnCondition &&
                    <p style={{
                        color: "red",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        marginLeft:'10px',
                        lineHeight: "24px", marginTop: '5px'
                    }}>This field is required!</p>}
                                    <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#D62339",
                    height: "38px",
                    borderRadius: "100px",
                    margin: "10px 0",
                    marginBottom:'30px',
                    marginTop:'20px',
                    cursor: "pointer",
                    boxShadow: "none",
                    width: this.state.isMobile ? "317px" : "480px",
                  }}
                  data-test-id="signupBtn"
                  onClick={this.handleSignupSubmit}
                >
                  <Typography sx={webStyle.signUpbtn}>
                    Sign Up
                  </Typography>
                </Button>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box style={{ display: "flex", justifyContent:  "center", alignItems: "center", width: "90%",marginBottom:'20px' }}>
                    <Typography sx={webStyle.loginText}>Have an account? <span style={webStyle.loginTextSpan as React.CSSProperties} data-test-id="loginNavigate" onClick={this.navigateToLogin}>Log in</span></Typography>
                   
                  </Box>
                </Box>
              </Box>
            </Box>
            )}
            {this.renderTermsAndCondition()}
          {this.state.isMobile && this.state.termsnConditionModal && (
            this.renderTermsAndConditionMobile()
          )}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: (isMobile:boolean)=> ({
    width: "100%",
    height:"100vh",
    display:"flex",
    ...(isMobile && {
      width: "100%",
      display:"flex",
      flexDirection:"column",
      height: "100%",
    }),
  }),
  formContainer:(isMobile:boolean)=> ({
    width: "50%",
    background:"#FAF7F7",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::after":{
      content: '"."',
      width: "50px",
      height: "100%",
      background: "#FAF7F7",
      display: "inline-block",
      position: "absolute",
      top: "0",
      left: "-42px",
      borderRadius: "40px 0 0 40px",
    },
    ...(isMobile && {
      width: "100%",
      height: "auto",
      display:"flex",
      flexDirection:"column",
      borderRadius: "40px",
      marginBottom:"-110px",
      "&::before":{
        content: '"."',
        width: "100%",
        height: "40px",
        background: "#FAF7F7",
        display: "inline-block",
        position: "absolute",
        top: "-38px",
        left: "0",
        borderRadius: "40px 40px 0 0 ",
      },
      "&::after":{
        display:"none"
      }
    }),
  }),
  bgImgContainer:(isMobile:boolean)=>({
    backgroundImage:`url(${signupBg})`,
    width: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display:"flex",
    justifyContent:"center",
    ...(isMobile && {
      width: "100%",
      height: "100vh",
    }),
  }),
  bgTextWrapper:(isMobile:boolean)=>({
    padding: "calc(100px)",
    ...(isMobile && {
      position:"static",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign:"center",
      padding: "0"
    }),
  }),
  mainForm:(isMobile:boolean)=>({
    maxWidth:"480px",
    margin:"auto",
    position:"relative",
    zIndex:10,
    ...(isMobile && {
      // padding:"40px"
    })
  }),
  formHeading:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: 700,
    textAlign: "center",
    color:"#3C8D9E",
    marginBottom:"35px",
    ...(isMobile && {
      fontSize: "30px",
      marginBottom:"20px",
    })
  }),
  formSubHeading:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    textAlign: "center",
    color:"#343434",
    ...(isMobile && {
      fontSize: "20px",
      textAlign: "center",
    })
  }),
  btnText:{
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
    color:"#043C61",
    textTransform: "capitalize",
  },
  signUpbtn:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    textTransform: "capitalize",
  },
  textField:(isMobile:boolean)=>({
    width: "480px",
    height: "38px",
    borderRadius: "100px",
    border: "0.5px",
    fontFamily:"Inter",
    fontSize:"12px",
    backgroundColor:"#fff",
    ...(isMobile && {
      width: "317px",
    })
  }),
  textFieldContiner:{
    marginTop:"10px",
    marginBottom:"10px",
  },
  textFieldLabel:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    color:"#888888",
    paddingLeft:"10px",
  },
  imageHeading:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    textAlign: "left",
    color: "#F47133",
    ...(isMobile && {
      width: "250px",
      fontSize: "25px",
      textAlign:"center"
    })
  }),
  imageSubHeading:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "25px",
    fontWeight: "400",
    textAlign: "justified",
    color: "#fff",
    ...(isMobile && {
      width: "250px",
      fontSize: "16px",
    })
  }),
  loginText:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "10px",
    textAlign: "left",
    color:"#888888"
  },
  loginTextSpan:{
    fontStyle:'bold',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 900,
    lineHeight: "10px",
    textAlign: "left",
    color:"#F47133",
    cursor:"pointer",
  },
  agreeTermsText:(isMobile:boolean)=>({
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "10px",
    textAlign: "left",
    color:"#888888",
    ...(isMobile && {
      fontSize: "12px",
    })
  }),
  termsnCondText:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 900,
    lineHeight: "10px",
    textAlign: "left",
    color:"#045E81",
    cursor:"pointer",
  },
  modalText:{
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color:"#343434",
    margin:0,
  },
  modalHeadingText:{
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    textAlign: "left",
    color:"#D62339",
    margin:0
  },
  dialogBtnContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between"
  },
  headerImg:(isMobile:boolean)=>({
     display:"none",
    ...(isMobile && {
      display:"block"
    })
  }),
  footerImg:(isMobile:boolean)=>({
    ...(isMobile && {
      display:"none"
    })
  }),
  errorLabel:{
    marginLeft:'10px',
    marginTop:'5px',
    fontFamily: "Inter",
    fontSize: "12px",
    textAlign: "left",
    color:"red"
  },
  backBtn:{
    color:"#fff",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    marginLeft:"20px"
  },
  backBtnContainer:{
    margin: "-20px 0 50px 0",
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center",
    cursor : "pointer"
  }
};
// Customizable Area End

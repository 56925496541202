import React from "react";

//Customizable Area Start
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import ForgotPasswordControllerWeb, { Props } from "./ForgotPasswordController.web";
import { backArrow, BgForgotPassword, cross, logo } from "./assets";
import { Formik, FormikErrors, FormikTouched } from "formik";
import OtpInput from 'react-otp-input';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
//Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (
    touched: FormikTouched<{
      email: boolean;
      otp: boolean;
      Repeatnewpassword: boolean,
      newpassword: boolean,
    }>,
    errors: FormikErrors<{
      email: string;
      otp: string;
      Repeatnewpassword: string,
      newpassword: string,
    }>,
    value: string
  ) => {
    return (
      touched[value as keyof typeof touched] &&
      errors[value as keyof typeof errors] && (
        <Typography className={'errorLabel'}>{errors[value as keyof typeof errors]}</Typography>
      )
    );
  };

  SnackBar = () => {
    // severity: 'success' | 'error' | 'info' | 'warning'
    return (
      <Snackbar anchorOrigin={{
        vertical: 'top', horizontal: 'right'
      }} open={this.state.SnackbarState} autoHideDuration={6000} onClose={this.CloseSnakabar}>
        <Alert
          onClose={this.CloseSnakabar}
          severity={this.state.severity as 'success' | 'error' | 'info' | 'warning'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {this.state.SnakbarMessage}
        </Alert>
      </Snackbar>
    )
  }
  ForgotPasswordForm = () => {
    return (
      <Formik
        data-test-id="ForgotPasswordFormFormik"
        initialValues={{
          email: this.state.email,
        }}
        validationSchema={this.ForgotPasswordEmailSchema}
        onSubmit={() => {
          this.ForgotPasswordAPiCallFucantion()
        }}
      >
        {({ errors, touched, values, handleBlur, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Box>
              <Typography className={"formHeading"}>Forgot Password</Typography>
            </Box>
            <Box>
              <Typography className={"formSubHeading"}>
                Enter your email address to receive the verification code
              </Typography>
            </Box>
            <Box className={'textFieldContiner'}>
              <Typography className={'textFieldLabel'}>Email address <span style={{ color: "#F47133" }}>*</span></Typography>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    height: "38px",
                    borderRadius: "100px",
                    border: "0.5px",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    color: "#888888",
                    backgroundColor: "#fff",
                  }
                }}
                variant="outlined"
                data-test-id="EmailTextField"
                onBlur={handleBlur}
                value={values.email}
                name="email"
                placeholder="Enter your email"
                onChange={(e) => {
                  this.TextFieldOnChange(e, setFieldValue)
                }}
              />
              {this.getErrorMessage(touched, errors, "email")}
              {this.state.EmailError && <Typography className={'errorLabel'}>{this.state.EmailError}</Typography>}
            </Box>
            <Button
              variant="contained"
              data-test-id="loginBtn"
              fullWidth
              className="loginBtn"
              type="submit"
              disabled={errors.email !== undefined || values.email === ""}
            >
              <Typography className={'loginBtnText'}>
                Next
              </Typography>
            </Button>
          </form>
        )}
      </Formik>
    )
  }

  OtpForm = () => {
    return (
      <Box>
        <Formik
          data-test-id="Otpformik"
          initialValues={{
            otp: this.state.otp,
          }}
          validationSchema={this.otpSchema}
          enableReinitialize={this.state.OtpFormReinitialize}
          onSubmit={(values) => {
            this.OtpAPiCallFucantion(values)
          }}
        >
          {({ errors, touched, values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Box>
                <Typography className={"formHeading"}>Forgot Password</Typography>
              </Box>
              <Box>
                <Typography className={"formSubHeading"}>
                  We have sent the verification code to {this.state.email}
                </Typography>
              </Box>
              <Box className={'textFieldContiner'}>
                <Box className="OtpBoxContainer" >
                  <Box>
                    <OtpInput
                      value={this.state.otp}
                      onChange={(e) => {
                          this.OtpSetFieldValue(e, setFieldValue)
                      }}
                      numInputs={5}
                      data-test-id="OtpInput"
                      inputStyle={"otpContainer"}
                      renderSeparator={<span style={{ marginLeft:"5px", marginRight:"5px" }} >{"  "}</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    {this.getErrorMessage(touched, errors, "otp")}
                  </Box>
                </Box>
              </Box>
              <Box className={'textFieldContiner'}>
                <Button
                  variant="contained"
                  data-test-id="OtpBtn"
                  fullWidth
                  className="loginBtn"
                  type="submit"
                  disabled={errors.otp !== undefined || values.otp === "" || this.state.otp.length < 5}
                >
                  <Typography className={'loginBtnText'}>
                    Next
                  </Typography>
                </Button>
              </Box>
              <Box className={'textFieldContiner'}>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button variant="text" className="ResendBtn" onClick={this.ForgotPasswordAPiCallFucantion}>
                    <Typography className={"ReesendBtnText"}>
                      Resend code
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    )
  }

  SetNewPasswordForm = () => {
    return (
      <Formik
        data-test-id="SetNewPasswordFormFormik"
        initialValues={{
          newpassword: this.state.newpassword,
          Repeatnewpassword: this.state.Repeatnewpassword,
        }}
        validationSchema={this.SetPasswordFormScheme}
        onSubmit={(values) => {
          this.ResetPasswordAPiCallFucantion(values)
        }}
      >
        {({ errors, touched, values, handleBlur, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Box>
              <Typography className={"formHeading"}>Create new password</Typography>
            </Box>
            <Box>
              <Typography className={"formSubHeading"}>
                Your new password must be different from previously used password
              </Typography>
            </Box>
            <Box className={'textFieldContiner'}>
              <Typography className={'textFieldLabel'}>New password<span style={{ color: "#F47133" }}>*</span></Typography>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    height: "38px",
                    borderRadius: "100px",
                    border: "0.5px",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    color: "#888888",
                    backgroundColor: "#fff",
                  },
                  endAdornment: (
                    <InputAdornment style={{ cursor: "pointer" }} data-test-id="ShowNewPaswword" onClick={this.ShowNewPaswword} position="start">
                      {
                        this.state.Shownewpassword ?
                          <VisibilityIcon /> :
                          <VisibilityOffIcon />
                      }
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                data-test-id="SetNewPassword"
                onBlur={handleBlur}
                name="newpassword"
                value={values.newpassword}
                type={this.state.Shownewpassword ? "text" : "password"}
                placeholder="Enter your new password"
                onChange={(e) => {
                  this.TextFieldOnChange(e, setFieldValue)
                }}
              />
              {this.getErrorMessage(touched, errors, "newpassword")}

            </Box>
            <Box className={'textFieldContiner'}>
              <Typography className={'textFieldLabel'}>Repeat new password<span style={{ color: "#F47133" }}>*</span></Typography>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    height: "38px",
                    borderRadius: "100px",
                    border: "0.5px",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    color: "#888888",
                    backgroundColor: "#fff",
                  },
                  endAdornment: (
                    <InputAdornment style={{ cursor: "pointer" }} data-test-id="ShowRepeatnewpassword" onClick={this.ShowRepeatnewpassword} position="start">
                      {
                        this.state.ShowRepeatnewpassword ?
                          <VisibilityIcon /> :
                          <VisibilityOffIcon />
                      }
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                data-test-id="Repeatnewpassword"
                onBlur={handleBlur}
                value={values.Repeatnewpassword}
                name="Repeatnewpassword"
                type={this.state.ShowRepeatnewpassword ? "text" : "password"}
                placeholder="Repeat your new password"
                onChange={(e) => {
                  this.TextFieldOnChange(e, setFieldValue)
                }}
              />
              {this.getErrorMessage(touched, errors, "Repeatnewpassword")}
              {
                this.state.ResetPasswordError !== "" &&
                <Typography className={'errorLabel'}>{this.state.ResetPasswordError}</Typography>
              }
            </Box>
            <Button
              variant="contained"
              data-test-id="loginBtn"
              fullWidth
              className="loginBtn"
              type="submit"
            >
              <Typography className={'loginBtnText'}>
                Next
              </Typography>
            </Button>
          </form>
        )}
      </Formik>
    )
  }

  RenderForms = (FormName: string) => {
    if (FormName === "ForgotPasswordForm") {
      return this.ForgotPasswordForm()
    }
    else if (FormName === "OtpForm") {
      return this.OtpForm()
    }
    else if (FormName === "SetNewPasswordForm") {
      return this.SetNewPasswordForm()
    }
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ForgotPasswordStyle>
        <style>
          {`
            .MuiCheckbox-colorPrimary {
              color: #045E81;
            }
            .Mui-checked {
              color: #045E81;
            }
            .navContainer {
              display:none !important;
            }
          `}
        </style>
        <Grid container spacing={0}>
          {this.SnackBar()}
          <Grid className="BgContainer" item xs={12} sm={12} md={6} lg={6}>
            <Box className={'bgTextWrapper'}>
              <div className="MobileLogoContainer">
                <img src={logo} className="MobileLogoLogoClass" alt="logo" />
              </div>
              <Box className="backBtnContainer" data-test-id="backBtnContainer" onClick={() => {
                this.handleGoBack()
              }}>
                <img src={backArrow} />
                <Typography className={'backBtn'}>
                  Go back
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid className="formContainer" item xs={12} sm={12} md={6} lg={6}>
            <Box className="mainForm">
              <IconButton data-test-id="cancelBtn" aria-label="close"
                onClick={this.handleGoBack}
                className="CloseIcon">
                <img src={cross} alt="cross" />
              </IconButton>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                <img src={logo} className="LogoClass" alt="logo" />
              </Box>
              {this.RenderForms(this.state.FormRender)}
            </Box>
          </Grid>
        </Grid>
      </ForgotPasswordStyle>
      //Customizable Area End
    )
  }
}

// Customizable Area Start

const ForgotPasswordStyle = styled("div")({
  width: "100%",
  height: "100vh",
  display: "flex",
  "& .BgContainer": {
    backgroundImage: `url(${BgForgotPassword})`,
    width: "100%",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .formContainer": {
    width: "100%",
    background: "#FAF7F7",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"20px",
    "&::after": {
      content: '"."',
      width: "50px",
      height: "100%",
      background: "#FAF7F7",
      display: "inline-block",
      position: "absolute",
      top: "0",
      left: "-42px",
      borderRadius: "40px 0 0 40px",
    },
    "@media (max-width: 900px)": {
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      paddingBottom: "40px",
      background: "#FAF7F7",
      padding: "40px 0 100px 0",
      "&::before": {
        content: '"."',
        width: "100%",
        height: "40px",
        background: "#FAF7F7",
        display: "inline-block",
        position: "absolute",
        top: "-39px",
        left: "0",
        borderRadius: "40px 40px 0 0 ",
      },
      "&::after": {
        display: "none"
      }
    }
  },
  '& .errorLabel': {
    fontFamily: "Inter",
    fontSize: "12px",
    textAlign: "left",
    marginLeft: '10px',
    lineHeight: "24px", marginTop: '5px',
    color: "red"
  },
  "& .MobileLogoContainer": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MobileLogoLogoClass": {
    display: "none",
    "@media (max-width: 900px)": {
      display: "block"
    }
  },
  "& .LogoClass": {
    display: "block",
    "@media (max-width: 900px)": {
      display: "none",
    }
  },
  "& .mainForm": {
    margin: "auto",
    position: "relative",
    zIndex: 10,
    "& .CloseIcon": {
      position: "absolute", right: "15px", top: "-30px",
      display: "none",
      "@media (max-width: 900px)": {
        display: "block"
      }
    },
    "@media (max-width: 900px)": {
      padding: "20px",
    }
  },
  '& .formHeading': {
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: 700,
    textAlign: "center",
    color: "#3C8D9E",
    "@media (max-width: 900px)": {
      fontSize: "30px",
      marginBottom: "20px",
    }
  },
  '& .formSubHeading': {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    color: "#3C8D9E",
    marginBottom: "40px",
    "@media (max-width: 900px)": {
      fontSize: "14px",
      marginBottom: "20px",
    }
  },
  '& .backBtnContainer': {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width: 900px)": {
      display: "none",
    }
  },
  '& .bgTextWrapper': {
    position: "relative",
    padding: "calc(80px) calc(77px)",
    width: "75%",
    "@media (max-width: 900px)": {
      width: "100%",
      padding: "calc(80px) 0px"
    }
  },
  '& .backBtn': {
    color: "#fff",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "400",
    marginLeft: "20px"
  },
  "& .OtpBoxContainer": {
    display: "flex",
    justifyContent: "center",
  },
  "& .otpContainer": {
    width: '82px !important',
    height: '82px',
    borderRadius: '10px',
    color: "black",
    fontWeight: 700,
    fontSize: '20px',
    border: '1px solid #3C8D9E !important',
    outlineColor: "#3C8D9E !important",
    "@media (max-width: 768px)": {
      width: '60px !important',
      height: '60px',
      padding: '0px !important',
    }
  },
  '& .textFieldContiner': {
    marginTop: "10px",
    marginBottom: "10px",
  },
  "& .loginBtn": {
    backgroundColor: "#D62339",
    height: "38px",
    borderRadius: "100px",
    margin: "10px 0",
    boxShadow: "none",
    cursor: "pointer",
    marginBottom: '30px',
    "&:hover": {
      backgroundColor: "#D62339",
    }
  },
  "& .loginBtn.Mui-disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  "& .ReesendBtnText": {
    fontFamily: 'Inter',
    fontSize: '16px',
    textAlign: "center",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "initial",
  },
  "& .ResendBtn": {
    height: "38px",
    color: "#3C8D9E",
    borderRadius: "100px",
    margin: "10px 0",
    boxShadow: "none",
    cursor: "pointer",
    marginBottom: '30px',
  },
  "& .ResendBtn.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.12)",
  },
  '& .loginBtnText': {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    textTransform: "capitalize",
  },
  '& .textFieldLabel': {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    color: "#888888",
    paddingLeft: "10px",
    marginBottom: "4px",
  },
  '& .textField': {
    width: "480px",
    height: "38px",
    borderRadius: "100px",
    border: "0.5px",
    fontFamily: "Inter",
    fontSize: "12px",
    color: "#888888",
    backgroundColor: "#fff",
    // ...(isMobile && {
    //   width: "317px",
    // })
  },
});

//Customizable Area End

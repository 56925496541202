import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine"; 
import { ApiParams, GeolocationCallback, GeolocationErrorCallback, GeolocationPromptCallback } from "./typeInterfaces";

export const apiCalling = ({ header, endPoint, method, body } : ApiParams) => {
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
}

export const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}

export const fetchGeolocationCoordinates = (
    onSuccess: GeolocationCallback,
    onError: GeolocationErrorCallback,
    onPrompt : GeolocationPromptCallback,
) => {
    if (!navigator.permissions || !navigator.geolocation) {
        onError("Geolocation is not supported by your browser.");
        return;
    }
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
            getCurrentLocation(onSuccess, onError)
        } else if (result.state === "prompt") {
            onPrompt()
        } else {
            onError("Geolocation permission is denied.");
        }
    });
};

export const getCurrentLocation = (
    onSuccess: GeolocationCallback,
    onError: GeolocationErrorCallback,
) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          onSuccess(coordinates);
        },
        (error) => {
            onError(handleGeolocationError(error));
        }
      );
    } else {
      onError("Geolocation is not supported by your browser.");
    }
};

const handleGeolocationError = (error: any): string => {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return "User denied the request for Geolocation.";
        case error.POSITION_UNAVAILABLE:
            return "Location information is unavailable.";
        case error.TIMEOUT:
            return "The request to get user location timed out.";
        default:
            return "An unknown error occurred.";
    }
};
// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Button
} from "@mui/material"
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { currentLocationIcon, locationPin, timeIcon } from "./assets";
import CloseIcon from '@mui/icons-material/Close';

import EventMapController, {
    Props
} from "./EventMapController";
import MyLocationIcon from "@mui/icons-material/MyLocation";


class EventMap extends EventMapController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderModalContentSection = () => {
    return (
        <Box>
            <Typography className="permissionText">
                {this.state.locationDeniedState ? this.showLocationMessage.locationDeniedMsg : this.showLocationMessage.locationRequiredMsg}
            </Typography>

            <Typography data-test-id="deniedBtn" onClick={this.closeLocationPermissionModal} className="dontAllowText">
                Don’t Allow
            </Typography>

            {!this.state.locationDeniedState &&
                <Button data-test-id="allowBtn" onClick={this.allowLocationAccess} className="allowBtn" fullWidth>
                    Allow
                </Button>
            }
        </Box>
    )
  }

  render() {
    // Customizable Area Start
    const {screenWidth}=this.state
    const zoomPosition = screenWidth < 700 ?  window.google.maps.ControlPosition.RIGHT_CENTER :  window.google.maps.ControlPosition.RIGHT_BOTTOM;

    const myLocBottom = screenWidth > 700 ? "120px": "240px";

    
      const center = {
          lat: 38.8950368,
          lng: -77.0365427,
      };

      
    
      return (
          <div>
              <MapWrapper>

                  <Box className="mapBox">
                      {this.state.isGoogleScriptLoaded ? (
                          <GoogleMap
                              mapContainerStyle={{
                                  height: "100%",
                                  width: "100%",
                                  
                              }}
                              mapContainerClassName="mapComponent"
                              center={center}
                              zoom={12}
                              options={{
                                  disableDefaultUI: true,
                                  zoomControl: true,
                                  zoomControlOptions:{
                                    position : zoomPosition
                                  }
                              }}
                          >  
                              {this.state.mapEventsData.length > 0 && this.state.mapEventsData.map((item, index) => (
                                  item.events.map((events) => (
                                      <Marker
                                          key={index}
                                          position={{
                                              lat: this.handletLatCheck(events),
                                              lng: this.handletLongCheck(events),
                                          }}
                                          label={item.totalEvents !== 1 ? {
                                            text: `${item.totalEvents}`,
                                            color: "white",
                                            fontSize: "20px",
                                            fontWeight: "700",
                                            fontFamily: "Inter"
                                        } :""}
                                          icon={{
                                              url: item.totalEvents !== 1 ? this.handleDynamicMarkerPinColor(index)  :this.handleDynamicMarker(index),
                                              scaledSize: { width: 64, height: 64, equals: () => true }
                                          }}
                                          data-test-id="eventMarker"
                                          onClick={() => this.openMapPopUp(item.events, item.locationId)}
                                      >

                                          {this.state.mapPopUpId === item.locationId &&
                                              <InfoWindow
                                                  position={{
                                                      lat: this.handletLatCheck(events),
                                                      lng: this.handletLongCheck(events),
                                                  }}
                                                  onCloseClick={this.closeMapPopUp}
                                                  data-test-id="mapPopUp"
                                              >
                                                  <Box bgcolor={this.handleDynamicBgColor(index)} className="outerWrapper" >
                                                      {this.state.eventInfoArr.length > 0 && this.state.eventInfoArr.map((itemVal, itemIndex) => (
                                                          <Box key={itemIndex}>
                                                              <Box data-test-id="clickPopUp" onClick={() => this.props.showEvent(itemVal.currentEvent)} className="mainPopUpWrapper">
                                                                  <Box className="imgBox">
                                                                      <img height={"95px"} width={"84px"} style={{
                                                                          border: "1px solid #fff",
                                                                          borderRadius: "5px"
                                                                      }} src={itemVal.eventImage} />
                                                                  </Box>
                                                                  <Box>
                                                                      <Typography className="titleText">{itemVal.eventTitle}</Typography>
                                                                      <Box display={"flex"} alignItems={"center"} gap={"5px"} marginBottom={"5px"}>
                                                                          <img src={locationPin} height={20} width={20} />
                                                                          <Typography className="addressText">{itemVal.eventAddress}</Typography>
                                                                      </Box>
                                                                      <Box display={"flex"} gap={"5px"}>
                                                                          <img src={timeIcon} height={20} width={20} />
                                                                          <Box>
                                                                              <Typography className="dateText">{itemVal.eventDates.eventDate}</Typography>
                                                                              <Typography className="timeText">{itemVal.eventDates.eventTimeRange}</Typography>
                                                                          </Box>
                                                                      </Box>
                                                                  </Box>
                                                              </Box>

                                                               {itemIndex !== this.state.eventInfoArr.length - 1 && <Divider className="dividerCSS" />}
                                                          </Box>
                                                      ))}
                                                  </Box>
                                              </InfoWindow>
                                          }
                                          
                                      </Marker>
                                  ))

                              ))}

                              {this.state.isLocationLoaded &&
                                  <Marker
                                      position={center}
                                      icon={{
                                          url: currentLocationIcon,
                                          scaledSize: { width: 64, height: 64, equals: () => true }
                                      }}
                                      data-test-id="currentLocationMarker"
                                  />
                              }
                              <IconButton
                                  style={{
                                      position: "absolute",
                                      color:"white",
                                     zIndex: "10",
                                      backgroundColor: "white",
                                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                      bottom: myLocBottom,
                                      right:"10px"
                                  }}
                                  onClick={this.allowLocationAccess}
                                  aria-label="Show My Location"
                              >
                                  <MyLocationIcon style={{ color: "#475569" }} />
                              </IconButton>
                          
                          </GoogleMap>
                      ) : (
                          <></>
                      )}
                  </Box>

                  <StyledDialog
                     open={this.state.locationPermissionPopUp}
                     onClose={this.closeLocationPermissionModal}
                     data-test-id="closeIcon"
                     maxWidth="md"
                  >
                    <DialogTitle>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography className="locationText">Location Permission</Typography>
                            <IconButton onClick={this.closeLocationPermissionModal}>
                                <CloseIcon sx={{ color :"#1E293B"}}/>
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        {this.renderModalContentSection()}
                    </DialogContent>

                  </StyledDialog>

                  
              </MapWrapper>
          </div>
      );
    // Customizable Area End
  }
}

export default EventMap;

const MapWrapper = styled(Box)({
  "& .mapBox" : {
    height : "747px",
    background : "#fff",
   "@media (max-width: 700px)" : {
        height : "376px",
    },
  },
  "& .mapComponent" : {
    borderRadius: "20px",
    "@media (max-width: 700px)" : {
        borderRadius: "0px",
    },
  },
  "& .mainPopUpWrapper" : {
    display : "flex",
    gap : 10, 
    minWidth : "240px",
    maxWidth : "300px",
  },
  "& .outerWrapper" : {
    padding : 10,
    borderRadius : "10px",
    cursor : "pointer"
  },
  "& .titleText" : {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
      color : "#fff",
      marginBottom : "3px"
  },
  "& .addressText" : {
      fontFamily: 'Inter',
      fontSize: '9px',
      fontWeight: 400,
      color: "#fff",
      maxWidth : "150px"
  },
  "& .dateText" : {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    color: "#fff",
    maxWidth : "150px"
  },
  "& .timeText" : {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    color: "#fff",
    maxWidth : "150px"
  },
  "& .dividerCSS" : {
    color : "#fff",
    border: "1px solid #fff",
    margin : "5px 10px"
  },
  "& .tagText" : {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color : "#BC3081",
  },
  "& .tagDiv" : {
    padding : "5px 10px",
    background : "#fff",
    borderRadius : "50px",
    border: "1px solid #BC3081"
  }
})

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '20px',
        overflow: 'hidden',
        background: "#FFFFFF",
        width : "375px"
    },
    "& .MuiDialogTitle-root" : {
        padding : "22px 24px",
    },
    "& .locationText" : {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 700,
        color: "#000000",
        marginTop : "8px"
    },
    "& .permissionText" : {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        color: "#0F172A",
        textAlign : "center"
    },
    "& .dontAllowText" : {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        color: "#96B999",
        textAlign : "center",
        marginTop : "40px",
        marginBottom : "24px",
        cursor: "pointer"
    },
    "& .allowBtn" : {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        color: "#FFFFFF",
        textTransform : "none",
        background : "#D62339",
        height : "56px",
        borderRadius : "100px",
        marginBottom : "16px"
    }

})
// Customizable Area End